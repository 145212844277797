import { Banner, Button, Spinner, useBreakpoints } from '@shopify/polaris'
import React, { useState, useEffect, useRef } from 'react'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { merge } from 'lodash-es'
import humps from 'humps'

import { getQueryParams } from '../utils/router.js'
import { getTemplateDetails, updateTemplate } from '../apis/template.js'
import Loader from '../components/Loader/index.jsx'
import { useShopDetails } from '../hooks/useShopDetails.js'
import { useDashboardDetails } from '../hooks/useDashboardDetails.js'
import { useStoreLinks } from '../hooks/useStoreLinks.js'
import { CART, COLLECTION, PRODUCT } from '../constants/page.js'
import { WIDGET_TYPES } from '../constants/widgets'
import {
  AMAZON_BOUGHT_TOGETHER,
  CAROUSEL,
  HORIZONTAL_GRID,
} from '../constants/layouts.js'
import StoreFrame from '../components/StoreFrame/index.jsx'
import { updateSection } from '../apis/section.js'
import VisualEditorForm from '../components/VisualEditorFormV2'
import queryClient from '../utils/query.js'
import analytics, { MixPanelAnalytics } from '../utils/analytics.js'

export default function VisualEditorPage() {
  const storeFrameRef = useRef(null)
  const [key, setKey] = useState(0)
  const { t } = useTranslation()
  const [frameLoading, setFrameLoading] = useState(true)
  const {
    template: templateId = null,
    widget: widgetId = null,
    shop,
  } = getQueryParams()

  const {
    data: template,
    isLoading: templateQueryLoading,
    isError: templateQueryError,
  } = useQuery({
    queryFn: async () => {
      const { data, error } = await getTemplateDetails(templateId)
      if (error) {
        return Promise.reject(error)
      }
      return data.template
    },
    queryKey: ['templateDetails', { id: templateId }],
    refetchOnWindowFocus: false,
    enabled: !!templateId,
  })

  const [frameUrl, setFrameUrl] = React.useState(null)
  const shopDetailsQuery = useShopDetails()
  const dashboardDetailsQuery = useDashboardDetails()
  const isStorePasswordProtected =
    dashboardDetailsQuery.data?.config?.storefrontPasswordEnabled ?? true
  const widget =
    shopDetailsQuery.data?.sections?.find(({ id }) => id == widgetId) ?? {}
  const page =
    shopDetailsQuery.data?.pages?.find(({ id }) => id == widget.page) ?? {}
  const isAmazonBoughtTogetherEnabled = Boolean(
    page.type === PRODUCT.value &&
      widget.type === WIDGET_TYPES.bought_together.value,
  )

  const [layout, setLayout] = useState(null)
  const [screenType, setScreenType] = useState('desktop')

  const { getLink } = useStoreLinks()

  async function handleSave({ widgetParams, settings, translations }) {
    const templatePayload = merge(
      {
        settings: template.settings,
        translations: template.translations,
      },
      {
        settings,
        translations: {
          ...template.translations,
          ...translations,
        },
      },
    )
    try {
      const {
        data: { template },
      } = await updateTemplate(templateId, templatePayload)
      const {
        data: { section },
      } = await updateSection(widgetId, {
        ...widgetParams,
        layout,
      })
      analytics.trackVisualEditorEvent(
        MixPanelAnalytics.Actions.OPEN,
        section,
        page.type,
        template,
      )
      await queryClient.invalidateQueries([
        'templateDetails',
        { id: templateId },
      ])
      await dashboardDetailsQuery.invalidate()
      shopify.toast.show(t('VisualEditorForm.successText'))
      return true
    } catch (err) {
      shopify.toast.show(t('VisualEditorForm.errorText'))
      return false
    }
  }

  useEffect(() => {
    ;(async () => {
      if (!widget || !page) {
        return
      }
      const { id: widgetId } = widget
      const { type: pageType } = page
      const { domain } = dashboardDetailsQuery.data?.shop ?? {}
      try {
        const pageTypeUrl = await getLink(pageType)
        setFrameUrl(
          `https://${domain.split('.').join('---')}.proxy.glood.co${pageTypeUrl}?rkWidgetId=${widgetId}&rk_preview=true&rk_force_random=true&rk_editor_mode=preview`,
        )
      } catch (err) {
        setFrameUrl(null)
      }
      if (!layout) {
        setLayout(widget.layout)
      }
    })()
  }, [widget, page, dashboardDetailsQuery.data])

  if (
    templateQueryError ||
    shopDetailsQuery.error ||
    !templateId ||
    !widgetId ||
    dashboardDetailsQuery.error
  ) {
    return (
      <div
        style={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Banner
          title={`Error in fetching ${
            templateQueryError || !templateId ? 'template' : 'widget'
          }  details`}
        >
          <p>
            Unable to fetch details of{' '}
            {templateQueryError ? 'template' : 'widget'}{' '}
          </p>
        </Banner>
      </div>
    )
  }

  if (
    templateQueryLoading ||
    shopDetailsQuery.isLoading ||
    dashboardDetailsQuery.isLoading
  ) {
    return <Loader />
  }

  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <VisualEditorForm
        layout={layout}
        setLayout={setLayout}
        handleSave={handleSave}
        widget={widget}
        // Skipping keys that have values equal to layout values as they are used in visual editor schema
        template={humps.camelizeKeys(template, (key, convert) => {
          if (
            key === CAROUSEL.value ||
            key === HORIZONTAL_GRID.value ||
            key === AMAZON_BOUGHT_TOGETHER.value
          ) {
            return key
          }
          return convert(key)
        })}
        setScreenType={setScreenType}
        screenType={screenType}
        isAmazonBoughtTogetherEnabled={isAmazonBoughtTogetherEnabled}
        storeFrameRef={storeFrameRef}
        setKey={setKey}
        shop={shop}
        frameLoading={frameLoading && !isStorePasswordProtected}
      >
        {/* Widget preview section */}
        <div
          style={{
            width: '100%',
            height: 'calc(100vh - 60px)',
            padding: 'var(--p-space-300)',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              background: 'var(--p-color-bg-surface-active)',
              borderRadius: 'var(--p-space-300)',
              padding: 'var(--p-space-300)',
            }}
          >
            <div
              style={{
                height: '100%',
                width: '100%',
                maxWidth: screenType == 'desktop' ? '100%' : '500px',
                border: isStorePasswordProtected ? '2px dashed red' : undefined,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: 'var(--p-space-200)',
                borderRadius: 'var(--p-space-300)',
              }}
            >
              {isStorePasswordProtected && (
                <Banner
                  title={t('VisualEditorForm.passwordProtectedText')}
                  status="critical"
                />
              )}
              {!isStorePasswordProtected && (
                <StoreFrame
                  ref={storeFrameRef}
                  onLoad={() => {
                    storeFrameRef.current.contentWindow.postMessage(
                      {
                        type: 'rk:queryparams:init',
                        payload: {
                          rkWidgetId: widget.id,
                          rk_preview: true,
                          rk_force_random: true,
                          rk_editor_mode: 'preview',
                        },
                      },
                      '*',
                    )
                    setFrameLoading(false)
                  }}
                  key={key}
                  frameUrl={frameUrl}
                  loading={frameLoading}
                />
              )}
            </div>
          </div>
        </div>
      </VisualEditorForm>
    </div>
  )
}
