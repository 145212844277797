import getApiClient from '.'

export async function executeTask(taskType, data = {}) {
  try {
    const client = await getApiClient()
    const response = await client.post('/api/setup/execute-task', {
      taskType: taskType,
      ...data,
    })
    return {
      data: response.data,
      error: null,
    }
  } catch (error) {
    return {
      data: null,
      error: error,
    }
  }
}
