import {
  BlockStack,
  Box,
  Button,
  Checkbox,
  ColorPicker,
  Divider,
  InlineStack,
  Select,
  Text,
  TextField,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import get from 'lodash.get'

import formSchema from '../../formSchema'
import InputColor from '../../../InputColor'

export default function FormOptionParser({ onClose, activeOptionId, fields, data }) {
  const { t } = useTranslation()
  const activeFields = formSchema.options.find(
    (field) => field.id === activeOptionId,
  )

  return <Box paddingBlock={'300'}>{renderForm(activeFields)}</Box>

  function renderForm(block, level = 0) {
    if (Array.isArray(block)) {
      return (
        <BlockStack gap={'400'}>
          {block.map((field) => {
            return renderForm(field, level + 1)
          })}
        </BlockStack>
      )
    } else if (typeof block === 'object' && !block.type) {
      let blocks = []
      if (Array.isArray(block.fields)) {
        const recCall = renderForm(block.fields, level + 1)
        blocks = blocks.concat(recCall)
      }
      return (
        <BlockStack gap={'100'}>
          {level == 3 && <Divider />}
          <Box paddingBlockEnd={'100'} paddingBlockStart={'100'}>
            <Text as="strong">{t(block.title.key)}</Text>
          </Box>
          {blocks.flat(1)}
        </BlockStack>
      )
    } else {
      let component = null,
        key = (Math.random() * 1000000).toFixed(0)
      const formField = get(fields, block.path)
      switch (block.type) {
        case 'select':
          let options = (block.options || [])
          if(block.optionFilter){
            options = block.optionFilter(options, {section: data.section, page: data.page})
          }
          component = (
            <Select
              label={t(block.title.key)}
              options={(options).map((op) => ({
                label: t(op.label.key),
                value: op.value,
              }))}
              {...formField}
            />
          )
          break
        case 'boolean':
          component = (
            <Checkbox
              {...formField}
              onChange={(value) => {
                formField.onChange(Boolean(value))
              }}
              checked={formField.value}
              label={t(block.title.key)}
            />
          )
          break
        case 'color':
          component = <InputColor label={t(block.title.key)} {...formField} />
          break
        case 'text':
          component = (
            <TextField
              {...formField}
              label={t(block.title.key)}
              suffix={block.suffix}
            />
          )
          break
        case 'number':
          component = (
            <TextField
              key={key}
              {...formField}
              label={t(block.title.key)}
              type="number"
              suffix={block.suffix}
              value={(formField.value || '').toString()}
              onChange={(value) => {
                formField.onChange(parseInt(value))
              }}
            />
          )
          break
      }
      return component
    }
  }
}
