import React, { useCallback, useEffect, useState } from 'react'
import {
  Badge,
  BlockStack,
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  DataTable,
  Divider,
  DropZone,
  IndexFilters,
  InlineGrid,
  InlineStack,
  Link,
  SkeletonBodyText,
  Text,
  Thumbnail,
  useSetIndexFiltersMode,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { format, formatDistanceToNow } from 'date-fns'
import { PlusIcon } from '@shopify/polaris-icons'
import FileDownload from 'js-file-download'

import { getBulkUpload } from '../../../../apis/recommendation'
import Loader from '../../../../components/Loader'
import * as WIDGETS from '../../../../constants/widgets'
import BulkUploadFormModal from '../../../../components/BulkUploadFormModal'
import { BULK_UPLOAD_STATUS } from '../../../../constants/bulkUpload'

const SAMPLE_CSV_DATA = `Product,Recommended Product
Parent Product 1 Title,Single Recommmended Product 1 Title
Parent Product 1 Title,Single Recommmended Product 2 Title
Parent Product 2 Title,Single Recommmended Product 3 Title`

function BulkUpload() {
  const { t } = useTranslation()
  const [filterQuery, setFilterQuery] = React.useState('')
  const query = useQuery({
    queryFn: async () => {
      const res = await getBulkUpload()
      if (res.error) {
        return Promise.reject(res.error)
      }
      return res.data
    },
    queryKey: ['bulkUpload'],
    refetchOnWindowFocus: false,
  })
  const [data, setData] = useState([])
  const [selectedTab, setSelectedTab] = React.useState(0)
  const { mode, setMode } = useSetIndexFiltersMode()
  const [bulkUploadModalOpen, setBulkUploadModalOpen] = useState(false)

  const TagLabelMap = {
    success: t('RecommendationPage.Components.BulkUpload.Status.success'),
    failed: t('RecommendationPage.Components.BulkUpload.Status.error'),
    in_progress: t(
      'RecommendationPage.Components.BulkUpload.Status.inprogress',
    ),
  }

  function onSampleDownload() {
    FileDownload(SAMPLE_CSV_DATA, 'bulk-recommendations-sample.csv')
  }

  const TABS = [
    {
      content: t('RecommendationPage.Components.BulkUpload.filters.all'),
      index: 0,
      id: 'filter-all',
      isLocked: true,
      actions: [],
      onAction: () => {
        setData(query.data)
      },
    },
    {
      content: t('RecommendationPage.Components.BulkUpload.filters.success'),
      index: 1,
      id: 'filter-success',
      isLocked: true,
      actions: [],
      onAction: () => {
        setData((data) =>
          query.data.filter(
            (item) => item.status === BULK_UPLOAD_STATUS.SUCCESS,
          ),
        )
      },
    },
    {
      content: t('RecommendationPage.Components.BulkUpload.filters.error'),
      index: 2,
      id: 'filter-error',
      isLocked: true,
      actions: [],
      onAction: () => {
        setData((data) =>
          query.data.filter(
            (item) => item.status === BULK_UPLOAD_STATUS.FAILED,
          ),
        )
      },
    },
    {
      content: t('RecommendationPage.Components.BulkUpload.filters.initiated'),
      index: 3,
      id: 'filter-initiated',
      isLocked: true,
      actions: [],
      onAction: () => {
        setData((data) =>
          query.data.filter(
            (item) => item.status === BULK_UPLOAD_STATUS.INPROGRESS,
          ),
        )
      },
    },
  ]

  const onHandleSave = async () => {
    await new Promise((res) => {
      setTimeout(() => {
        res('')
      }, 1000)
    })
    return true
  }

  const onHandleCancel = () => {}

  useEffect(() => {
    setData(query.data ?? [])
  }, [query.data])

  useEffect(() => {
    if (!filterQuery) {
      setData(query.data ?? [])
    } else {
      setData((data) =>
        data.filter((item) =>
          item.name.toLowerCase().includes(filterQuery.toLowerCase()),
        ),
      )
    }
  }, [filterQuery])

  if (query.isLoading) {
    return (
      <BlockStack>
        <SkeletonBodyText lines={6} />
      </BlockStack>
    )
  }

  return (
    <>
      <Card padding={'0'}>
        {/* FROM(Hiten): Moving Button Groups upwards becuase there is no way of hiding border in index filter and buttons are looking weird without underline */}
        <InlineGrid columns={'1fr auto'} gap={'200'} alignItems="center">
          <div
            style={{
              paddingLeft: 'var(--p-space-400)',
            }}
          >
            <Text variant="headingSm">
              {t('RecommendationPage.Components.BulkUpload.title')}
            </Text>
          </div>
          <Box padding={'200'}>
            <ButtonGroup>
              <Button variant="plain" onClick={onSampleDownload}>
                {t('RecommendationPage.Components.BulkUpload.actions.download')}
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setBulkUploadModalOpen(true)
                }}
                icon={PlusIcon}
              >
                {t('RecommendationPage.Components.BulkUpload.actions.upload')}
              </Button>
            </ButtonGroup>
          </Box>
        </InlineGrid>
        <Divider />
        <IndexFilters
          queryValue={filterQuery}
          queryPlaceholder={t(
            'RecommendationPage.Components.BulkUpload.placeholders.filterQuery',
          )}
          onQueryChange={setFilterQuery}
          onQueryClear={() => setFilterQuery('')}
          primaryAction={{
            type: 'save',
            onAction: onHandleSave,
            disabled: false,
            loading: false,
          }}
          cancelAction={{
            onAction: onHandleCancel,
            disabled: false,
            loading: false,
          }}
          tabs={TABS}
          selected={selectedTab}
          onSelect={setSelectedTab}
          filters={[]}
          appliedFilters={[]}
          mode={mode}
          setMode={setMode}
          canCreateNewView={false}
        />
        <DataTable
          headings={[
            t('RecommendationPage.Components.BulkUpload.headings.heading1'),
            t('RecommendationPage.Components.BulkUpload.headings.heading2'),
            t('RecommendationPage.Components.BulkUpload.headings.heading3'),
            t('RecommendationPage.Components.BulkUpload.headings.heading4'),
            t('RecommendationPage.Components.BulkUpload.headings.heading6'),
          ]}
          columnContentTypes={['text', 'text', 'text', 'text', 'text']}
          rows={data.map((item) => [
            <Text key={item.id}>{item.name.length > 25 ? `${item.name.slice(0, 25)}...` : item.name}</Text>,
            Object.values(WIDGETS).find(
              (widget) => widget.value === item.recommendationType,
            )?.label ?? 'Recommedation',
            item.rowCount,
            formatDistanceToNow(new Date(item.createdAt), { addSuffix: true }),
            <Badge tone={TagToneMap[item.status]}>
              {TagLabelMap[item.status]}
            </Badge>,
          ])}
          pagination={{
            hasNext: false,
            hasPrevious: false,
            onPrevious: () => {},
            onNext: () => {},
          }}
        />
      </Card>
      <BulkUploadFormModal
        show={bulkUploadModalOpen}
        setShow={setBulkUploadModalOpen}
      />
    </>
  )
}

export default BulkUpload

const TagToneMap = {
  success: 'success',
  failed: 'critical',
  in_progress: 'info',
}
