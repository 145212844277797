const ProductReviewApps = {
  LOOX: 'loox',
  JUDGE_ME: 'judge_me',
  SPR: 'spr',
  RYVIU: 'ryviu',
  STAMPED: 'stamped',
  GROWAVE: 'ssw',
  ALI_REVIEWS: 'ali',
  OKENDO: 'okendo',
  JUNIP: 'junip',
  RIVYO: 'rivyo',
  LAI: 'lai',
}

const SwatchApps = {
  VARIANT_KING: 'variant_options_swatch_king',
  CSP: 'csp',
}

const WishlistApps = {
  GROWAVE_WISHLIST: 'ssw_wishlist',
}

const AnalyticsTypes = {
  GTAG: 'gtag',
  META: 'fbq',
}

const PageTypes = {
  PRODUCT_PAGE: 'product_page',
  COLLECTION: 'collection',
  HOME_PAGE: 'home',
  CART: 'cart',
  ORDER_CONFIRMATION: 'order_confirm',
  OTHER: 'other',
  BLOG: 'blog',
  AJAX_CART: 'ajax_cart',
  NOT_FOUND: '404',
  CHECKOUT: 'checkout',
  RETURNS: 'returns',
}

const RecommendationTypes = {
  BOUGHT_TOGETHER: 'bought_together',
  RECENTLY_VIEWED: 'recently_viewed',
  SIMILAR_PRODUCTS: 'similar_products',
  CROSS_SELL: 'cross_sell',
  BESTSELLERS: 'bestsellers',
  TRENDING_PRODUCTS: 'trending',
  PERSONALIZED: 'personalized',
  DISCOUNTS_FOR_YOU: 'discounts', //TODO >> Delay
  SHOP_THE_LOOK: 'shop_look', // TODO >>> Not in use
  PROMOTED_PRODUCTS: 'promoted',
  RECENTLY_LAUNCHED: 'recent_launch',
  COLLECTIONS_WIDGET: 'collections',
  CUSTOM: 'custom',
  RECENTLY_PURCHASED: 'purchases',
  SEARCH_RESULT: 'search', // TODO >>> Not in use
  SLOTS: 'slots', // TODO >> Delay
}

const AppIntegrations = {
  PRODUCT_REVIEWS: 'product_reviews',
  THANK_YOU_PAGE: 'thank_you_page',
  VARIANT: 'variant',
  SWATCH: 'swatch',
  WISHLIST: 'wishlist',
  PAGE_BUILDER: 'page_builder',
  MOBILE_APP: 'mobile',
  ACR: 'acr',
  RETURNS: 'returns',
}

const Features = {
  DISCOUNTS: 'discounts',
  TRANSLATIONS: 'translations',
  EMAIL_RECOMMENDATIONS: 'emails',
  CUSTOM_TEMPLATES: 'custom_templates',
  SEGMENTS: 'segments',
  GEO_TARGETING: 'geo_targeting',
  RECOMMENDATION_RULES: 'recommendation_rules',
  LIVE_SUPPORT: 'live_support',
  BASIC_RECOMMENDATIONS: 'basic_rec',
  ADVANCED_RECOMMENDATIONS: 'advanced_reco',
  CUSTOM_CSS: 'custom_css',
  SEARCH: 'search',
  PRODUCT_DISCOUNTS: 'product_discounts',
  ZIPCODE_TARGETTING: 'zipcode_targetting',
  AB_EXPERIENCE: 'ab_experience',
  BUNDLES: 'bundles',
  FUNNELS: 'funnels',
  GLOOD_PROXY: 'glood_proxy',
  CHECKOUT_SECTIONS: 'checkout_sections',
  ACCOUNT_PAGE_RECOMMENDATIONS: 'account_page_recommendations',
  POST_PURCHASE_UPSELL: 'post_checkout',
}

const PromotionCodeTypes = {
  EXTEND_FREE_TRIAL: 'extend_trial',
  FLAT_BASE_DISCOUNT: 'flat_base_discount',
  PERCENT_BASE_DISCOUNT: 'percent_base_discount',
  FLAT_BASE_QUOTA_INCREASE: 'flat_base_quota_increase',
  PERCENT_BASE_QUOTA_INCREASE: 'percent_base_quota_increase',
  GENERIC: 'generic',
}

const ShopPlanStatuses = {
  INITIALIZED: 'initialized',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
}

const PromotionCodeStatuses = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  DISABLED: 'disabled',
}

const ChargeIntervals = {
  EVERY_30_DAYS: 'every_30_days',
  ANNUAL: 'annual',
}

const ExperienceStatuses = {
  PUBLISHED: 'published',
  UNPUBLISHED: 'unpublished',
  ARCHIEVED: 'archieved',
}

const SectionLayouts = {
  CUSTOM: 'custom',
  BUCKET: 'bucket',
  HORIZONTAL_GRID: 'horizontal_grid',
  CAROUSEL: 'carousel',
  AMAZON_BOUGHT_TOGETHER: 'amazon_bought_together',
}

const SectionSegments = {
  ALL: 'all',
  FIRST_TIME_VISITOR: 'first_time_visitor',
  RETURNING_VISITOR: 'returning_visitor',
  BUYER: 'buyer',
  IS_CUSTOMER: 'is_customer',
  IS_NOT_CUSTOMER: 'is_not_customer',
}

const FilterCriterias = {
  PRODUCT_TYPE: 'product_type',
  COLLECTION: 'collection',
  PRODUCTS: 'products',
  VENDOR: 'vendor',
}

const FallbackCriterias = {
  RANDOM: 'random',
  BESTSELLERS: 'bestsellers',
  TRENDING: 'trending',
}

const RankingCriterias = {
  PERSONALIZED: 'personalised',
  BESTSELLERS: 'bestsellers',
  NEW_ARRIVALS: 'new_arrivals',
  LOWEST_PRICED: 'lowest_priced',
  HIGHEST_PRICED: 'highest_priced',
  RANDOM: 'random',
}

const CarouselControls = {
  ARROW: 'arrow',
  BULLET: 'bullet',
}

const CreationTypes = {
  MANUAL: 'manual',
  AUTOMATIC: 'automatic',
}

const TemplateTypes = {
  WIDGET: 'widget',
  BUNDLE_PAGE: 'bundle_page',
  BUNDLE_POPUP: 'bundle_popup',
  POPUP: 'popup',
  MINI_CART: 'mini_cart',
  BUNDLE_SECTION: 'bundle_section',
}

const RecommendationRuleStates = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  DELETED: 'deleted',
}

const RecommendationRuleTypes = {
  BUNDLE: 'bundle',
  LIST: 'list',
}

const RecommendationUploadStatuses = {
  IN_PROGRESS: 'in_progress',
  SUCCESS: 'success',
  FAILED: 'failed',
}

const AutoRecommendationObjectTypes = {
  SHOP: 'shop',
  PRODUCT_TYPE: 'product_type',
  COLLECTION: 'collection',
  PRODUCT: 'product',
  PAGE: 'page',
  SEARCH_TERM: 'search_term',
  ZIPCODE: 'zipcode'
}

const CheckoutRecommendationTypes = {
  DISABLE: 'disabled',
  ENABLED: 'enabled'
}

const DiscountTypes = {
  PERCENTAGE: 'percentage',
  FIXED: 'fixed',
}

const CheckoutCampaignTypes = {
  ALL: 'all',
  CONDITIONAL: 'conditional',
}

const CheckoutCampaignTriggerConditionType = {
  OR: 'or',
  AND: 'and',
}

const StorefrontFeatures = {
  CART_UPSELL: 'cart_upsell',
}

const CampaignStatuses = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  PAUSED: 'paused',
  ARCHIVED: 'archived',
}

const CampaignTriggerConditionOptions = {
  PRODUCTS: {
    value: 'products',
    subOptions: {
      specificProducts: {
        value: 'specific_products',
        options: {
          atleast: {
            value: 'atleast',
          },
          excluded: {
            value: 'excluded',
          },
        }
      },
      collection: {
        value: 'collection',
        options: {
          atleast: {
            value: 'atleast',
          },
          excluded: {
            value: 'excluded',
          },
        }
      },
      quantity: {
        value: 'quantity',
        options: {
          greater: {
            value: 'greater',
          },
          lesser: {
            value: 'lesser',
          },
        }
      }
    }
  },
  CART: {
    value: 'cart',
    subOptions: {
      subtotal: {
        value: 'subtotal',
        options: {
          greater: {
            value: 'greater',
          },
          lesser: {
            value: 'lesser',
          },
        }
      },
      quantity: {
        value: 'quantity',
        options: {
          greater: {
            value: 'greater',
          },
          lesser: {
            value: 'lesser',
          },
        }
      },
      subscription: {
        value: 'subscription',
        options: {
          true: {
            value: 'true',
          },
          false: {
            value: 'false',
          },
        }
      },
    }
  },
  DISCOUNT: {
    value: 'discount',
    subOptions: {
      value: {
        value: 'value',
        options: {
          greater: {
            value: 'greater',
          },
          lesser: {
            value: 'lesser',
          },
        }
      }
    }
  },
}

const BundleState = {
  CREATED: 'created',
  COMPLETE: 'complete',
  FAILED: 'failed',
}

const ShopifyBundleStatus = {
  ACTIVE: 'active',
  DRAFT: 'draft',
  ARCHIVED: 'archived'
}

const ProductStatus = {
  ACTIVE: "ACTIVE"
}

const BundleSettingsType = {
  UNIQUE: 'unique_option',
  PRODUCT: 'product',
  CUSTOM: 'custom',
}

const BundleType = {
  CUSTOM: 'custom',
  PACK_OF_N: 'pack_of_n',
  BUY_X_GET_Y: 'buy_x_get_y',
  MIX_MATCH: 'mix_match',
  UPSELL: 'upsell',
  GIFT: 'gift',
}

const Tasks = {
  PRODUCT_SYNC: 'product_sync',
  SHOPIFY_SYNC: 'shopify_sync',
}

const DiscountStatus = {
  ACTIVE: 'active',
  EXPIRED: 'expired',
  SCHEDULED: 'scheduled',
  DELETED: 'deleted',
}

const DiscountType = {
  AUTOMATIC_APP: 'automatic_app'
}

const DiscountClass = {
  ORDER: 'order',
  PRODUCT: 'product',
  SHIPPING: 'shipping',
}

const DiscountTargetType = {
  LINE_ITEM: 'line_item',
  SHIPPING_LINE: 'shipping_line'
}

const ObjectType = {
  SECTION: 'section',
}

const WebPixelEventTypes = {
  STANDARD: 'standard',
  CUSTOM: 'custom',
}

const WebPixelEventNames = {
  GLOOD_CUSTOMER_PREFERENCES: 'glood:customer_preference',
  SHOPIFY_CART_VIEWED: 'cart_viewed',
  SHOPIFY_CHECKOUT_COMPLETED: 'checkout_completed',
  SHOPIFY_CHECKOUT_STARTED: 'checkout_started',
  SHOPIFY_CHECKOUT_SHIPPING_INFO_SUBMITTED: 'checkout_shipping_info_submitted',
  SHOPIFY_CHECKOUT_CONTACT_INFO_SUBMITTED: 'checkout_contact_info_submitted',
  SHOPIFY_CHECKOUT_ADDRESS_INFO_SUBMITTED: 'checkout_address_info_submitted',
  SHOPIFY_PAYMENT_INFO_SUBMITTED: 'payment_info_submitted',
  SHOPIFY_COLLECTION_VIEWED: 'collection_viewed',
  SHOPIFY_PAGE_VIEWED: 'page_viewed',
  SHOPIFY_PRODUCT_ADDED_TO_CART: 'product_added_to_cart',
  SHOPIFY_PRODUCT_REMOVED_FROM_CART: 'product_removed_from_cart',
  SHOPIFY_PRODUCT_VIEWED: 'product_viewed',
  SHOPIFY_SEARCH_SUBMITTED: 'search_submitted',
  SHOPIFY_UI_EXTENSION_ERRORED: 'ui_extension_errored',
  GLOOD_SECTION_ADD_TO_CART: 'glood:section:add_to_cart',
  GLOOD_SECTION_CLICK: 'glood:section:click',
  SHOPIFY_ALERT_DISPLAYED: 'alert_displayed',
  GLOOD_SECTION_RENDER: 'glood:section:render',
  GLOOD_SECTION_VIEW: 'glood:section:view',
  GLOOD_BUNDLE_ADD_TO_CART: 'glood:bundle:add_to_cart',
  GLOOD_BUNDLE_RENDER: 'glood:bundle:render',
  GLOOD_BUNDLE_VIEW: 'glood:bundle:view',
  GLOOD_BUNDLE_CLICK: 'glood:bundle:click',
  GLOOD_CHECKOUT_ADD_TO_CART: 'glood:checkout:add_to_cart',
  GLOOD_CHECKOUT_RENDER: 'glood:checkout:render',
  GLOOD_CHECKOUT_OFFER_ACCEPT: 'glood:checkout:offer_accept',
  GLOOD_CHECKOUT_OFFER_REJECT: 'glood:checkout:offer_reject',
  GLOOD_CHECKOUT_CLICK: 'glood:checkout:click',
  GLOOD_POST_PURCHASE_RENDER: 'glood:post_purchase:render',
  GLOOD_INIT: 'glood:init',
}

const AttributionFeature = {
  RECOMMENDATIONS: 'recommendations',
  CHECKOUT_UPSELL: 'checkout_upsell',
  ACCOUNT_UPSELL: 'account_upsell',
  BUNDLE: 'bundle',
}

const RecommendationView = {
  PRODUCT_DETAILS: 'product_details',
  PRODUCT_IDS: 'product_ids',
}

const EmbeddingTypes = {
  PRODUCT: 'product',
  SIMILAR: 'similar',
  BOUGHT_TOGETHER: 'boughtTogether',
}

const RecommendationsRankingCriteria = {
  PERSONALIZED: 'personalised',
  BESTSELLERS: 'bestsellers',
  NEW_ARRIVALS: 'new_arrivals',
  LOWEST_PRICED: 'lowest_priced',
  HIGHEST_PRICED: 'highest_priced',
}

const CheckoutType = {
  POST_CEHCKOUT: 'post_checkout',
  CHECKOUT_UPSELL: 'checkout_upsell',
}

const AtributedSource = {
  RECOMMENDATIONS: 'recommendations',
  CHECKOUT_CAMPAIGN: 'checkout_campaign',
  BUNDLE: 'bundle',
  ACCOUNT: 'account',
}

const EnrichmentTypes = {
  PRODUCT_EMBEDDING: 'product_embedding',
  BOUGHT_TOGETHER: 'bought_together_embedding',
}

const TaskTypes = {
  CREATE_STOREFRONT_TOKEN: 'create_storefront_token',
  ENABLE_WEBPIXEL: 'enable_webpixel',
}


export {
  ProductReviewApps,
  SwatchApps,
  WishlistApps,
  AnalyticsTypes,
  PageTypes,
  RecommendationTypes,
  AppIntegrations,
  Features,
  PromotionCodeTypes,
  ShopPlanStatuses,
  PromotionCodeStatuses,
  ChargeIntervals,
  ExperienceStatuses,
  SectionLayouts,
  SectionSegments,
  FilterCriterias,
  FallbackCriterias,
  RankingCriterias,
  CarouselControls,
  CreationTypes,
  TemplateTypes,
  RecommendationRuleStates,
  RecommendationRuleTypes,
  RecommendationUploadStatuses,
  AutoRecommendationObjectTypes,
  CheckoutRecommendationTypes,
  DiscountTypes,
  CheckoutCampaignTypes,
  CheckoutCampaignTriggerConditionType,
  StorefrontFeatures,
  CampaignStatuses,
  CampaignTriggerConditionOptions,
  BundleState,
  BundleSettingsType,
  Tasks,
  BundleType,
  ShopifyBundleStatus,
  DiscountStatus,
  DiscountClass,
  DiscountTargetType,
  ObjectType,
  DiscountType,
  WebPixelEventTypes,
  WebPixelEventNames,
  AttributionFeature,
  RecommendationView,
  EmbeddingTypes,
  RecommendationsRankingCriteria,
  CheckoutType,
  AtributedSource,
  EnrichmentTypes,
  TaskTypes,
  ProductStatus
}
