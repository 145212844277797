import { decamelizeKeys } from 'humps'
import mixpanel from 'mixpanel-browser'

/**
 * For event details, check glood-app wiki:
 * https://github.com/loopclub/glood-app/wiki/Dashboard-Events
 */

export class MixPanelAnalytics {
  constructor() {
    this.isInitialized = this.initMixpanel()
  }

  initMixpanel() {
    const token = process.env.VITE_MIXPANEL_TOKEN

    if (!token) {
      console.warn('MixPanel token is missing. Analytics will be disabled.')
      return false
    }

    try {
      mixpanel.init(token, {
        disable_persistence: true,
        ignore_dnt: true,
      })
      mixpanel.set_group('myshopify_domain', window.shopify.config.shop)
      return true
    } catch (error) {
      console.error('Failed to initialize MixPanel:', error)
      return false
    }
  }

  track(eventName, properties = {}) {
    if (!this.isInitialized) return
    if (!Object.values(MixPanelAnalytics.Events).includes(eventName)) {
      console.error(`Invalid event name: ${eventName}`)
      return
    }
    try {
      mixpanel.track(eventName, {
        timestamp: new Date().toISOString(),
        ...properties,
      })
    } catch (error) {
      console.error(`Failed to track ${eventName}:`, error)
    }
  }

  logPerformanceMetrics({ metric, value }) {
    this.track(MixPanelAnalytics.Events.PERFORMANCE_METRICS, {
      metric,
      value,
      page_url: window.location.href,
      path: window.location.pathname,
      myshopify_domain: window.shopify.config.shop,
    })
  }

  trackSectionEvent(eventName, section, pageType) {
    const key = 'section::' + eventName

    const payload = {
      myshopify_domain: window.shopify.config.shop,
      pageType: pageType,
      recommendation_type: section.type,
      experience: section.experience,
    }

    if (
      [
        MixPanelAnalytics.Events.SECTION_CREATE_COMPLETED,
        MixPanelAnalytics.Events.SECTION_DELETE,
        MixPanelAnalytics.Events.SECTION_ENABLED,
        MixPanelAnalytics.Events.SECTION_DISABLED,
        MixPanelAnalytics.Events.SECTION_UPDATE,
      ].includes(key)
    ) {
      payload.layout = section.layout
      payload.section = section.id
      payload.enabled = section.enabled
    }

    this.track(key, payload)
  }

  trackTemplateEvent(eventName, template) {
    const key = 'template::' + eventName

    const payload = {
      myshopify_domain: window.shopify.config.shop,
      version: template.version,
      type: template.type,
      clone_of: template.source || null,
    }

    if (
      [
        MixPanelAnalytics.Events.TEMPLATE_DELETE,
        MixPanelAnalytics.Events.TEMPLATE_UPDATE,
      ].includes(key)
    ) {
      payload.template = template.id
    }

    this.track(key, payload)
  }

  trackExperienceEvent(eventName, experience) {
    const key = 'experience::' + eventName

    const payload = {
      myshopify_domain: window.shopify.config.shop,
      weightage: experience.weight,
      start_date: new Date(experience.startTime).toISOString(),
      end_date: new Date(experience.endTime).toISOString(),
    }

    if (
      [
        MixPanelAnalytics.Events.EXPERIENCE_DELETE,
        MixPanelAnalytics.Events.EXPERIENCE_UPDATE,
      ].includes(key)
    ) {
      payload.experience = experience.id
      payload.status = experience.status
      payload.is_default = experience.isDefault
    }

    this.track(key, payload)
  }

  trackVisualEditorEvent(eventName, section, pageType, template) {
    const key = 'visual_editor::' + eventName

    const payload = {
      myshopify_domain: window.shopify.config.shop,
      section: section.id,
      template: template.id,
      page_type: pageType,
      layout: section.layout,
      recommendation_type: section.type,
      experience: section.experience,
      template: template.id,
      template_version: template.version,
    }

    this.track(key, payload)
  }

  trackPlanInitEvent(
    eventName,
    {
      previousPlan,
      updatedPlan,
      previousPrice,
      updatedPrice,
      reason,
      feedback,
      otherReason,
    },
  ) {
    const key = 'plan::' + eventName

    const payload = {
      myshopify_domain: window.shopify.config.shop,
      previous_plan: previousPlan,
      updated_plan: updatedPlan,
      previous_price: previousPrice,
      updated_price: updatedPrice,
    }

    if (key === MixPanelAnalytics.Events.PLAN_DOWNGRADE_INITIATED) {
      payload.reason = reason
      payload.other_reason = otherReason
      payload.feedback = feedback
    }

    this.track(key, payload)
  }

  trackChargeConfirmEvent({ plan, price }) {
    const payload = {
      myshopify_domain: window.shopify.config.shop,
      plan,
      price,
    }

    this.track(MixPanelAnalytics.Events.CHARGE_CONFIRM, payload)
  }

  trackPageEvent(eventName) {
    const key = 'page::' + eventName

    const payload = {
      myshopify_domain: window.shopify.config.shop,
      page_type: window.location.pathname,
    }

    this.track(key, payload)
  }

  trackButtonEvent(eventName, action) {
    const key = 'btn::' + eventName

    const payload = {
      myshopify_domain: window.shopify.config.shop,
      page_url: window.location.pathname,
      action: action,
    }

    this.track(key, payload)
  }

  trackBundleEvent(eventName, bundle) {
    const key = 'bundle::' + eventName

    const payload = {
      myshopify_domain: window.shopify.config.shop,
      bundle_type: bundle.bundleType,
      bundle_configuration_type: bundle.bundleConfigurationType,
      product_count: bundle.productIds.length,
      template: bundle.template,
      status: bundle.status,
    }

    if (
      [
        MixPanelAnalytics.Events.BUNDLE_CREATE_COMPLETED,
        MixPanelAnalytics.Events.BUNDLE_DELETE,
        MixPanelAnalytics.Events.BUNDLE_ENABLED,
        MixPanelAnalytics.Events.BUNDLE_DISABLED,
      ].includes(key)
    ) {
      payload.bundle = bundle.id
    }

    this.track(key, payload)
  }

  trackCheckoutCampaignEvent(eventName, checkoutCampaign) {
    const key = 'checkout_campaign::' + eventName
    if (!Object.values(MixPanelAnalytics.Events).includes(key)) {
      console.error(`Invalid event name: ${key}`)
      return
    }

    const payload = {
      myshopify_domain: window.shopify.config.shop,
      start_date: checkoutCampaign.startDate
        ? new Date(checkoutCampaign.startDate).toISOString()
        : null,
      end_date: checkoutCampaign.endDate
        ? new Date(checkoutCampaign.endDate).toISOString()
        : null,
    }

    if (
      [
        MixPanelAnalytics.Events.CHECKOUT_CAMPAIGN_CREATE_COMPLETED,
        MixPanelAnalytics.Events.CHECKOUT_CAMPAIGN_DELETE,
        MixPanelAnalytics.Events.CHECKOUT_CAMPAIGN_ENABLED,
        MixPanelAnalytics.Events.CHECKOUT_CAMPAIGN_DISABLED,
        MixPanelAnalytics.Events.CHECKout_CAMPAIGN_UPDATE,
      ].includes(key)
    ) {
      payload.campaign = checkoutCampaign.id
      payload.status = checkoutCampaign.status
      payload.trigger_type = checkoutCampaign.triggerType
    }

    this.track(key, payload)
  }

  identifyUser(storeMetrics) {
    if (!this.isInitialized) return false
    try {
      mixpanel.identify(storeMetrics.email)
      mixpanel.people.set({
        'Shopify Plan': storeMetrics.shopifyPlan,
        'Glood Plan': `${storeMetrics.gloodPlan}`,
        'Glood Price': Number(storeMetrics.gloodPrice),
        'Current Serve Usage': storeMetrics.gloodCurrentServeUsage,
        'Maximum Serve Usage': storeMetrics.gloodMaxServeUsage,
        'Store Domain': storeMetrics.shopifyMyDomain,
        'Is Store Owner': storeMetrics.isStoreOwner,
        'Is Collaborator': storeMetrics.isCollaborator,
        'Store Created At': storeMetrics.storeCreatedAt,
        'Store Country': storeMetrics.storeCountry,
        'User Country': storeMetrics.userCountry,
        $email: storeMetrics.email,
      })
      return true
    } catch (error) {
      console.error('Failed to identify user:', error)
      return false
    }
  }

  trackOnboarding(data) {
    this.track(MixPanelAnalytics.Events.ONBOARDING,{
      myshopify_domain: window.shopify.config.shop,
      ...decamelizeKeys(data),
    })
  }

  trackFeedback({ rating, feedback }) {
    this.track(MixPanelAnalytics.Events.FEEDBACK_SUBMITTED, {
      myshopify_domain: window.shopify.config.shop,
      rating,
      feedback,
    })
  }

  static Events = {
    // Performance Events
    PERFORMANCE_METRICS: 'dashboard::performance_metrics',

    // Section Events
    SECTION_CREATE_INITIATED: 'section::create::initiated',
    SECTION_CREATE_COMPLETED: 'section::create::completed',
    SECTION_DELETE: 'section::delete',
    SECTION_ENABLED: 'section::enabled',
    SECTION_DISABLED: 'section::disabled',
    SECTION_UPDATE: 'section::update',

    // Template Events
    TEMPLATE_CREATE: 'template::create',
    TEMPLATE_DELETE: 'template::delete',
    TEMPLATE_UPDATE: 'template::update',

    // Experience Events
    EXPERIENCE_CREATE: 'experience::create',
    EXPERIENCE_DELETE: 'experience::delete',
    EXPERIENCE_UPDATE: 'experience::update',

    // Visual Editor Events
    VISUAL_EDITOR_OPEN: 'visual_editor::open',
    VISUAL_EDITOR_SAVE: 'visual_editor::save',

    // Plan Events
    PLAN_UPGRADE_INITIATED: 'plan::upgrade::initiated',
    PLAN_DOWNGRADE_INITIATED: 'plan::downgrade::initiated',
    CHARGE_CONFIRM: 'plan::charge::confirmed',

    // Navigation Events
    PAGE_VIEW: 'page::view',
    BUTTON_CLICK: 'btn::click',

    // Bundle Events
    BUNDLE_CREATE_INITIATED: 'bundle::create::initiated',
    BUNDLE_CREATE_COMPLETED: 'bundle::create::completed',
    BUNDLE_DELETE: 'bundle::delete',
    BUNDLE_ENABLED: 'bundle::enabled',
    BUNDLE_DISABLED: 'bundle::disabled',

    // Checkout Campaign Events
    CHECKOUT_CAMPAIGN_CREATE_INITIATED: 'checkout_campaign::create::initiated',
    CHECKOUT_CAMPAIGN_CREATE_COMPLETED: 'checkout_campaign::create::completed',
    CHECKOUT_CAMPAIGN_DELETE: 'checkout_campaign::delete',
    CHECKOUT_CAMPAIGN_ENABLED: 'checkout_campaign::enabled',
    CHECKOUT_CAMPAIGN_DISABLED: 'checkout_campaign::disabled',
    CHECKout_CAMPAIGN_UPDATE: 'checkout_campaign::update',

    // Feedback
    FEEDBACK_SUBMITTED: 'feedback::submitted',

    //Onboarding
    ONBOARDING: 'onboarding',
  }

  static Actions = {
    CREATE: 'create',
    DELETE: 'delete',
    ENABLE: 'enabled',
    DISABLE: 'disabled',
    UPDATE: 'update',
    CREATE_COMPLETED: 'create::completed',
    CREATE_INITIATED: 'create::initiated',
    CLICK: 'click',
    VIEW: 'view',
    OPEN: 'open',
    CLOSE: 'close',
    UPGRADE_INITIATED: 'upgrade::initiated',
    DOWNGRADE_INITIATED: 'downgrade::initiated',
    SAVE: 'save'
  }
}

const analytics = new MixPanelAnalytics()
export default analytics
