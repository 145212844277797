import {
  InlineStack,
  Select,
  Tooltip,
  Text,
  Icon,
  Form,
  TextField,
  Grid,
  Button,
  Box,
  BlockStack,
} from '@shopify/polaris'
import { InfoIcon } from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'
import humps, { camelize, decamelize } from 'humps'
import { notEmpty, useField, useForm } from '@shopify/react-form'

import useMultiLangTranlationForm from './hooks/useMultiLangTranlationForm'
import {
  TEMPLATE_LAYOUTS,
  TEMPLATE_TYPES,
} from '../../../../constants/templates'
import { updateTemplate } from '../../../../apis/template'
import queryClient from '../../../../utils/query'
import LANGUAGES from '../../../../constants/languages'
import { useEffect, useMemo, useState } from 'react'
import merge from 'lodash.merge'

function LabelComponent({ label, helpText }) {
  return (
    <div>
      <InlineStack>
        <Text>{label}</Text>
        <Tooltip content={helpText} dismissOnMouseOut>
          <Icon source={InfoIcon} />
        </Tooltip>
      </InlineStack>
    </div>
  )
}

function MultiLanguageTranslationsV1({ config }) {
  const { t } = useTranslation()
  const shopPrimaryLocale = window.shopify.config.locale.split('-')[0]
  const chooseLanguageField = useField(shopPrimaryLocale)
  const { fields, submit, submitting } = useMultiLangTranlationForm({
    currentValues: humps.camelizeKeys({
      ...(config.translations[
        chooseLanguageField.value == shopPrimaryLocale
          ? 'primary_locale'
          : chooseLanguageField.value
      ] ?? {}),
    }),
    chooseLanguageField,
    onSubmit: async (data) => {
      let objects = {}
      if (config.type !== TEMPLATE_TYPES.BUNDLE_SECTION) {
        objects = {
          go_to_cart_text: data.goToCartTextfield,
          add_to_cart_text: data.addToCartTextfield,
          out_of_stock_text: data.outOfStockTextfield,
          added_to_cart_text: data.addedToCartTextfield,
          adding_to_cart_text: data.addingToCartTextfield,
          discount_label_text: data.discountLabelTextfield,
          discount_text: data.discountTextTextfieled,
        }
        if (config.layout === TEMPLATE_LAYOUTS.FBT) {
          objects.fbt_add_to_cart_text = data.fbtAddToCartTextfield
          objects.this_product_text = data.thisProductTextfield
          objects.total_price_text = data.totalPriceTextfield
        }
      } else if (config.type === TEMPLATE_TYPES.BUNDLE_SECTION) {
        objects = {
          bundle_section_title: data.bundleSectionTitleTextfield,
          product_component_title: data.productComponentTitleTextField,
          total_price_text: data.totalPriceTextfield,
          add_to_cart_text: data.addToCartTextfield,
          out_of_stock_text: data.outOfStockTextfield,
          added_to_cart_text: data.addedToCartTextfield,
          bundle_discount: data.bundleDiscountTextfield,
          discount_label_text: data.discountLabelTextfield,
        }
      }
      const payload = {
        translations: {
          ...config.translations,
          [data.chooseLanguageField === shopPrimaryLocale
            ? 'primary_locale'
            : data.chooseLanguageField]: objects,
        },
      }

      const res = await updateTemplate(config.id, payload)
      if (res.error) {
        shopify.toast.show('Some Error Occured!', {
          isError: true,
        })
        return {
          status: 'error',
          message: res.error,
        }
      }

      await queryClient.invalidateQueries(['templates', config.id])
      shopify.toast.show(
        t('DefaultText.updateSuccess', {
          text: t('DefaultText.settings'),
        }),
      )
      return {
        status: 'success',
      }
    },
    type: config.layout,
    templateType: config.type,
  })

  const options = LANGUAGES.map((lang) => ({
    label: lang.label,
    value: lang.value,
  }))

  if (config.type === TEMPLATE_TYPES.BUNDLE_SECTION) {
    return (
      <div style={{ padding: 'var(--p-space-400)' }}>
        <Form onSubmit={submit}>
          <Grid gap={300}>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 12, xl: 12 }}>
              <Select
                {...fields.chooseLanguageField}
                label={
                  <LabelComponent
                    label={t(
                      'Templates.Configure.MultiLanguageTranslations.ChooseLanguage.label',
                    )}
                    helpText={t(
                      'Templates.Configure.MultiLanguageTranslations.ChooseLanguage.helpText',
                    )}
                  />
                }
                options={options}
              />
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <TextField
                {...fields.bundleSectionTitleTextfield}
                autoComplete="text"
                type="text"
                label={
                  <LabelComponent
                    label={t(
                      'Templates.Configure.MultiLanguageTranslations.bundleSectionTitleTextfield.label',
                    )}
                    helpText={t(
                      'Templates.Configure.MultiLanguageTranslations.bundleSectionTitleTextfield.helpText',
                      {
                        count: <strong>{'{{count}}'}</strong>,
                      },
                    )}
                  />
                }
              />
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <TextField
                {...fields.productComponentTitleTextField}
                autoComplete="text"
                type="text"
                label={
                  <LabelComponent
                    label={t(
                      'Templates.Configure.MultiLanguageTranslations.productSectionTitleTextfield.label',
                    )}
                    helpText={t(
                      'Templates.Configure.MultiLanguageTranslations.productSectionTitleTextfield.helpText',
                      {
                        count: <strong>{'{{count}}'}</strong>,
                      },
                    )}
                  />
                }
              />
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <TextField
                {...fields.totalPriceTextfield}
                autoComplete="text"
                type="text"
                label={
                  <LabelComponent
                    label={t(
                      'Templates.Configure.MultiLanguageTranslations.totalPriceTextBundle.label',
                    )}
                    helpText={t(
                      'Templates.Configure.MultiLanguageTranslations.totalPriceTextBundle.helpText',
                      {
                        totalPrice: <strong>{'{{total_price}}'}</strong>,
                      },
                    )}
                  />
                }
              />
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <TextField
                {...fields.addToCartTextfield}
                autoComplete="text"
                type="text"
                label={
                  <LabelComponent
                    label={t(
                      'Templates.Configure.MultiLanguageTranslations.addToCartText.label',
                    )}
                    helpText={t(
                      'Templates.Configure.MultiLanguageTranslations.addToCartText.helpText',
                    )}
                  />
                }
              />
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <TextField
                {...fields.addedToCartTextfield}
                autoComplete="text"
                type="text"
                label={
                  <LabelComponent
                    label={t(
                      'Templates.Configure.MultiLanguageTranslations.addedToCartText.label',
                    )}
                    helpText={t(
                      'Templates.Configure.MultiLanguageTranslations.addedToCartText.helpText',
                    )}
                  />
                }
              />
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <TextField
                {...fields.outOfStockTextfield}
                autoComplete="text"
                type="text"
                label={
                  <LabelComponent
                    label={t(
                      'Templates.Configure.MultiLanguageTranslations.outOfStockText.label',
                    )}
                    helpText={t(
                      'Templates.Configure.MultiLanguageTranslations.outOfStockText.helpText',
                    )}
                  />
                }
              />
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <TextField
                {...fields.bundleDiscountTextfield}
                autoComplete="text"
                type="text"
                label={
                  <LabelComponent
                    label={t(
                      'Templates.Configure.MultiLanguageTranslations.bundleDiscount.label',
                    )}
                    helpText={t(
                      'Templates.Configure.MultiLanguageTranslations.bundleDiscount.helpText',
                      {
                        percent: <strong>{'{{percent}}'}</strong>,
                      },
                    )}
                  />
                }
              />
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
              <TextField
                {...fields.discountLabelTextfield}
                autoComplete="text"
                type="text"
                label={
                  <LabelComponent
                    label={t(
                      'Templates.Configure.MultiLanguageTranslations.discountLabelTextBundle.label',
                    )}
                    helpText={t(
                      'Templates.Configure.MultiLanguageTranslations.discountLabelTextBundle.helpText',
                      {
                        percent: <strong>{'{{percent}}'}</strong>,
                      },
                    )}
                  />
                }
              />
            </Grid.Cell>
          </Grid>
          <Box padding={'200'} />
          <Button loading={submitting} variant="primary" size="large" submit>
            {t('Templates.Configure.MultiLanguageTranslations.buttonText')}
          </Button>
        </Form>
      </div>
    )
  }

  return (
    <Box padding={'400'}>
      <Form onSubmit={submit}>
        <Grid gap={300}>
          <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 12, xl: 12 }}>
            <Select
              {...fields.chooseLanguageField}
              label={
                <LabelComponent
                  label={t(
                    'Templates.Configure.MultiLanguageTranslations.ChooseLanguage.label',
                  )}
                  helpText={t(
                    'Templates.Configure.MultiLanguageTranslations.ChooseLanguage.helpText',
                  )}
                />
              }
              options={options}
            />
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
            <TextField
              {...fields.addToCartTextfield}
              autoComplete="text"
              type="text"
              label={
                <LabelComponent
                  label={t(
                    'Templates.Configure.MultiLanguageTranslations.addToCartText.label',
                  )}
                  helpText={t(
                    'Templates.Configure.MultiLanguageTranslations.addToCartText.helpText',
                  )}
                />
              }
            />
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
            <TextField
              {...fields.addedToCartTextfield}
              autoComplete="text"
              type="text"
              label={
                <LabelComponent
                  label={t(
                    'Templates.Configure.MultiLanguageTranslations.addedToCartText.label',
                  )}
                  helpText={t(
                    'Templates.Configure.MultiLanguageTranslations.addedToCartText.helpText',
                  )}
                />
              }
            />
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
            <TextField
              {...fields.addingToCartTextfield}
              autoComplete="text"
              type="text"
              label={
                <LabelComponent
                  label={t(
                    'Templates.Configure.MultiLanguageTranslations.addingToCartText.label',
                  )}
                  helpText={t(
                    'Templates.Configure.MultiLanguageTranslations.addingToCartText.helpText',
                  )}
                />
              }
            />
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
            <TextField
              {...fields.discountLabelTextfield}
              autoComplete="text"
              type="text"
              label={
                <LabelComponent
                  label={t(
                    'Templates.Configure.MultiLanguageTranslations.discountLabelText.label',
                  )}
                  helpText={t(
                    'Templates.Configure.MultiLanguageTranslations.discountLabelText.helpText',
                  )}
                />
              }
            />
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
            <TextField
              {...fields.goToCartTextfield}
              autoComplete="text"
              type="text"
              label={
                <LabelComponent
                  label={t(
                    'Templates.Configure.MultiLanguageTranslations.goToCartText.label',
                  )}
                  helpText={t(
                    'Templates.Configure.MultiLanguageTranslations.goToCartText.helpText',
                  )}
                />
              }
            />
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
            <TextField
              {...fields.outOfStockTextfield}
              autoComplete="text"
              type="text"
              label={
                <LabelComponent
                  label={t(
                    'Templates.Configure.MultiLanguageTranslations.outOfStockText.label',
                  )}
                  helpText={t(
                    'Templates.Configure.MultiLanguageTranslations.outOfStockText.helpText',
                  )}
                />
              }
            />
          </Grid.Cell>
          <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
            <TextField
              {...fields.discountTextTextfieled}
              autoComplete="text"
              type="text"
              label={
                <LabelComponent
                  label={t(
                    'Templates.Configure.MultiLanguageTranslations.discountText.label',
                  )}
                  helpText={t(
                    'Templates.Configure.MultiLanguageTranslations.discountText.helpText',
                  )}
                />
              }
            />
          </Grid.Cell>
          {config.layout === TEMPLATE_LAYOUTS.FBT && (
            <>
              <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                <TextField
                  {...fields.fbtAddToCartTextfield}
                  autoComplete="text"
                  type="text"
                  label={
                    <LabelComponent
                      label={t(
                        'Templates.Configure.MultiLanguageTranslations.fbtAddToCartText.label',
                      )}
                      helpText={t(
                        'Templates.Configure.MultiLanguageTranslations.fbtAddToCartText.helpText',
                      )}
                    />
                  }
                />
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                <TextField
                  {...fields.thisProductTextfield}
                  autoComplete="text"
                  type="text"
                  label={
                    <LabelComponent
                      label={t(
                        'Templates.Configure.MultiLanguageTranslations.thisProductText.label',
                      )}
                      helpText={t(
                        'Templates.Configure.MultiLanguageTranslations.thisProductText.label',
                      )}
                    />
                  }
                />
              </Grid.Cell>
              <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 6, xl: 6 }}>
                <TextField
                  {...fields.totalPriceTextfield}
                  autoComplete="text"
                  type="text"
                  label={
                    <LabelComponent
                      label={t(
                        'Templates.Configure.MultiLanguageTranslations.totalPriceText.label',
                      )}
                      helpText={t(
                        'Templates.Configure.MultiLanguageTranslations.totalPriceText.helpText',
                      )}
                    />
                  }
                />
              </Grid.Cell>
            </>
          )}
        </Grid>
        <Box padding={'200'} />
        <Button loading={submitting} variant="primary" size="large" submit>
          {t('Templates.Configure.MultiLanguageTranslations.buttonText')}
        </Button>
      </Form>
    </Box>
  )
}

function MultiLanguageTranslationsV2({ config }) {
  const { t } = useTranslation()
  const shopPrimaryLocale = window.shopify.config.locale.split('-')[0]
  const chooseLanguageField = useField('primary_locale')
  const fields = useMemo(
    () => [
      {
        key: 'atc_text',
        defaultValue:
          config.translations[chooseLanguageField.value]?.atc_text ??
          'Add to Cart',
      },
      {
        key: 'new_text',
        defaultValue:
          config.translations[chooseLanguageField.value]?.new_text ?? 'New',
      },
      {
        key: 'oos_text',
        defaultValue:
          config.translations[chooseLanguageField.value]?.oos_text ??
          'Out of stock',
      },
      {
        key: 'discount_text',
        defaultValue:
          config.translations[chooseLanguageField.value]?.discount_text ??
          'Get Flat Discount of {percentage}',
      },
      {
        key: 'fbt_save_text',
        defaultValue:
          config.translations[chooseLanguageField.value]?.fbt_save_text ??
          'Save {percent}',
      },
      {
        key: 'trending_text',
        defaultValue:
          config.translations[chooseLanguageField.value]?.trending_text ??
          'Trending',
      },
      {
        key: 'atc_error_text',
        defaultValue:
          config.translations[chooseLanguageField.value]?.atc_error_text ??
          'Error adding to cart',
      },
      {
        key: 'bestseller_text',
        defaultValue:
          config.translations[chooseLanguageField.value]?.bestseller_text ??
          'Bestseller',
      },
      {
        key: 'total_price_text',
        defaultValue:
          config.translations[chooseLanguageField.value]?.total_price_text ??
          'Total Price',
      },
      {
        key: 'added_to_cart_text',
        defaultValue:
          config.translations[chooseLanguageField.value]?.added_to_cart_text ??
          'Added to cart',
      },
      {
        key: 'discount_save_text',
        defaultValue:
          config.translations[chooseLanguageField.value]?.discount_save_text ??
          'Save {amount}',
      },
      {
        key: 'discount_label_text',
        defaultValue:
          config.translations[chooseLanguageField.value]?.discount_label_text ??
          'Save {amount}',
      },
      {
        key: 'fbt_add_to_cart_text',
        defaultValue:
          config.translations[chooseLanguageField.value]
            ?.fbt_add_to_cart_text ?? 'Add to cart',
      },
      {
        key: 'fbt_added_to_cart_text',
        defaultValue:
          config.translations[chooseLanguageField.value]
            ?.fbt_added_to_cart_text ?? 'Added to cart',
      },
    ],
    [chooseLanguageField.value],
  )

  const options = LANGUAGES.map((lang) => ({
    label: lang.label,
    value: lang.value,
  })).map((option) => {
    if (shopPrimaryLocale === option.value) {
      option.value = 'primary_locale'
    }
    return option
  })

  const languageFields = fields.reduce(
    (acc, curr) => {
      acc[camelize(curr.key)] = useField({
        value: curr.defaultValue,
        validates: [notEmpty(t(`TemplatesV3.${curr.key}.errorText`))],
      })
      return acc
    },
    {
      chooseLanguageField,
    },
  )

  const form = useForm({
    fields: languageFields,
    onSubmit: async (data) => {
      const copy = { ...config.translations }
      const selectedLang = data.chooseLanguageField
      delete copy[selectedLang]

      const translations = Object.entries(data).reduce((acc, [key, value]) => {
        acc[decamelize(key)] = value
        return acc
      }, {})

      const payload = merge(config.translations, {
        [selectedLang]: translations,
      })

      const res = await updateTemplate(config.id, {
        translations: payload,
      })

      if (res.error) {
        shopify.toast.show('Some Error Occured!', {
          isError: true,
        })
        return {
          status: 'error',
          message: res.error,
        }
      }

      await queryClient.invalidateQueries(['templates', config.id])
      shopify.toast.show(
        t('DefaultText.updateSuccess', {
          text: t('DefaultText.settings'),
        }),
      )
      return {
        status: 'success',
      }
    },
  })

  const fieldComponent = (
    <Grid
      columns={{
        xs: 1,
        sm: 1,
        md: 2,
        lg: 2,
        xl: 2,
      }}
    >
      {fields.map((field) => {
        return (
          <Grid.Cell>
            <TextField
              {...languageFields[camelize(field.key)]}
              autoComplete="text"
              type="text"
              label={
                <LabelComponent
                  label={t(`TemplatesV3.${field.key}.label`)}
                  helpText={t(`TemplatesV3.${field.key}.helpText`)}
                />
              }
            />
          </Grid.Cell>
        )
      })}
    </Grid>
  )

  return (
    <Box padding={'400'}>
      <Form onSubmit={form.submit}>
        <BlockStack gap={'400'}>
          <Select
            {...languageFields.chooseLanguageField}
            label={
              <LabelComponent
                label={t(
                  'Templates.Configure.MultiLanguageTranslations.ChooseLanguage.label',
                )}
                helpText={t(
                  'Templates.Configure.MultiLanguageTranslations.ChooseLanguage.helpText',
                )}
              />
            }
            options={options}
          />
          {fieldComponent}
        </BlockStack>
        <Box padding={'200'} />
        <Button loading={form.submitting} variant="primary" size="large" submit>
          {t('Templates.Configure.MultiLanguageTranslations.buttonText')}
        </Button>
      </Form>
    </Box>
  )
}

function MultiLanguageTranslations({ config }) {
  if (config.version !== 3) {
    return <MultiLanguageTranslationsV1 config={config} />
  }
  return <MultiLanguageTranslationsV2 config={config} />
}

export default MultiLanguageTranslations
