import {
  BlockStack,
  Box,
  Card,
  FormLayout,
  Select,
  Text,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

import * as LAYOUTS from '../../../../constants/layouts'
import CustomModal from '../../../../components/CustomModal'
import { PAGE_TYPES } from '../../../../constants/page'

export default function LayoutConfiguration({ form, show, setShow }) {
  const { t } = useTranslation()

  const LAYOUT_OPTIONS = [
    {
      label: t(
        'Section.Edit.Sections.LayoutConfiguration.layouts.horizontalGrid',
      ),
      value: LAYOUTS.HORIZONTAL_GRID.value,
    },
    {
      label: t('Section.Edit.Sections.LayoutConfiguration.layouts.carousel'),
      value: LAYOUTS.CAROUSEL.value,
    },
    {
      label: t(
        'Section.Edit.Sections.LayoutConfiguration.layouts.amazonBoughtTogether',
      ),
      value: LAYOUTS.AMAZON_BOUGHT_TOGETHER.value,
    },
  ].filter(({ value }) => {
    if (
      value === LAYOUTS.AMAZON_BOUGHT_TOGETHER.value &&
      (form.fields.layout.value != LAYOUTS.AMAZON_BOUGHT_TOGETHER.value ||
        form.fields.page.value != PAGE_TYPES.product_page.value)
    )
      return false
    return true
  })

  const layouts = form.fields.extra.value?.layoutBreakpoints || {}

  const handleChange = (size, value) => {
    // Create a new object instead of mutating the existing one
    const newLayoutBreakpoints = {
      ...layouts,
      [size]: value,
    }

    // Update the entire extra object at once
    form.fields.extra.onChange({
      ...form.fields.extra.value,
      layoutBreakpoints: newLayoutBreakpoints,
    })
  }

  return (
    <CustomModal
      show={show}
      variant="medium"
      setShow={setShow}
      title={t('Section.Edit.Sections.LayoutConfiguration.title')}
    >
      <Box padding={'400'} background="bg-fill">
        <BlockStack gap={'200'}>
          <FormLayout>
            <Select
              label={t('Section.Edit.Sections.LayoutConfiguration.smallScreen')}
              options={
                layouts.small
                  ? LAYOUT_OPTIONS
                  : [
                      {
                        label: '-',
                        value: null,
                      },
                      ...LAYOUT_OPTIONS,
                    ]
              }
              value={layouts.small}
              onChange={(value) => handleChange('small', value)}
            />
            <Select
              label={t(
                'Section.Edit.Sections.LayoutConfiguration.mediumScreen',
              )}
              options={
                layouts.medium
                  ? LAYOUT_OPTIONS
                  : [
                      {
                        label: '-',
                        value: null,
                      },
                      ...LAYOUT_OPTIONS,
                    ]
              }
              value={layouts.medium}
              onChange={(value) => handleChange('medium', value)}
            />
            <Select
              label={t('Section.Edit.Sections.LayoutConfiguration.largeScreen')}
              options={
                layouts.large
                  ? LAYOUT_OPTIONS
                  : [
                      {
                        label: '-',
                        value: null,
                      },
                      ...LAYOUT_OPTIONS,
                    ]
              }
              onChange={(value) => handleChange('large', value)}
              value={layouts.large}
            />
          </FormLayout>
        </BlockStack>
      </Box>
    </CustomModal>
  )
}
