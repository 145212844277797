import {
  BlockStack,
  Box,
  Button,
  Icon,
  InlineStack,
  Text,
} from '@shopify/polaris'
import { StoreOnlineIcon } from '@shopify/polaris-icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import formSchema from '../../formSchema'
import { parseFormFields } from '../../utils'

export default function FormOptionsList({
  setActiveOption,
  activeOption,
  fields
}) {
  const { t } = useTranslation()
  return (
    <Box paddingBlockStart={'300'}>
      <Box paddingInlineStart={'400'}>
        <Text variant="headingSm">{t('visualEditorV3.settings.title')}</Text>
      </Box>
      <Box paddingBlockStart={'100'} paddingInline={'100'}>
        <BlockStack gap={'100'}>{renderFormOption(formSchema)}</BlockStack>
      </Box>
    </Box>
  )

  function renderFormOption(schema) {
    const parsed = parseFormFields(fields)
    const titles = schema.options.filter((option)=>{
      if(typeof option.show !== 'function') return true
      return option.show(parsed)
    }).map((option) => ({
      label: t(option.title.key),
      value: option.id,
    }))

    return titles.map((option, index) => (
      <OptionRow key={index} option={option} />
    ))

    function OptionRow({ option }) {
      const [isHovering, setIsHovering] = useState(false)
      return (
        <div
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onClick={() => setActiveOption(option.value)}
          style={{
            cursor: 'pointer',
          }}
        >
          <Box
            paddingInlineEnd={'200'}
            paddingBlock={'100'}
            borderRadius="100"
            background={
              isHovering || option.value == activeOption
                ? 'bg-fill-active'
                : 'bg-surface'
            }
            paddingInlineStart={'300'}
          >
            <InlineStack align="start" gap={'200'}>
              <Box>
                <Icon source={StoreOnlineIcon} />
              </Box>
              <Text>{option.label}</Text>
            </InlineStack>
          </Box>
        </div>
      )
    }
  }
}
