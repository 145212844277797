import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'


import { useDashboardDetails } from '../../hooks/useDashboardDetails'
import analytics, { MixPanelAnalytics } from '../../utils/analytics'

const TrackUserStore = () => {
  const { data: dashboardData, error, isLoading } = useDashboardDetails()

  useEffect(() => {
    if (isLoading || error || !dashboardData) return
    try {
      analytics.identifyUser({
        shopifyPlan: dashboardData.shop.shopifyPlan,
        gloodPlan: dashboardData.shopPlan.plan,
        gloodPrice: dashboardData.shopPlan.price,
        gloodCurrentServeUsage: dashboardData.billingCycle.currentUsage,
        gloodMaxServeUsage:
          dashboardData.billingCycle.maximumRecummendationsServed,
        shopifyMyDomain: shopify.config.shop,
        isStoreOwner: dashboardData.shopUser.accountOwner,
        isCollaborator: !dashboardData.shopUser.accountOwner,
        storeCreatedAt: dashboardData.shop.createdAt,
        storeCountry: dashboardData.shop.country,
        userCountry: dashboardData.shop.country,
        email: dashboardData.shopUser.email,
      })
    } catch (err) {
      console.error('Failed to identify user:', err)
    }
  }, [isLoading])

  return null
}

const TrackPerformanceMetrics = () => {
  const location = useLocation()

  useEffect(() => {
    const performanceObserver = new PerformanceObserver((entryList) => {
      const entries = entryList.getEntries()

      entries.forEach((entry) => {
        // Log TTFB (Time to First Byte)
        if (entry.entryType === 'navigation') {
          const ttfb = entry.responseStart - entry.requestStart
          analytics.logPerformanceMetrics({
            metric: 'TTFB',
            value: ttfb
          })
        }

        // Log FCP (First Contentful Paint)
        if (entry.entryType === 'paint' && entry.name === 'first-contentful-paint') {
          analytics.logPerformanceMetrics({
            metric: 'FCP',
            value: entry.startTime,
          })
        }

        // Log LCP (Largest Contentful Paint)
        if (entry.entryType === 'largest-contentful-paint') {
          analytics.logPerformanceMetrics({
            metric: 'LCP',
            value: entry.startTime,
          })
        }

        // Log CLS (Cumulative Layout Shift)
        if (entry.entryType === 'layout-shift' && !entry.hadRecentInput) {
          analytics.logPerformanceMetrics({
            metric: 'CLS',
            value: entry.value,
          })
        }
      })
    })

    // Observe multiple types of performance entries
    performanceObserver.observe({
      type: 'paint',
      buffered: true,
    })

    performanceObserver.observe({
      type: 'largest-contentful-paint',
      buffered: true,
    })

    performanceObserver.observe({
      type: 'layout-shift',
      buffered: true,
    })

    const navigationEntries = performance.getEntriesByType('navigation')
    if (navigationEntries.length > 0) {
      const ttfb = navigationEntries[0].responseStart - navigationEntries[0].requestStart
      analytics.logPerformanceMetrics({
        metric: 'TTFB',
        value: ttfb,
      })
    }

    // Tracking page view event
    analytics.trackPageEvent(MixPanelAnalytics.Actions.VIEW)

    return () => performanceObserver.disconnect()
  }, [location])

  return null
}

export default TrackPerformanceMetrics


export { TrackUserStore, TrackPerformanceMetrics }
