import React from 'react'
import {
  Card,
  Layout,
  Text,
  BlockStack,
  ChoiceList,
  TextField,
  Tag,
  Button,
  InlineStack,
  Form,
  Box,
  Banner,
  Divider,
  Badge,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { useField, useForm } from '@shopify/react-form'
import { updateShopConfig } from '../../../apis/dashbboard'
import { LockIcon } from '@shopify/polaris-icons'
import { PricingModal, usePricing } from '../../../components/PricingModal'
import { areFeaturesEnabled } from '../../../utils/features'
import { FEATURES } from '../../../constants/features'

const ENRICHMENT_TYPES = [
  { label: 'Product Embedding', value: 'product_embedding' },
  { label: 'Bought Together Embedding', value: 'bought_together_embedding' },
]

function EnrichmentSection({ config, loading }) {
  const { t } = useTranslation()
  const [newTag, setNewTag] = React.useState('')
  const [attributeInput, setAttributeInput] = React.useState('')
  const pricing = usePricing()

  const configData = config.storeData || {}
  const enrichmentConfig = configData.enrichments || {}
  const genderTags = configData.gender_tags || []

  // Convert ENRICHMENT_TYPES labels to use translations
  const translatedEnrichmentTypes = ENRICHMENT_TYPES.map(type => ({
    ...type,
    label: t(`SettingsPage.Sections.Enrichment.Types.${type.value}`),
  }))

  const isEnrichmentEnabled = areFeaturesEnabled([FEATURES.AI_PRODUCT_ENRICHMENT], window.shopify.data)

  const { fields: enrichmentStatusFields } = useForm({
    fields: {
      isEnabled: useField({
        value: enrichmentConfig.product || false,
        validates: [],
      }),
    },
  })

  const { fields, submit, submitErrors, dirty } = useForm({
    fields: {
      types: useField({
        value: enrichmentConfig.types || [],
        validates: [],
      }),
      attributes: useField({
        value: enrichmentConfig.attributes || [],
        validates: [],
      }),
      genderTags: useField({
        value: genderTags || [],
        validates: [],
      }),
    },
    onSubmit: async (formData) => {
      const currentData = config.data || {}
      const newData = {
        ...currentData,
        enrichments: {
          ...currentData.enrichments,
          product: enrichmentStatusFields.isEnabled.value,
          types: formData.types,
          attributes: formData.attributes,
        },
        gender_tags: formData.genderTags,
      }
      const res = await updateShopConfig({
        data: newData,
      })

      if (res.data) {
        shopify.toast.show(
          t('DefaultText.updateSuccess', {
            text: t('DefaultText.settings'),
          }),
        )
        return { status: 'success' }
      } else {
        console.log('Error updating shop config in EnrichmentSection', res.error)
        return {
          status: 'fail',
          errors: [{ message: t('SettingsPage.Sections.Enrichment.Errors.updateFailed') }]
        }
      }
    },
  })

  const handleAddAttribute = () => {
    if (attributeInput && !fields.attributes.value.includes(attributeInput)) {
      fields.attributes.onChange([...fields.attributes.value, attributeInput])
      setAttributeInput('')
    }
  }

  const handleAddGenderTag = () => {
    if (newTag && !fields.genderTags.value.includes(newTag)) {
      fields.genderTags.onChange([...fields.genderTags.value, newTag])
      setNewTag('')
    }
  }

  const handleEnrichmentToggle = async () => {
    if (!isEnrichmentEnabled) {
      pricing.open({
        features: [FEATURES.AI_PRODUCT_ENRICHMENT],
      })
      return
    }

    const newEnabledState = !enrichmentStatusFields.isEnabled.value
    enrichmentStatusFields.isEnabled.onChange(newEnabledState)

    const currentData = config.data || {}
    const newData = {
      ...currentData,
      enrichments: {
        ...currentData.enrichments,
        product: newEnabledState,
        types: fields.types.value,
        attributes: fields.attributes.value,
      },
      gender_tags: fields.genderTags.value,
    }

    const res = await updateShopConfig({
      data: newData,
    })

    if (res.data) {
      shopify.toast.show(
        t('DefaultText.updateSuccess', {
          text: t('DefaultText.settings'),
        }),
      )
    } else {
      // Revert the toggle if update fails
      enrichmentStatusFields.isEnabled.onChange(!newEnabledState)
      shopify.toast.show(t('SettingsPage.Sections.Enrichment.Errors.updateFailed'), {
        isError: true,
      })
    }
  }

  React.useEffect(() => {
    if (submitErrors.length) {
      shopify.toast.show(submitErrors[0].message, {
        isError: true,
      })
    }
  }, [submitErrors])

  return (
    <Layout.AnnotatedSection
      title={t('SettingsPage.Sections.Enrichment.title')}
      description={t('SettingsPage.Sections.Enrichment.description')}
    >
      <Card padding={'0'}>
        <BlockStack>
          <Form>
            <Box padding="400">
              <InlineStack gap="1200" align="space-between" blockAlign="start" wrap={false}>
                <InlineStack gap="200" wrap={false}>
                  <InlineStack gap="200" align="start" blockAlign="baseline">
                    <Text variant="headingMd" as="h6">
                      {t('SettingsPage.Sections.Enrichment.enableTitle')}
                    </Text>
                    <Badge tone={enrichmentStatusFields.isEnabled.value ? 'success' : undefined}>
                      {enrichmentStatusFields.isEnabled.value ? t('DefaultText.on') : t('DefaultText.off')}
                    </Badge>
                  </InlineStack>
                </InlineStack>
                <Box minWidth="fit-content">
                  <InlineStack align="end">
                    <Button
                      role="switch"
                      ariaChecked={enrichmentStatusFields.isEnabled.value ? 'true' : 'false'}
                      onClick={handleEnrichmentToggle}
                      size="slim"
                      disabled={loading}
                      icon={!isEnrichmentEnabled ? LockIcon : undefined}
                    >
                      {enrichmentStatusFields.isEnabled.value ? t('DefaultText.turnOff') : t('DefaultText.turnOn')}
                    </Button>
                  </InlineStack>
                </Box>
              </InlineStack>
              <Box paddingBlockStart="200">
                <Text variant="bodyMd" as="p" tone="subdued">
                  {t('SettingsPage.Sections.Enrichment.enableHelpText')}
                </Text>
              </Box>
            </Box>
          </Form>

          <Form onSubmit={submit}>
            <BlockStack gap="0">
              <Divider />
              <Box padding="400">
                <Text as="strong">
                  {t('SettingsPage.Sections.Enrichment.typesTitle')}
                </Text>
                <BlockStack gap="200">
                  <ChoiceList
                    disabled={loading || !enrichmentStatusFields.isEnabled.value || !isEnrichmentEnabled}
                    allowMultiple
                    choices={translatedEnrichmentTypes}
                    selected={fields.types.value}
                    onChange={fields.types.onChange}
                  />
                </BlockStack>
              </Box>
              <Divider />
              <Box padding="400">
                <BlockStack gap="200">
                  <Text as="strong" paddingBlockEnd="200">
                    {t('SettingsPage.Sections.Enrichment.Attributes.title')}
                  </Text>
                  <InlineStack gap="200" align="start">
                    <Box minWidth="150px" maxWidth="300px">
                      <TextField
                        label={t('SettingsPage.Sections.Enrichment.Attributes.addLabel')}
                        labelHidden
                        disabled={loading || !enrichmentStatusFields.isEnabled.value || !isEnrichmentEnabled}
                        value={attributeInput}
                        onChange={setAttributeInput}
                        placeholder={t('SettingsPage.Sections.Enrichment.Attributes.addPlaceholder')}
                        autoComplete="off"
                      />
                    </Box>
                    <Button onClick={handleAddAttribute} disabled={loading || !enrichmentStatusFields.isEnabled.value || !isEnrichmentEnabled}>
                      {t('DefaultText.add')}
                    </Button>
                  </InlineStack>
                  {fields.attributes.value.length > 0 && (
                    <Box>
                      <InlineStack gap="200" wrap>
                        {fields.attributes.value.map((attribute) => (
                          <Tag
                            key={attribute}
                            onRemove={(!enrichmentStatusFields.isEnabled.value || !isEnrichmentEnabled) ? undefined : () => fields.attributes.onChange(
                              fields.attributes.value.filter((a) => a !== attribute)
                            )}
                          >
                            {attribute}
                          </Tag>
                        ))}
                      </InlineStack>
                    </Box>
                  )}
                </BlockStack>
              </Box>
              <Divider />
              <Box padding="400">
                <BlockStack gap="200">
                  <Text as="strong" paddingBlockEnd="200">
                    {t('SettingsPage.Sections.Enrichment.GenderTags.title')}
                  </Text>
                  <InlineStack gap="200" align="start">
                    <Box minWidth="150px" maxWidth="300px">
                      <TextField
                        label={t('SettingsPage.Sections.Enrichment.GenderTags.addLabel')}
                        labelHidden
                        disabled={loading}
                        value={newTag}
                        onChange={setNewTag}
                        placeholder={t('SettingsPage.Sections.Enrichment.GenderTags.addPlaceholder')}
                        autoComplete="off"
                      />
                    </Box>
                    <Button onClick={handleAddGenderTag} disabled={loading}>
                      {t('DefaultText.add')}
                    </Button>
                  </InlineStack>
                  {fields.genderTags.value.length > 0 && (
                    <Box>
                      <InlineStack gap="200" wrap>
                        {fields.genderTags.value.map((tag) => (
                          <Tag
                            key={tag}
                            onRemove={() => fields.genderTags.onChange(
                              fields.genderTags.value.filter((t) => t !== tag)
                            )}
                          >
                            {tag}
                          </Tag>
                        ))}
                      </InlineStack>
                    </Box>
                  )}
                </BlockStack>
              </Box>
              <Divider />
              <Box padding="200" paddingInlineEnd="400">
                <InlineStack align="end">
                  <Button
                    primary
                    submit
                    disabled={!dirty || loading}
                  >
                    {t('DefaultText.save')}
                  </Button>
                </InlineStack>
              </Box>
            </BlockStack>
          </Form>

          {enrichmentStatusFields.isEnabled.value && fields.types.value.length === 0 && (
            <Banner
              title={t('SettingsPage.Sections.Enrichment.Warnings.noTypesSelected')}
              status="warning"
            >
              <p>{t('SettingsPage.Sections.Enrichment.Warnings.noTypesSelectedDescription')}</p>
            </Banner>
          )}
        </BlockStack>
      </Card>
      <PricingModal modal={pricing} />
    </Layout.AnnotatedSection>
  )
}

export default EnrichmentSection