export const FEATURES = Object.freeze({
    DISCOUNTS: 'discounts',
    TRANSLATIONS: 'translations',
    EMAIL_RECOMMENDATIONS: 'emails',
    CUSTOM_TEMPLATES: 'custom_templates',
    SEGMENTS: 'segments',
    GEO_TARGETING: 'geo_targeting',
    RECOMMENDATION_RULES: 'recommendation_rules',
    LIVE_SUPPORT: 'live_support',
    BASIC_RECOMMENDATIONS: 'basic_rec',
    ADVANCED_RECOMMENDATIONS: 'advanced_reco',
    CUSTOM_CSS: 'custom_css',
    SEARCH: 'search',
    PRODUCT_DISCOUNTS: 'product_discounts',
    ZIPCODE_TARGETTING: 'zipcode_targetting',
    AB_EXPERIENCE: 'ab_experience',
    BUNDLES: 'bundles',
    FUNNELS: 'funnels',
    GLOOD_PROXY: 'glood_proxy',
    CHECKOUT_SECTIONS: 'checkout_sections',
    POST_PURCHASE: 'post_checkout',
    ACCOUNT_PAGE_PERSOLISATION: 'account_page_recommendations',
    AI_PRODUCT_ENRICHMENT: 'ai_product_enrichment'
})