import {
  Banner,
  BlockStack,
  Box,
  Card,
  InlineGrid,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Text,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import useBundlesPermissible from '../hooks/useBundlesPermissible'
import { useDashboardDetails } from '../../../hooks/useDashboardDetails'
import {
  BUNDLE_SETTING_TYPE,
  BUNDLE_STATUS,
  BUNDLE_TYPE,
} from '../../../constants/bundles'
import { createBundle } from '../../../apis/bundles'
import { fetchProductTypes, fetchPublications } from '../../../apis/shopify'
import BundlesUpsertForm from '../components/BundlesUpsertForm'
import NotFound from '../../../pages/NotFound'
import { calculatePrice } from '../../../utils/bundles'
import analytics, { MixPanelAnalytics } from '../../../utils/analytics'

export default function CreateBundle() {
  const { allowed, loading, error } = useBundlesPermissible()
  const { data: dashbaordData, isLoading: dashboardLoading } =
    useDashboardDetails()

  const productTypesQuery = useQuery({
    queryKey: ['productTypes'],
    queryFn: fetchProductTypes,
  })
  const publicationsQuery = useQuery({
    queryKey: ['publications'],
    queryFn: fetchPublications,
  })

  const { t } = useTranslation()
  const { bundleType } = useParams()

  if (!Object.values(BUNDLE_TYPE).includes(bundleType)) {
    return <NotFound />
  }

  if (
    loading ||
    dashboardLoading ||
    productTypesQuery.isLoading ||
    publicationsQuery.isLoading
  ) {
    return <Skeleton />
  }

  if (error) {
    return (
      <Page>
        <Card>
          <InlineGrid columns={'1fr auto 1fr'}>
            <Text>{error}</Text>
          </InlineGrid>
        </Card>
      </Page>
    )
  }

  if (!allowed) {
    return (
      <Page>
        <Card>
          <Banner title={t('Bundles.notAllowed')} tone="critical" />
        </Card>
      </Page>
    )
  }

  const currentValues = {
    title: '',
    description: '',
    products: [],
    productType: '',
    tags: [],
    collections: [],
    variantDiscount: {},
    bundleDiscount: 0,
    discountType: 'noDiscount',
    configurationType: BUNDLE_SETTING_TYPE.CUSTOM,
    bundles: [],
    options: [],
    id: null,
    template: '',
    bundleType: bundleType,
    status: BUNDLE_STATUS.DRAFT,
    publicationIds: [],
  }

  return (
    <Page
      title={t('Bundles.Details.title')}
      subtitle={t('Bundles.Details.description')}
      backAction={{
        url: '/bundles',
      }}
    >
      <BundlesUpsertForm
        currentValues={currentValues}
        dashboardDetails={dashbaordData}
        productTypes={productTypesQuery.data}
        publications={publicationsQuery.data.filter(
          (pub) => pub.name == 'Online Store',
        )}
        onSubmit={createBundleHandler}
        bundleType={bundleType}
      />
      <Box padding={'400'} />
    </Page>
  )
}

function Skeleton() {
  const { t } = useTranslation()
  return (
    <SkeletonPage title={t('Bundles.Create.title')}>
      <Card>
        <BlockStack gap={'300'}>
          <SkeletonDisplayText />
          <SkeletonBodyText lines={3} />
        </BlockStack>
      </Card>
    </SkeletonPage>
  )
}

const createBundleHandler = async (value) => {
  let payload = {}
  switch (value.configurationType) {
    case BUNDLE_SETTING_TYPE.CUSTOM:
      payload = {
        name: value.title,
        productIds: value.products.map((product) =>
          parseInt(product.id.replace('gid://shopify/Product/', '')),
        ),
        options: value.products.map((product) => ({
          name: product?.title,
          values: product.variants
            .map((variant) =>
              variant.selectedOptions.map(({ value }) => value).join(' / '),
            )
            .flat(),
        })),
        variants: value.bundleVariants.map((bundleVariant) => ({
          ...bundleVariant,
          productVariants: bundleVariant.productVariants.map(
            (productVariant) => ({
              ...productVariant,
              price: calculatePrice(
                productVariant.price,
                value.variantDiscount,
                value.discountType,
                bundleVariant.name,
                productVariant.id,
                value.bundleDiscount,
              ),
            }),
          ),
        })),
        bundleConfigurationType: value.configurationType,
        template: parseInt(value.shopTemplate),
        images: value.images,
        tags: value.tags,
        collections: value.collections.map((collection) =>
          parseInt(collection.id.replace('gid://shopify/Collection/', '')),
        ),
        productType: value.productType,
        status: value.status,
        bundleType: value.bundleType,
        publicationIds: value.publications,
        discountConfig: {
          discountType: value.discountType,
          bundleDiscount: parseInt(value.bundleDiscount),
          variantDiscount: value.variantDiscount,
        }
      }
      break
    case BUNDLE_SETTING_TYPE.PRODUCT:
    // complete tjhis later
    case BUNDLE_SETTING_TYPE.UNIQUE:
    // complete this later
  }
  analytics.trackBundleEvent(MixPanelAnalytics.Actions.CREATE_INITIATED, payload)
  const res = await createBundle(payload)
  analytics.trackBundleEvent(MixPanelAnalytics.Actions.CREATE_COMPLETED,{
    ...payload,
    id: res.data.productId
  })
  return res
}
