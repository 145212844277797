import { useContextualSaveBar } from '@shopify/app-bridge-react'
import {
  ActionList,
  Badge,
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  EmptyState,
  InlineGrid,
  InlineStack,
  Layout,
  Link,
  Page,
  PageActions,
  Popover,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  Text,
  useBreakpoints,
} from '@shopify/polaris'
import humps from 'humps'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import merge from 'lodash.merge'

import {
  deleteAutoRecommendation,
  getAutoRecommendations,
  upsertAutoRecommendations,
} from '../../../apis/recommendation'
import { deleteSection, updateSection } from '../../../apis/section'
import { fetchProduct } from '../../../apis/shopify'
import { getTemplates } from '../../../apis/template'
import { PricingModal, usePricing } from '../../../components/PricingModal'
import {
  BOUGHT_TOGETHER,
  CROSS_SELL,
  SIMILAR_PRODUCTS,
} from '../../../constants/widgets'
import { useRedirect } from '../../../hooks'
import { useDashboardDetails } from '../../../hooks/useDashboardDetails'
import { useShopDetails } from '../../../hooks/useShopDetails'
import { removeKeys } from '../../../utils/object'
import queryClient from '../../../utils/query'
import { getQueryParams, updateURLSearch } from '../../../utils/router'
import { shouldDiscountConfigRender } from '../../../utils/sections'
import { useEditSection } from './hooks'
import {
  AdvanceConfig,
  Details,
  Recommendation,
  SectionDiscountConfig,
  Segmentations,
  SelectTemplate,
  Status,
  StatusCheck,
} from './WidgetSections'
import AboutSection from './WidgetSections/aboutSection'
import AnalyticsSection from './WidgetSections/analytics'
import LocationSection from './WidgetSections/locationAndPosition'
import analytics, { MixPanelAnalytics } from '../../../utils/analytics'

export default function EditSection() {
  const saveBar = useContextualSaveBar()
  const breakpoints = useBreakpoints()
  const [token, setToken] = useState(null)
  const { t } = useTranslation()
  const { openVisualEditor = false, showSection } = getQueryParams()
  const { redirectToLink } = useRedirect()
  const { sectionId = null } = useParams()
  const [loading, setLoading] = useState(false)
  const { data = {}, isLoading, error } = useShopDetails()
  const sections = data?.sections ?? []
  const section = sections.find((s) => s.id.toString() === sectionId) || {}
  const experience =
    (data?.experiences ?? []).find((exp) => exp.id === section?.experience) ??
    {}
  const page = data?.pages?.find((p) => p.id === section?.page) ?? {}

  const [expandedActions, setExpandedActions] = useState(false)
  const togglePopoverActive = useCallback(
    () => setExpandedActions((expandedActions) => !expandedActions),
    [],
  )
  const [deleting, setDeleting] = useState(false)
  const [clearingCache, setClearingCache] = useState(false)

  const [isPlanLimitReached, setIsPlanLimitReached] = useState(false)
  const [isSectionDowngraded, setIsSectionDowngraded] = useState(false)

  const isError = error !== null
  const pricing = usePricing()
  const { data: dashboardDetails, isLoading: dashboardLoading } =
    useDashboardDetails()
  const { billingCycle, shop } = dashboardDetails || {}

  const productsQuery = useQuery({
    queryKey: [
      'products',
      sectionId,
      section?.disabledProducts,
      section?.excludedProducts,
      section?.whitelistedProducts,
    ],
    queryFn: async () => {
      const disabledProducts = await Promise.all(
        (section?.disabledProducts ?? []).map(async (id) => {
          const { data: product } = await fetchProduct(id)
          return {
            ...product,
            id: 'gid://shopify/Product/' + product.id,
          }
        }),
      )
      const excludedProducts = await Promise.all(
        (section?.excludedProducts ?? []).map(async (id) => {
          const { data: product } = await fetchProduct(id)
          return {
            ...product,
            id: 'gid://shopify/Product/' + product.id,
          }
        }),
      )
      const whitelistedProducts = await Promise.all(
        (section?.whitelistedProducts ?? []).map(async (id) => {
          const { data: product } = await fetchProduct(id)
          return {
            ...product,
            id: 'gid://shopify/Product/' + product.id,
          }
        }),
      )
      return {
        disabledProducts,
        excludedProducts,
        whitelistedProducts,
      }
    },
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    enabled: Boolean(section?.id),
  })
  const products = productsQuery.data ?? {}
  const autoRecQuery = useQuery({
    queryKey: ['autoRecommendations', sectionId],
    queryFn: async () => {
      const { data, error } = await getAutoRecommendations({
        recommendationType: 'promoted',
        objectType: 'page',
        section: sectionId,
      })

      if (error) {
        return Promise.reject(error)
      }

      // populate products
      const objects = data.recommendations
      const populatedObjects = await Promise.all(
        objects.map(async (obj) => {
          const {
            data: { recommendations },
          } = obj
          const products = await Promise.all(
            recommendations.map(
              async ({ value }) => (await fetchProduct(value)).data,
            ),
          )
          return {
            products,
            id: obj.id,
            url: obj.objectId,
          }
        }),
      )
      return populatedObjects
    },
  })

  const templatesQuery = useQuery({
    queryKey: ['templates'],
    queryFn: async () => {
      setLoading(true)
      try {
        const res = await getTemplates()
        if (res.error) {
          return Promise.reject(res.error)
        }
        return res.data
      } finally {
        setLoading(false)
      }
    },
    refetchOnWindowFocus: false,
  })

  const currentValues = useMemo(() => {
    return {
      name: section?.title ?? '',
      enabled: section?.enabled ?? false,
      experience: experience?.name ?? '',
      page: page.type ?? '',
      widgetType: section?.type ?? 'bought_together',
      layout: section?.layout ?? '',
      excludedProducts: products.excludedProducts ?? [],
      disabledProducts: products.disabledProducts ?? [],
      whitelistedProducts: products.whitelistedProducts ?? [],
      disabledTags: section?.disabledTags ?? [],
      excludedTags: section?.excludedTags ?? [],
      whitelistedTags: section?.whitelistedTags ?? [],
      enableBundleDiscounts: section?.discountConfig?.enabled ?? false,
      discountType: section?.discountConfig?.type || 'PERCENTAGE',
      discountValue: section?.discountConfig?.value ?? 0,
      segmentation: section?.segment ?? '',
      sectionTitle: section?.title ?? '',
      showSoldOutItems: section?.allowOutOfStock ?? false,
      enableCartProps: section?.addCartProperties ?? false,
      pricePercentageThreshold:
        section?.type === BOUGHT_TOGETHER.value
          ? section?.pricePercentageThreshold ?? 0
          : null,
      productRankingCriteria: section?.rankingCriteria || 'null',
      productFilterCriteria: section?.filterCriteria ?? '',
      fallbackCriteria: section?.fallbackCriteria ?? 'null',
      minPrice: parseInt(section?.minPrice ?? '0'),
      maxPrice: parseInt(section?.maxPrice ?? '0'),
      automaticEnabled: section?.automaticEnabled ?? false,
      enableRandom: section?.enableRandom ?? false,
      allowIfUnavailable: section?.allowIfUnavailable ?? false,
      template: section?.template ?? null,
      enabledUrls: section?.enabledUrls ?? [],
      location: section?.location ?? 0,
      position: section?.position ?? '',
      collections: section?.collections ?? [],
      slots: section?.extra?.slots ?? [],
      objects: autoRecQuery.data ?? [], // add objects here
      translations: section?.translations ?? {
        primary_locale: {
          title: section?.title ?? '',
        },
      },
      enableCartButton: section?.enableCartButton ?? false,
      applyDiscountOnlyToRec:
        section?.discountConfig?.applyDiscountOnlyToRec ?? false,
      discountMessageObject: serialiseTranslationObject(
        removeKeys(
          humps.decamelizeKeys(section?.discountConfig?.message ?? {}),
          ['primary_locale'],
        ),
      ), // decamelized discount message object
      discountMessage:
        section?.discountConfig?.message?.primaryLocale ??
        'Bundle discount for {product_title}',
      discountSubtitle:
        typeof section?.discountConfig?.widgetMessage === 'string'
          ? section?.discountConfig?.widgetMessage
          : section?.discountConfig?.widgetMessage?.primaryLocale ?? '',
      discountSubtitleTranslated:
        typeof section?.discountConfig?.widgetMessage === 'string'
          ? {}
          : serialiseTranslationObject(
              removeKeys(
                humps.decamelizeKeys(
                  section?.discountConfig?.widgetMessage ?? {},
                ),
                ['primary_locale'],
              ),
            ), // since we already have primary_locale in discountSubtitle
      discountVersion: section?.discountConfig?.version ?? 1,
      extra: section?.extra || {},
      templateV3: section?.templateV3 ?? null,
    }
  }, [section, experience, page, products, autoRecQuery.data])

  const saveHandler = async (data) => {
    setLoading(true)
    const {
      name,
      layout,
      widgetType,
      template,
      disabledProducts,
      excludedProducts,
      disabledTags,
      excludedTags,
      enableBundleDiscounts: { checked: enableBundleDiscounts } = {},
      segmentation,
      showSoldOutItems: { checked: showSoldOutItems },
      enableCartProps: { checked: enableCartProps },
      productRankingCriteria,
      fallbackCriteria,
      minPrice,
      maxPrice,
      allowIfUnavailable: { checked: allowIfUnavailable },
      enabledUrls,
      location,
      position,
      whitelistedProducts,
      whitelistedTags,
      collections,
      slots,
      translations,
      enabled,
      templateV3,
      enableCartButton: { checked: enableCartButton } = {},
    } = data

    const ok = await upsertAutoRec({
      prevValue: currentValues.objects,
      currentValue: data.objects,
      sectionId,
    })

    if (!ok) {
      shopify.toast.show('Some Error Occured', {
        isError: true,
      })
      setLoading(false)
      return {
        status: 'error',
        message: res.error,
      }
    }

    const payload = {
      title: name,
      layout,
      collections,
      disabledProducts: disabledProducts.map((p) =>
        parseInt(p.id.replace('gid://shopify/Product/', '')),
      ),
      excludedProducts: excludedProducts.map((p) =>
        parseInt(p.id.replace('gid://shopify/Product/', '')),
      ),
      whitelistedProducts: whitelistedProducts.map((p) =>
        parseInt(p.id.replace('gid://shopify/Product/', '')),
      ),
      disabledTags,
      excludedTags,
      whitelistedTags,
      segment: segmentation,
      rankingCriteria: productRankingCriteria,
      fallbackCriteria,
      minPrice,
      maxPrice,
      allowOutOfStock: showSoldOutItems,
      allowIfUnavailable,
      enabledUrls,
      template,
      templateV3,
      location,
      position,
      extra: {
        slots,
        ...data.extra,
      },
      translations,
      enabled: enabled,
      enableCartButton: enableCartButton,
      addCartProperties: enableCartProps,
    }

    payload.fallbackCriteria =
      payload.fallbackCriteria == 'null' ? null : payload.fallbackCriteria
    payload.rankingCriteria =
      payload.rankingCriteria == 'null' ? null : payload.rankingCriteria

    // for discount config
    payload.discountConfig = {
      enabled: enableBundleDiscounts,
      value: data.discountValue,
      widget_message: deserialiseTranslationObject({
        primary_locale: {
          title: data.discountSubtitle,
        },
        ...data.discountSubtitleTranslated,
      }),
      type:
        typeof data.discountType === 'string'
          ? data.discountType
          : 'PERCENTAGE',
      version: 2,
      apply_discount_only_to_rec: data.applyDiscountOnlyToRec?.checked ?? false,
      message: deserialiseTranslationObject({
        ...(data.discountMessageObject ?? {}),
        primary_locale: {
          title: data.discountMessage,
        },
      }),
    }

    payload.enableRandom = data?.enableRandom?.checked
    if (
      widgetType === CROSS_SELL.value ||
      widgetType === BOUGHT_TOGETHER.value ||
      widgetType === SIMILAR_PRODUCTS.value
    ) {
      payload.automaticEnabled = data.automaticEnabled.checked
    }

    if (widgetType === BOUGHT_TOGETHER.value) {
      payload.pricePercentageThreshold = data.pricePercentageThreshold
    }

    if (widgetType === SIMILAR_PRODUCTS.value) {
      payload.filterCriteria = data.filterCriteria
    }
    payload.extra = merge(currentValues.extra, payload.extra)
    const res = await updateSection(sectionId, payload) // merging with section to keep the old values
    setLoading(false)
    saveBar.hide()
    if (res.error) {
      shopify.toast.show(
        res.error?.error?.[0]?.message ?? 'Some Error Occured',
        {
          isError: true,
        },
      )
      return {
        status: 'error',
        message: res.error,
      }
    }
    analytics.trackSectionEvent(
      MixPanelAnalytics.Actions.UPDATE,
      res.data.section,
      page.type,
    )
    if (res.data.section.enabled != currentValues.enabled) {
      if (res.data.section.enabled) {
        analytics.trackSectionEvent(
          MixPanelAnalytics.Actions.ENABLE,
          res.data.section,
          page.type,
        )
      } else {
        analytics.trackSectionEvent(
          MixPanelAnalytics.Actions.DISABLE,
          res.data.section,
          page.type,
        )
      }
    }
    await queryClient.invalidateQueries(['shopDetails'])
    await queryClient.invalidateQueries(['products', sectionId])
    await queryClient.invalidateQueries(['autoRecommendations', sectionId])
    shopify.toast.show(
      t('DefaultText.updateSuccess', {
        text: '"' + payload.title + '"',
      }),
    )
    return {
      status: 'success',
    }
  }

  const form = useEditSection({
    onSubmit: saveHandler,
    currentValues,
  })
  const parsedFormValues = getParsedValuesFromForm(form)
  const openVisualEditorHandler = async () => {
    const token = await shopify.idToken()
    setToken(token)
    setTimeout(() => {
      document.getElementById('visual-editor-modal')?.show()
    }, 300)
  }

  const isTemplateEnabled = Boolean(dashboardDetails?.config?.templatesEnabled)

  const handleStatusChange = (newStatus) => {
    form.fields.enabled.onChange(newStatus)
  }

  useEffect(() => {
    if (form.submitErrors.length > 0) {
      shopify.toast.show(form.submitErrors[0].message, {
        isError: true,
        duration: 5000, // 5 seconds
      })
    }
  }, [form.submitErrors])

  useEffect(() => {
    const timout = setTimeout(() => {
      const scrollToElem = document.querySelector(`#${showSection}`) || null
      if (!scrollToElem) return
      scrollToElem?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }, 10)
    return () => clearTimeout(timout)
  }, [])

  useEffect(() => {
    if (!isLoading && !dashboardLoading) {
      const isFormDirty = !compareValues(parsedFormValues, currentValues)
      if (isFormDirty) {
        saveBar.show()
      } else {
        saveBar.hide()
      }
    }
  }, [dashboardLoading, billingCycle, isLoading, parsedFormValues])

  useEffect(() => {
    if (openVisualEditor && !isLoading && !dashboardLoading) {
      setTimeout(() => {
        openVisualEditorHandler()
        updateURLSearch({ openVisualEditor: false })
      }, 100)
    }
  }, [openVisualEditor, isLoading, billingCycle, dashboardLoading])

  useEffect(() => {
    function handleEvent(ev) {
      if (ev?.data === 'GAI-close-visual-editor-modal') {
        document.getElementById('visual-editor-modal')?.hide()
      } else if (ev?.data === 'GLOODAI:V3EDITOR:CLOSE') {
        document.getElementById('visual-editor-modal-v3')?.hide()
      }
    }
    window.addEventListener('message', handleEvent)
    saveBar.saveAction.setOptions({
      onAction: async () => {
        try {
          await form.submit()
        } catch (e) {
          console.error('edit error saving section error', e)
        }
      },
      loading: loading || form.submitting,
    })
    saveBar.discardAction.setOptions({
      onAction: () => {
        form.reset()
      },
      disabled: false,
      loading: false,
      discardConfirmationModal: true,
    })

    return () => {
      window.removeEventListener('message', handleEvent)
    }
  }, [form.submitting])

  useEffect(() => {
    if (dashboardDetails && section && page) {
      // Check recommendation serves limit
      setIsPlanLimitReached(
        dashboardDetails?.billingCycle?.currentUsage >=
          dashboardDetails?.shopPlan?.recommendationsServedHardLimit,
      )

      // Check if section type and page are available in current plan
      const isTypeAvailable = dashboardDetails.shopPlan?.pagesEnabled?.includes(
        page.type,
      )
      const isRecommendationTypeAvailable =
        dashboardDetails.shopPlan?.recommendationsEnabled?.includes(
          section.type,
        )
      setIsSectionDowngraded(
        !(isTypeAvailable && isRecommendationTypeAvailable),
      )
    }
  }, [dashboardDetails, section])

  if (
    isLoading ||
    productsQuery.isLoading ||
    autoRecQuery.isLoading ||
    dashboardLoading ||
    billingCycle === undefined
  ) {
    return <SkeletonComponent />
  }

  if (!section.id || isError) {
    return (
      <Page
        backAction={{
          url: '/',
        }}
      >
        <Card>
          <EmptyState
            image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
            heading={t('Section.NotFound.heading')}
          >
            <p>{t('Section.NotFound.description')}</p>
          </EmptyState>
        </Card>
      </Page>
    )
  }

  return (
    <>
      <Page
        backAction={{
          url: '/sections',
        }}
        primaryAction={
          <Popover
            active={expandedActions}
            activator={
              <Button
                onClick={togglePopoverActive}
                disclosure={expandedActions ? 'up' : 'down'}
              >
                {!expandedActions ? 'More Actions' : 'Less Actions'}
              </Button>
            }
            autofocusTarget="first-node"
            onClose={togglePopoverActive}
          >
            <Box width="200">
              <ActionList
                actionRole="menuitem"
                items={[
                  {
                    content: 'Clear Cache',
                    disabled: clearingCache,
                    onAction: async () => {
                      setClearingCache(true)
                      const res = await updateSection(sectionId, {})
                      setClearingCache(false)
                      if (res.error) {
                        shopify.toast.show(
                          t('Section.Details.clearingCacheError'),
                          {
                            isError: true,
                          },
                        )
                      } else {
                        await queryClient.invalidateQueries(['shopDetails'])
                        shopify.toast.show(
                          t('Section.Details.clearingCacheSuccess'),
                        )
                      }
                      togglePopoverActive()
                    },
                  },
                  {
                    content: 'Delete',
                    destructive: true,
                    onAction: () => {
                      toggleDeleteModal('open')
                    },
                  },
                ]}
              />
            </Box>
          </Popover>
        }
        title={currentValues.name}
        titleMetadata={
          <InlineStack blockAlign="center" gap={'200'}>
            <Text>ID: {sectionId}</Text>
            {currentValues.enabled ? (
              <Badge size="large" progress="complete" tone="success">
                {t('Section.List.enums.sectionStatus.enabled')}
              </Badge>
            ) : (
              <Badge size="large" progress="complete" tone="critical">
                {t('Section.List.enums.sectionStatus.disabled')}
              </Badge>
            )}
          </InlineStack>
        }
      >
        <Box padding={breakpoints.mdUp ? '0' : '400'}>
          {!currentValues.enabled && (
            <SectionDisableBanner
              sectionId={section.id}
              section={section}
              pageType={page.type}
            />
          )}
          <InlineGrid
            columns={breakpoints.mdUp ? '2fr 1fr' : '1fr'}
            gap={'400'}
          >
            <BlockStack gap={'400'}>
              <Details
                page="edit"
                pricing={pricing}
                dashboardData={dashboardDetails}
                form={form}
              />
              <Recommendation
                pricing={pricing}
                dashboardData={dashboardDetails}
                sectionId={sectionId}
                form={form}
              />
              {shouldDiscountConfigRender({
                type: section?.type,
                pageType: page?.type,
              }) && (
                <SectionDiscountConfig
                  form={form}
                  pricing={pricing}
                  dashboardData={dashboardDetails}
                />
              )}
              <LocationSection
                form={form}
                sectionId={sectionId}
                type={section?.type}
                templates={templatesQuery.data?.templates}
              />
              <AdvanceConfig
                pricing={pricing}
                dashboardData={dashboardDetails}
                showPricePercentageThreshold={
                  section?.type === BOUGHT_TOGETHER.value
                }
                form={form}
              />
            </BlockStack>
            <BlockStack gap={'200'}>
              <AboutSection
                page={currentValues.page}
                pricing={pricing}
                dashboardData={dashboardDetails}
                form={form}
              />
              <Status
                form={form}
                isEnabled={form.fields.enabled.value}
                handleStatusChange={handleStatusChange}
                loadingState={{ loading, setLoading }}
              />
              {isTemplateEnabled && (
                <SelectTemplate
                  section={section}
                  loadingState={{ loading, setLoading }}
                  pricing={pricing}
                  dashboardData={dashboardDetails}
                  form={form}
                  templates={templatesQuery.data?.templates}
                />
              )}
              <Segmentations
                pricing={pricing}
                dashboardData={dashboardDetails}
                form={form}
              />
              <AnalyticsSection
                shop={shop}
                sectionId={sectionId}
                filters={{
                  dateGte: new Date(new Date() - 1000 * 60 * 60 * 24 * 30).valueOf(),
                  dateLte: new Date().valueOf(),
                }}
              />
              <StatusCheck
                section={section}
                isPasswordProtected={
                  dashboardDetails.config.storefrontPasswordEnabled
                }
                isPlanLimitReached={isPlanLimitReached}
                isSectionDowngraded={isSectionDowngraded}
                domain={dashboardDetails?.shop?.domain}
                page={page}
              />
            </BlockStack>
          </InlineGrid>
        </Box>
        <PageActions
          primaryAction={{
            content: 'Save',
            disabled: compareValues(parsedFormValues, currentValues),
            loading: form.submitting,
            onAction: async () => {
              await form.submit()
            },
          }}
          secondaryActions={
            <Button
              variant="primary"
              tone="critical"
              onClick={() => {
                toggleDeleteModal('open')
              }}
            >
              Delete
            </Button>
          }
        />
        {/* To add some space in the bottom so that sections can be scrolled to the top */}
        <Box
          style={{
            height: '10vh',
          }}
        />
      </Page>
      <PricingModal modal={pricing} />
      <ui-modal id="glood-delete-modal">
        <Box
          style={{
            padding: 'var(--p-space-200)',
          }}
        >
          <p>{t('Section.Details.deletionText')}</p>
        </Box>
        <ui-title-bar title="Attention">
          <button
            variant="primary"
            loading={deleting}
            onClick={async () => {
              setDeleting(true)
              await deleteSection(sectionId)
              analytics.trackSectionEvent(
                MixPanelAnalytics.Actions.DELETE,
                section,
                page.type,
              )
              setDeleting(false)
              toggleDeleteModal('close')
              redirectToLink({
                url: '/sections',
                external: false,
              })
              await queryClient.invalidateQueries(['shopDetails'])
            }}
          >
            Delete
          </button>
          <button
            onClick={() => {
              toggleDeleteModal('close')
            }}
          >
            Cancel
          </button>
        </ui-title-bar>
      </ui-modal>
      <ui-modal
        id="visual-editor-modal"
        variant="max"
        src={`/visual-editor?template=${section.template}&widget=${sectionId}&token=${token}`}
      >
        <ui-title-bar title="Visual Editor"></ui-title-bar>
      </ui-modal>
      <ui-modal
        id="visual-editor-modal-v3"
        variant="max"
        src={`/visual-editor-v3/${sectionId}`}
      ></ui-modal>
    </>
  )
}

function arraysEqual(arr1, arr2) {
  // Check if the arrays are of the same length
  if (arr1.length !== arr2.length) {
    return false
  }

  // Sort the arrays if the order of elements doesn't matter
  // arr1.sort();
  // arr2.sort();

  // Iterate over each element in the arrays
  for (let i = 0; i < arr1.length; i++) {
    const item1 = arr1[i]
    const item2 = arr2[i]

    // If both elements are arrays, recursively compare them
    if (Array.isArray(item1) && Array.isArray(item2)) {
      if (!arraysEqual(item1, item2)) {
        return false
      }
    }
    // If both elements are objects, recursively compare them
    else if (
      item1 !== null &&
      typeof item1 === 'object' &&
      item2 !== null &&
      typeof item2 === 'object'
    ) {
      if (!objectsEqual(item1, item2)) {
        return false
      }
    }
    // Otherwise, compare primitive data types directly
    else if (item1 !== item2) {
      return false
    }
  }

  // If all elements are equal, arrays are equal
  return true
}

function objectsEqual(obj1, obj2) {
  const keys1 = Object.keys(obj1)
  const keys2 = Object.keys(obj2)

  // Check if objects have the same number of keys
  if (keys1.length !== keys2.length) {
    return false
  }

  // Iterate over each key in obj1
  for (let key of keys1) {
    const val1 = obj1[key]
    const val2 = obj2[key]

    // If both values are arrays, recursively compare them
    if (Array.isArray(val1) && Array.isArray(val2)) {
      if (!arraysEqual(val1, val2)) {
        return false
      }
    }
    // If both values are objects, recursively compare them
    else if (
      val1 !== null &&
      typeof val1 === 'object' &&
      val2 !== null &&
      typeof val2 === 'object'
    ) {
      if (!objectsEqual(val1, val2)) {
        return false
      }
    }
    // Otherwise, compare primitive data types directly
    else if (val1 !== val2) {
      return false
    }
  }

  // If all keys and values are equal, objects are equal
  return true
}

const upsertAutoRec = async ({
  prevValue = [],
  currentValue = [],
  sectionId,
}) => {
  const areEqual = arraysEqual(currentValue, prevValue)

  if (areEqual) {
    return true
  }

  const deletedEntries = prevValue.filter(
    (prev) => !currentValue.some((curr) => curr.id === prev.id),
  )
  const idsToBeDeleted = deletedEntries.map((entry) => entry.id)

  if (idsToBeDeleted.length > 0) {
    const deleteRes = await deleteAutoRecommendation({
      ids: idsToBeDeleted,
    })

    if (deleteRes.error) {
      return false
    }
  }

  const upsertRes = await upsertAutoRecommendations(
    currentValue.map((obj) => ({
      id: obj.id,
      objectId: obj.url,
      count: 2,
      data: {
        recommendations: obj.products.map((product) => ({
          value: product.id,
        })),
      },
      recommendationType: 'promoted',
      objectType: 'page',
      section: sectionId,
    })),
  )

  if (upsertRes.error) {
    return false
  }

  return true
}

const SkeletonComponent = () => {
  const { t } = useTranslation()
  return (
    <SkeletonPage primaryAction title={t('Section.Edit.title')}>
      <InlineGrid columns="2fr 1fr" gap="400">
        <BlockStack gap="400">
          <Layout>
            <Layout.Section>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="extralarge" />
                  <SkeletonBodyText lines={6} />
                  <SkeletonDisplayText size="extralarge" />
                </BlockStack>
              </Card>
            </Layout.Section>
          </Layout>
          <Layout>
            <Layout.Section>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="large" />
                  <SkeletonBodyText lines={13} />
                </BlockStack>
              </Card>
            </Layout.Section>
          </Layout>
          <Layout>
            <Layout.Section>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="large" />
                  <SkeletonBodyText lines={9} />
                  <SkeletonDisplayText size="large" />
                </BlockStack>
              </Card>
            </Layout.Section>
          </Layout>
          <Layout>
            <Layout.Section>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="large" />
                  <SkeletonBodyText lines={6} />
                  <InlineGrid columns="3fr 1fr" gap="400">
                    <SkeletonDisplayText size="large" />
                    <SkeletonDisplayText size="large" />
                  </InlineGrid>
                  <InlineGrid columns="3fr 1fr" gap="400">
                    <SkeletonDisplayText size="large" />
                    <SkeletonDisplayText size="large" />
                  </InlineGrid>
                </BlockStack>
              </Card>
            </Layout.Section>
          </Layout>
        </BlockStack>
        <BlockStack gap="400">
          <Layout>
            <Layout.Section>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={5} />
                  <InlineGrid columns="1fr 1fr" gap="400">
                    <SkeletonDisplayText size="small" />
                    <SkeletonDisplayText size="small" />
                  </InlineGrid>
                </BlockStack>
              </Card>
            </Layout.Section>
          </Layout>
          <Layout>
            <Layout.Section>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={3} />
                </BlockStack>
              </Card>
            </Layout.Section>
          </Layout>
          <Layout>
            <Layout.Section>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={5} />
                  <SkeletonDisplayText size="small" />
                </BlockStack>
              </Card>
            </Layout.Section>
          </Layout>
          <Layout>
            <Layout.Section>
              <Card>
                <BlockStack gap="400">
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={15} />
                </BlockStack>
              </Card>
            </Layout.Section>
          </Layout>
        </BlockStack>
      </InlineGrid>
    </SkeletonPage>
  )
}

function SectionDisableBanner({ sectionId, section, pageType }) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  return (
    <Box>
      <Banner title={t('Section.disableBanner.title')}>
        <BlockStack gap={'200'}>
          <p>
            {t('Section.disableBanner.description', {
              setupPageLink: (
                <Link url={`/sections/${sectionId}`}>
                  {t('Section.disableBanner.setupCta')}
                </Link>
              ),
            })}
          </p>
          <Box>
            <Button
              loading={loading}
              onClick={async () => {
                setLoading(true)
                const res = await updateSection(sectionId, { enabled: true })
                setLoading(false)
                if (res.error) {
                  shopify.toast.show(t('Section.disableBanner.error'), {
                    isError: true,
                  })
                  return
                }
                analytics.trackSectionEvent(
                  MixPanelAnalytics.Actions.ENABLE,
                  section,
                  pageType,
                )
                await queryClient.invalidateQueries(['shopDetails'])
                shopify.toast.show(t('Section.disableBanner.success'))
              }}
            >
              {t('Section.disableBanner.enableCta')}
            </Button>
          </Box>
        </BlockStack>
      </Banner>
      <Box padding="200" />
    </Box>
  )
}

function getParsedValuesFromForm(form) {
  const obj = {
    name: form.fields.name?.value ?? undefined,
    experience: form.fields.experience?.value ?? undefined,
    page: form.fields.page?.value ?? undefined,
    widgetType: form.fields.widgetType?.value ?? undefined,
    layout: form.fields.layout?.value ?? undefined,
    excludedProducts: form.fields.excludedProducts?.value ?? undefined,
    disabledProducts: form.fields.disabledProducts?.value ?? undefined,
    whitelistedProducts: form.fields.whitelistedProducts?.value ?? undefined,
    disabledTags: form.fields.disabledTags?.value ?? undefined,
    excludedTags: form.fields.excludedTags?.value ?? undefined,
    whitelistedTags: form.fields.whitelistedTags?.value ?? undefined,
    enableBundleDiscounts:
      form.fields.enableBundleDiscounts?.checked ?? undefined,
    discountType: form.fields.discountType?.value ?? undefined,
    discountValue: form.fields.discountValue?.value ?? undefined,
    segmentation: form.fields.segmentation?.value ?? undefined,
    sectionTitle: form.fields.name?.value ?? undefined,
    showSoldOutItems: form.fields.showSoldOutItems?.checked ?? undefined,
    enableCartProps: form.fields.enableCartProps?.checked ?? undefined,
    pricePercentageThreshold:
      form.fields.pricePercentageThreshold?.value ?? undefined,
    productRankingCriteria:
      form.fields.productRankingCriteria?.value ?? undefined,
    productFilterCriteria:
      form.fields.productFilterCriteria?.value ?? undefined,
    fallbackCriteria: form.fields.fallbackCriteria?.value ?? undefined,
    minPrice: form.fields.minPrice?.value ?? undefined,
    maxPrice: form.fields.maxPrice?.value ?? undefined,
    automaticEnabled: form.fields.automaticEnabled?.checked ?? undefined,
    enableRandom: form.fields.enableRandom?.checked ?? undefined,
    allowIfUnavailable: form.fields.allowIfUnavailable?.checked ?? undefined,
    template: form.fields.template?.value ?? undefined,
    enabledUrls: form.fields.enabledUrls?.value ?? undefined,
    location: form.fields.location?.value ?? undefined,
    position: form.fields.position?.value ?? undefined,
    collections: form.fields.collections?.value ?? undefined,
    slots: form.fields.slots?.value ?? undefined,
    objects: form.fields.objects?.value ?? undefined,
    translations: form.fields.translations?.value ?? undefined,
    enableCartButton: form.fields.enableCartButton?.checked ?? undefined,
    applyDiscountOnlyToRec:
      form.fields.applyDiscountOnlyToRec?.checked ?? undefined,
    discountMessageObject:
      form.fields.discountMessageObject?.value ?? undefined,
    discountSubtitle: form.fields.discountSubtitle?.value ?? undefined,
    discountSubtitleTranslated:
      form.fields.discountSubtitleTranslated?.value ?? undefined,
    discountMessage: form.fields.discountMessage?.value ?? undefined,
    enabled: form.fields.enabled?.value ?? undefined,
    templateV3: form.fields.templateV3?.value ?? undefined,
    extra: form.fields.extra?.value || {},
  }

  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key] = value
    }
    return acc
  }, {})
}

function compareValues(obj1, obj2) {
  const result = Object.entries(obj1).every(([key, value]) => {
    return (
      (Array.isArray(value) && arraysEqual(value, obj2[key])) ||
      (typeof value === 'object' &&
        value !== null &&
        objectsEqual(value, obj2[key])) ||
      (typeof value === 'boolean' && value === obj2[key]) ||
      (typeof value === 'string' && value === obj2[key]) ||
      (typeof value === 'number' && value === obj2[key])
    )
  })
  return result
}

function serialiseTranslationObject(obj) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key] = {
        title: value,
      }
    }
    return acc
  }, {})
}

function deserialiseTranslationObject(obj) {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key] = value.title
    }
    return acc
  }, {})
}

function toggleDeleteModal(state) {
  const modal = document.getElementById('glood-delete-modal')
  if (state === 'open') {
    modal.show()
  } else {
    modal.hide()
  }
}
