export const VisualEditorSchema = (t) => ({
  tabs: [
    {
      title: t('VisualEditorForm.tabs.widgetSettings.title'),
      layout: ['carousel', 'horizontal_grid', 'amazon_bought_together'],
      sections: [
        {
          rows: [
            {
              fields: [
                {
                  name: 'title',
                  label: t('VisualEditorForm.fields.title.label'),
                  type: 'text',
                  validators: [
                    { type: 'notEmpty' },
                    { type: 'minLength', length: 5 },
                    { type: 'maxLength', length: '128' },
                  ],
                  path: ['widget', 'title'],
                  defaultValue: t('VisualEditorForm.fields.title.defaultValue'),
                  placeholder: t('VisualEditorForm.fields.title.placeholder'),
                },
                {
                  name: 'widgetTitleAlignment',
                  label: t(
                    'VisualEditorForm.fields.widgetTitleAlignment.label',
                  ),
                  type: 'select',
                  options: [
                    {
                      value: 'left',
                      label: t(
                        'VisualEditorForm.fields.widgetTitleAlignment.options.left',
                      ),
                    },
                    {
                      value: 'center',
                      label: t(
                        'VisualEditorForm.fields.widgetTitleAlignment.options.center',
                      ),
                    },
                  ],
                  path: ['template', 'settings', 'widgetTitleAlignment'],
                  validators: [{ type: 'notEmpty' }],
                  defaultValue: '',
                },
                {
                  name: 'widgetFontFamily',
                  label: t('VisualEditorForm.fields.widgetFontFamily.label'),
                  type: 'text',
                  validators: [],
                  helpText: t(
                    'VisualEditorForm.fields.widgetFontFamily.helpText',
                  ),
                  path: ['template', 'settings', 'widgetFontFamily'],
                  defaultValue: '',
                },
              ],
            },
            {
              fields: [
                {
                  name: 'widgetTitleFontSize',
                  label: t('VisualEditorForm.fields.widgetTitleFontSize.label'),
                  type: 'number',
                  suffix: 'px',
                  validators: [
                    { type: 'notEmpty' },
                    { type: 'positiveIntegerString' },
                  ],
                  path: ['template', 'settings', 'widgetTitleFontSize'],
                  min: 0,
                  defaultValue: 32,
                },
                {
                  name: 'maxContent',
                  label: t('VisualEditorForm.fields.maxContent.label'),
                  type: 'number',
                  min: 1,
                  max: 16,
                  validators: [
                    { type: 'notEmpty' },
                    { type: 'positiveIntegerString' },
                  ],
                  helpText: t('VisualEditorForm.fields.maxContent.helpText'),
                  path: ['widget', 'maxContent'],
                  defaultValue: 8,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: t('VisualEditorForm.tabs.imageSettings.title'),
      layout: ['carousel', 'horizontal_grid'],
      sections: [
        {
          rows: [
            {
              fields: [
                {
                  name: 'imageObjectFit',
                  label: t('VisualEditorForm.fields.imageObjectFit.label'),
                  type: 'select',
                  options: [
                    {
                      value: 'cover',
                      label: t(
                        'VisualEditorForm.fields.imageObjectFit.options.cover',
                      ),
                    },
                    {
                      value: 'contain',
                      label: t(
                        'VisualEditorForm.fields.imageObjectFit.options.contain',
                      ),
                    },
                  ],
                  validators: [{ type: 'notEmpty' }],
                  path: [
                    'template',
                    'settings',
                    'productCard',
                    'imageObjectFit',
                  ],
                  defaultValue: 'cover',
                },
                {
                  name: 'imageAspectRatio',
                  label: t('VisualEditorForm.fields.imageAspectRatio.label'),
                  type: 'text',
                  validators: [
                    { type: 'notEmpty' },
                    {
                      type: 'aspectRatio',
                    },
                  ],
                  path: [
                    'template',
                    'settings',
                    'productCard',
                    'imageAspectRatio',
                  ],
                  defaultValue: '4:3',
                  helpText: t(
                    'VisualEditorForm.fields.imageAspectRatio.helpText',
                  ),
                },
                {
                  name: 'imageHoverMode',
                  label: t('VisualEditorForm.fields.imageHoverMode.label'),
                  type: 'select',
                  options: [
                    {
                      value: 'secondary',
                      label: t(
                        'VisualEditorForm.fields.imageHoverMode.options.secondary',
                      ),
                    },
                    {
                      value: 'zoom',
                      label: t(
                        'VisualEditorForm.fields.imageHoverMode.options.zoom',
                      ),
                    },
                    {
                      value: 'none',
                      label: t(
                        'VisualEditorForm.fields.imageHoverMode.options.none',
                      ),
                    },
                  ],
                  validators: [{ type: 'notEmpty' }],
                  path: [
                    'template',
                    'settings',
                    'productCard',
                    'imageHoverMode',
                  ],
                  defaultValue: 'secondary',
                },
                {
                  name: 'gutter',
                  label: t('VisualEditorForm.fields.gutter.label'),
                  type: 'number',
                  min: 0,
                  suffix: 'px',
                  helpText: t('VisualEditorForm.fields.gutter.helpText'),
                  validators: [
                    { type: 'notEmpty' },
                    { type: 'positiveNumericString' },
                  ],
                  path: ['template', 'settings', 'productCard', 'gutter'],
                  defaultValue: 10,
                },
                {
                  name: 'gutterMobile',
                  label: t('VisualEditorForm.fields.gutterMobile.label'),
                  type: 'number',
                  suffix: 'px',
                  min: 0,
                  helpText: t('VisualEditorForm.fields.gutterMobile.helpText'),
                  validators: [
                    { type: 'notEmpty' },
                    { type: 'positiveNumericString' },
                  ],
                  path: ['template', 'settings', 'productCard', 'gutterMobile'],
                  defaultValue: 10,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: t('VisualEditorForm.tabs.imageSettings.title'),
      layout: ['amazon_bought_together'],
      sections: [
        {
          rows: [
            {
              fields: [
                {
                  name: 'imageObjectFit',
                  label: t('VisualEditorForm.fields.imageObjectFit.label'),
                  type: 'select',
                  options: [
                    {
                      value: 'cover',
                      label: t(
                        'VisualEditorForm.fields.imageObjectFit.options.cover',
                      ),
                    },
                    {
                      value: 'contain',
                      label: t(
                        'VisualEditorForm.fields.imageObjectFit.options.contain',
                      ),
                    },
                  ],
                  validators: [{ type: 'notEmpty' }],
                  path: [
                    'template',
                    'settings',
                    'amazonBoughtTogether',
                    'imageObjectFit',
                  ],
                  defaultValue: 'cover',
                },
                {
                  name: 'imageAspectRatio',
                  label: t('VisualEditorForm.fields.imageAspectRatio.label'),
                  type: 'text',
                  validators: [
                    { type: 'notEmpty' },
                    {
                      type: 'aspectRatio',
                    },
                  ],
                  path: [
                    'template',
                    'settings',
                    'amazonBoughtTogether',
                    'imageAspectRatio',
                  ],
                  defaultValue: '4:3',
                  helpText: t(
                    'VisualEditorForm.fields.imageAspectRatio.helpText',
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: t('VisualEditorForm.tabs.productCardSettings.title'),
      layout: ['carousel', 'horizontal_grid'],
      sections: [
        {
          rows: [
            {
              fields: [
                {
                  name: 'titleFontSize',
                  label: t('VisualEditorForm.fields.titleFontSize.label'),
                  type: 'number',
                  min: 0,
                  suffix: 'px',
                  validators: [
                    { type: 'notEmpty' },
                    { type: 'positiveIntegerString' },
                  ],
                  path: [
                    'template',
                    'settings',
                    'productCard',
                    'titleFontSize',
                  ],
                  defaultValue: 16,
                },
                {
                  name: 'titleColor',
                  label: t('VisualEditorForm.fields.titleColor.label'),
                  type: 'color',
                  validators: [{ type: 'notEmpty' }],
                  path: ['template', 'settings', 'productCard', 'titleColor'],
                  defaultValue: '#222222',
                },
                {
                  name: 'titleAlign',
                  label: t('VisualEditorForm.fields.titleAlign.label'),
                  type: 'select',
                  options: [
                    {
                      value: 'left',
                      label: t(
                        'VisualEditorForm.fields.titleAlign.options.left',
                      ),
                    },
                    {
                      value: 'center',
                      label: t(
                        'VisualEditorForm.fields.titleAlign.options.center',
                      ),
                    },
                  ],
                  validators: [{ type: 'notEmpty' }],
                  path: ['template', 'settings', 'productCard', 'titleAlign'],
                  defaultValue: 'center',
                },
                {
                  name: 'maxTitleRows',
                  label: t('VisualEditorForm.fields.maxTitleRows.label'),
                  type: 'number',
                  min: 1,
                  max: 2,
                  validators: [
                    { type: 'notEmpty' },
                    { type: 'positiveIntegerString' },
                  ],
                  path: ['template', 'settings', 'productCard', 'maxTitleRows'],
                  defaultValue: 2,
                },
                {
                  name: 'productTitleFontFamily',
                  label: t(
                    'VisualEditorForm.fields.productTitleFontFamily.label',
                  ),
                  type: 'text',
                  validators: [],
                  path: [
                    'template',
                    'settings',
                    'productCard',
                    'productTitleFontFamily',
                  ],
                  defaultValue: '',
                },
                {
                  name: 'maxTitleCharacters',
                  label: t('VisualEditorForm.fields.maxTitleCharacters.label'),
                  type: 'number',
                  min: 1,
                  max: 2048,
                  validators: [
                    { type: 'notEmpty' },
                    { type: 'positiveIntegerString' },
                  ],
                  defaultValue: 50,
                  path: [
                    'template',
                    'settings',
                    'productCard',
                    'maxTitleCharacters',
                  ],
                },
              ],
            },
            {
              fields: [
                {
                  name: 'vendorPosition',
                  label: t('VisualEditorForm.fields.vendorPosition.label'),
                  type: 'select',
                  options: [
                    {
                      value: 'hidden',
                      label: t(
                        'VisualEditorForm.fields.vendorPosition.options.hidden',
                      ),
                    },
                    {
                      value: 'above',
                      label: t(
                        'VisualEditorForm.fields.vendorPosition.options.aboveProductTitle',
                      ),
                    },
                    {
                      value: 'below',
                      label: t(
                        'VisualEditorForm.fields.vendorPosition.options.belowProductTitle',
                      ),
                    },
                  ],
                  validators: [{ type: 'notEmpty' }],
                  path: [
                    'template',
                    'settings',
                    'productCard',
                    'vendorPosition',
                  ],
                  defaultValue: 'hidden',
                },
                {
                  name: 'vendorFontSize',
                  label: t('VisualEditorForm.fields.vendorFontSize.label'),
                  type: 'number',
                  min: 0,
                  suffix: 'px',
                  validators: [
                    { type: 'notEmpty' },
                    { type: 'positiveIntegerString' },
                  ],
                  path: [
                    'template',
                    'settings',
                    'productCard',
                    'vendorFontSize',
                  ],
                  defaultValue: 14,
                },
                {
                  name: 'vendorColor',
                  label: t('VisualEditorForm.fields.vendorColor.label'),
                  type: 'color',
                  validators: [{ type: 'notEmpty' }],
                  path: ['template', 'settings', 'productCard', 'vendorColor'],
                  defaultValue: '#222222',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: t('VisualEditorForm.tabs.prices.title'),
      layout: ['carousel', 'horizontal_grid'],
      sections: [
        {
          rows: [
            {
              fields: [
                {
                  name: 'priceFontSize',
                  label: t('VisualEditorForm.fields.priceFontSize.label'),
                  type: 'number',
                  min: 0,
                  suffix: 'px',
                  validators: [
                    { type: 'notEmpty' },
                    { type: 'positiveIntegerString' },
                  ],
                  path: [
                    'template',
                    'settings',
                    'productCard',
                    'priceFontSize',
                  ],
                  defaultValue: 16,
                },
                {
                  name: 'priceCompareAtMode',
                  label: t('VisualEditorForm.fields.priceCompareAtMode.label'),
                  type: 'select',
                  options: [
                    {
                      value: 'hidden',
                      label: t(
                        'VisualEditorForm.fields.priceCompareAtMode.options.hidden',
                      ),
                    },
                    {
                      value: 'before',
                      label: t(
                        'VisualEditorForm.fields.priceCompareAtMode.options.beforeOriginalPrice',
                      ),
                    },
                    {
                      value: 'after',
                      label: t(
                        'VisualEditorForm.fields.priceCompareAtMode.options.afterOriginalPrice',
                      ),
                    },
                  ],
                  validators: [{ type: 'notEmpty' }],
                  path: [
                    'template',
                    'settings',
                    'productCard',
                    'priceCompareAtMode',
                  ],
                  defaultValue: 'before',
                },
                {
                  name: 'comparePriceVisibilityMobile',
                  label: t(
                    'VisualEditorForm.fields.comparePriceVisibilityMobile.label',
                  ),
                  type: 'select',
                  options: [
                    {
                      value: 'hidden',
                      label: t(
                        'VisualEditorForm.fields.comparePriceVisibilityMobile.options.hidden',
                      ),
                    },
                    {
                      value: 'visible',
                      label: t(
                        'VisualEditorForm.fields.comparePriceVisibilityMobile.options.visible',
                      ),
                    },
                  ],
                  validators: [{ type: 'notEmpty' }],
                  path: [
                    'template',
                    'settings',
                    'productCard',
                    'comparePriceVisibilityMobile',
                  ],
                  defaultValue: 'hidden',
                },
                {
                  name: 'priceFontFamily',
                  label: t('VisualEditorForm.fields.priceFontFamily.label'),
                  type: 'text',
                  validators: [],
                  path: [
                    'template',
                    'settings',
                    'productCard',
                    'priceFontFamily',
                  ],
                  defaultValue: '',
                },
              ],
            },
            {
              fields: [
                {
                  name: 'priceColorCompare',
                  label: t('VisualEditorForm.fields.priceColorCompare.label'),
                  type: 'color',
                  validators: [{ type: 'notEmpty' }],
                  path: [
                    'template',
                    'settings',
                    'productCard',
                    'priceColorCompare',
                  ],
                  defaultValue: '#aaaaaa',
                },
                {
                  name: 'priceColorOriginal',
                  label: t('VisualEditorForm.fields.priceColorOriginal.label'),
                  type: 'color',
                  validators: [{ type: 'notEmpty' }],
                  path: [
                    'template',
                    'settings',
                    'productCard',
                    'priceColorOriginal',
                  ],
                  defaultValue: '#222222',
                },
                {
                  name: 'priceColor',
                  label: t('VisualEditorForm.fields.priceColor.label'),
                  type: 'color',
                  validators: [{ type: 'notEmpty' }],
                  path: ['template', 'settings', 'productCard', 'priceColor'],
                  defaultValue: '#222222',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: t('VisualEditorForm.tabs.atcBtn.title'),
      layout: ['carousel', 'horizontal_grid'],
      sections: [
        {
          rows: [
            {
              fields: [
                {
                  name: 'addToCartBackground',
                  label: t('VisualEditorForm.fields.addToCartBackground.label'),
                  type: 'color',
                  validators: [{ type: 'notEmpty' }],
                  path: [
                    'template',
                    'settings',
                    'productCard',
                    'addToCartBackground',
                  ],
                  defaultValue: '#000000',
                },
                {
                  name: 'addToCartColor',
                  label: t('VisualEditorForm.fields.addToCartColor.label'),
                  type: 'color',
                  validators: [{ type: 'notEmpty' }],
                  path: [
                    'template',
                    'settings',
                    'productCard',
                    'addToCartColor',
                  ],
                  defaultValue: '#ffffff',
                },
                {
                  name: 'addToCartMode',
                  label: t('VisualEditorForm.fields.addToCartMode.label'),
                  type: 'select',
                  options: [
                    {
                      value: 'hidden',
                      label: t(
                        'VisualEditorForm.fields.addToCartMode.options.hidden',
                      ),
                    },
                    {
                      value: 'slide',
                      label: t(
                        'VisualEditorForm.fields.addToCartMode.options.slide',
                      ),
                    },
                    {
                      value: 'visible',
                      label: t(
                        'VisualEditorForm.fields.addToCartMode.options.visible',
                      ),
                    },
                  ],
                  validators: [{ type: 'notEmpty' }],
                  path: [
                    'template',
                    'settings',
                    'productCard',
                    'addToCartMode',
                  ],
                  defaultValue: 'slide',
                },
              ],
            },
            {
              fields: [
                {
                  name: 'cartButtonText',
                  label: t('VisualEditorForm.fields.cartButtonText.label'),
                  type: 'text',
                  validators: [
                    { type: 'notEmpty' },
                    { type: 'minLength', length: 5 },
                    { type: 'maxLength', length: '128' },
                  ],
                  path: [
                    'tempalate',
                    'translations',
                    'primaryLocale',
                    'addToCartText',
                  ],
                  defaultValue: t(
                    'VisualEditorForm.fields.cartButtonText.defaultValue',
                  ),
                },
                {
                  name: 'addedToCartText',
                  label: t('VisualEditorForm.fields.addedToCartText.label'),
                  type: 'text',
                  validators: [
                    { type: 'notEmpty' },
                    { type: 'minLength', length: 5 },
                    { type: 'maxLength', length: '128' },
                  ],
                  path: [
                    'template',
                    'translations',
                    'primaryLocale',
                    'addedToCartText',
                  ],
                  defaultValue: t(
                    'VisualEditorForm.fields.addedToCartText.defaultValue',
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: t('VisualEditorForm.tabs.atcBtn.title'),
      layout: ['amazon_bought_together'],
      sections: [
        {
          rows: [
            {
              fields: [
                {
                  name: 'buttonBackgroundColor',
                  label: t(
                    'VisualEditorForm.fields.buttonBackgroundColor.label',
                  ),
                  type: 'color',
                  validators: [{ type: 'notEmpty' }],
                  path: [
                    'template',
                    'settings',
                    'amazonBoughtTogether',
                    'buttonBackgroundColor',
                  ],
                  defaultValue: '#000000',
                },
                {
                  name: 'buttonTextColor',
                  label: t('VisualEditorForm.fields.buttonTextColor.label'),
                  type: 'color',
                  validators: [{ type: 'notEmpty' }],
                  path: [
                    'template',
                    'settings',
                    'amazonBoughtTogether',
                    'buttonTextColor',
                  ],
                  defaultValue: '#ffffff',
                },
                {
                  name: 'fbtCartButtonText',
                  label: t('VisualEditorForm.fields.fbtCartButtonText.label'),
                  type: 'text',
                  validators: [
                    { type: 'notEmpty' },
                    { type: 'minLength', length: 5 },
                    { type: 'maxLength', length: '128' },
                  ],
                  path: [
                    'template',
                    'translations',
                    'primaryLocale',
                    'fbtAddToCartText',
                  ],
                  defaultValue: t(
                    'VisualEditorForm.fields.fbtCartButtonText.defaultValue',
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: t('VisualEditorForm.tabs.translations.title'),
      layout: ['amazon_bought_together'],
      sections: [
        {
          rows: [
            {
              fields: [
                {
                  name: 'thisProductText',
                  label: t('VisualEditorForm.fields.this_product_text.label'),
                  type: 'text',
                  validators: [
                    { type: 'notEmpty' },
                    { type: 'minLength', length: 5 },
                    { type: 'maxLength', length: '128' },
                  ],
                  path: [
                    'template',
                    'translations',
                    'primaryLocale',
                    'thisProductText',
                  ],
                  defaultValue: t(
                    'VisualEditorForm.fields.this_product_text.defaultValue',
                  ),
                },
                {
                  name: 'totalPriceText',
                  label: t('VisualEditorForm.fields.total_price_text.label'),
                  type: 'text',
                  validators: [
                    { type: 'notEmpty' },
                    { type: 'minLength', length: 5 },
                    { type: 'maxLength', length: '128' },
                  ],
                  path: [
                    'template',
                    'translations',
                    'primaryLocale',
                    'totalPriceText',
                  ],
                  defaultValue: t(
                    'VisualEditorForm.fields.total_price_text.defaultValue',
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: t('VisualEditorForm.tabs.priceColors.title'),
      layout: ['amazon_bought_together'],
      sections: [
        {
          rows: [
            {
              fields: [
                {
                  name: 'totalPriceColor',
                  label: t('VisualEditorForm.fields.totalPriceColor.label'),
                  type: 'color',
                  validators: [{ type: 'notEmpty' }],
                  path: [
                    'template',
                    'settings',
                    'amazonBoughtTogether',
                    'totalPriceColor',
                  ],
                  defaultValue: '#222000',
                },
                {
                  name: 'productPriceColor',
                  label: t('VisualEditorForm.fields.productPriceColor.label'),
                  type: 'color',
                  validators: [{ type: 'notEmpty' }],
                  path: [
                    'template',
                    'settings',
                    'amazonBoughtTogether',
                    'productPriceColor',
                  ],
                  defaultValue: '#200222',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: t('VisualEditorForm.tabs.salesLabel.title'),
      layout: ['carousel', 'horizontal_grid'],
      sections: [
        {
          rows: [
            {
              fields: [
                {
                  name: 'discountLabelVisible',
                  label: t(
                    'VisualEditorForm.fields.discountLabelVisible.label',
                  ),
                  type: 'select',
                  options: [
                    {
                      value: 'false',
                      label: t(
                        'VisualEditorForm.fields.discountLabelVisible.options.hidden',
                      ),
                    },
                    {
                      value: 'true',
                      label: t(
                        'VisualEditorForm.fields.discountLabelVisible.options.visible',
                      ),
                    },
                  ],
                  validators: [{ type: 'notEmpty' }],
                  path: [
                    'template',
                    'settings',
                    'productCard',
                    'discountLabelVisible',
                  ],
                  defaultValue: 'true',
                },
                {
                  name: 'discountLabelBackground',
                  label: t(
                    'VisualEditorForm.fields.discountLabelBackground.label',
                  ),
                  type: 'color',
                  validators: [{ type: 'notEmpty' }],
                  path: [
                    'template',
                    'settings',
                    'productCard',
                    'discountLabelBackground',
                  ],
                  defaultValue: '#ff0000',
                },
                {
                  name: 'discountLabelColor',
                  label: t('VisualEditorForm.fields.discountLabelColor.label'),
                  type: 'color',
                  validators: [{ type: 'notEmpty' }],
                  path: [
                    'template',
                    'settings',
                    'productCard',
                    'discountLabelColor',
                  ],
                  defaultValue: '#ffffff',
                },
              ],
            },
            {
              fields: [
                {
                  name: 'discountLabelText',
                  label: t('VisualEditorForm.fields.discountLabelText.label'),
                  type: 'text',
                  validators: [
                    { type: 'notEmpty' },
                    { type: 'minLength', length: 5 },
                    { type: 'maxLength', length: '128' },
                  ],
                  helpText: t(
                    'VisualEditorForm.fields.discountLabelText.helpText',
                  ),
                  path: [
                    'template',
                    'translations',
                    'primaryLocale',
                    'discountLabelText',
                  ],
                  defaultValue: '{percent} off',
                },
                {
                  name: 'discountLabelPosition',
                  label: t(
                    'VisualEditorForm.fields.discountLabelPosition.label',
                  ),
                  type: 'select',
                  options: [
                    {
                      value: 'left',
                      label: t(
                        'VisualEditorForm.fields.discountLabelPosition.options.left',
                      ),
                    },
                    {
                      value: 'right',
                      label: t(
                        'VisualEditorForm.fields.discountLabelPosition.options.right',
                      ),
                    },
                  ],
                  validators: [{ type: 'notEmpty' }],
                  path: [
                    'template',
                    'settings',
                    'productCard',
                    'discountLabelPosition',
                  ],
                  defaultValue: 'left',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: t('VisualEditorForm.tabs.carousel.title'),
      layout: ['carousel'],
      sections: [
        {
          rows: [
            {
              fields: [
                {
                  name: 'navigationMode',
                  label: t('VisualEditorForm.fields.navigationMode.label'),
                  type: 'select',
                  options: [
                    {
                      value: 'arrows',
                      label: t(
                        'VisualEditorForm.fields.navigationMode.options.arrows',
                      ),
                    },
                    {
                      value: 'bullet',
                      label: t(
                        'VisualEditorForm.fields.navigationMode.options.bullets',
                      ),
                    },
                  ],
                  validators: [{ type: 'notEmpty' }],
                  path: ['template', 'settings', 'carousel', 'navigationMode'],
                  defaultValue: 'arrows',
                },
                {
                  name: 'type',
                  label: t('VisualEditorForm.fields.type.label'),
                  type: 'select',
                  options: [
                    {
                      value: 'carousel',
                      label: t('VisualEditorForm.fields.type.options.carousel'),
                    },
                    {
                      value: 'slider',
                      label: t('VisualEditorForm.fields.type.options.slider'),
                    },
                  ],
                  validators: [{ type: 'notEmpty' }],
                  path: [
                    'template',
                    'settings',
                    'carousel',
                    'glideConfig',
                    'type',
                  ],
                  defaultValue: 'carousel',
                },
              ],
            },
            {
              dependency: {
                navigationMode: 'arrows',
              },
              fields: [
                {
                  name: 'arrowStyle',
                  label: t('VisualEditorForm.fields.arrowStyle.label'),
                  type: 'select',
                  options: [
                    {
                      value: 'rectangle',
                      label: t(
                        'VisualEditorForm.fields.arrowStyle.options.rectangle',
                      ),
                    },
                    {
                      value: 'circle',
                      label: t(
                        'VisualEditorForm.fields.arrowStyle.options.circle',
                      ),
                    },
                  ],
                  validators: [{ type: 'notEmpty' }],
                  path: ['template', 'settings', 'carousel', 'arrowStyle'],
                  defaultValue: 'rectangle',
                },
                {
                  name: 'arrowFill',
                  label: t('VisualEditorForm.fields.arrowFill.label'),
                  type: 'color',
                  validators: [{ type: 'notEmpty' }],
                  path: ['template', 'settings', 'carousel', 'arrowFill'],
                  defaultValue: '#000000',
                },
                {
                  name: 'arrowBackground',
                  label: t('VisualEditorForm.fields.arrowBackground.label'),
                  type: 'color',
                  validators: [{ type: 'notEmpty' }],
                  path: ['template', 'settings', 'carousel', 'arrowBackground'],
                  defaultValue: '#ffffff',
                },
                {
                  name: 'arrowTransition',
                  label: t('VisualEditorForm.fields.arrowTransition.label'),
                  type: 'select',
                  options: [
                    {
                      value: 'appearOnMouseHover',
                      label: t(
                        'VisualEditorForm.fields.arrowTransition.options.appearOnMouseHover',
                      ),
                    },
                    {
                      value: 'alwaysVisible',
                      label: t(
                        'VisualEditorForm.fields.arrowTransition.options.alwaysVisible',
                      ),
                    },
                  ],
                  validators: [{ type: 'notEmpty' }],
                  path: ['template', 'settings', 'carousel', 'arrowTransition'],
                  defaultValue: 'appearOnMouseHover',
                },
              ],
            },
            {
              dependency: {
                navigationMode: 'bullet',
              },
              fields: [
                {
                  name: 'bulletBackground',
                  label: t('VisualEditorForm.fields.bulletBackground.label'),
                  type: 'color',
                  validators: [
                    {
                      type: 'notEmpty',
                    },
                  ],
                  path: [
                    'template',
                    'settings',
                    'carousel',
                    'bulletBackground',
                  ],
                  defaultValue: '#000000',
                },
                {
                  name: 'bulletActiveBackground',
                  label: t(
                    'VisualEditorForm.fields.bulletActiveBackground.label',
                  ),
                  type: 'color',
                  validators: [
                    {
                      type: 'notEmpty',
                    },
                  ],
                  path: [
                    'template',
                    'settings',
                    'carousel',
                    'bulletActiveBackground',
                  ],
                  defaultValue: '#ffffff',
                },
                {
                  name: 'bulletSize',
                  label: t('VisualEditorForm.fields.bulletSize.label'),
                  type: 'number',
                  min: 0,
                  suffix: 'px',
                  min: 1,
                  validators: [
                    { type: 'notEmpty' },
                    { type: 'positiveNumericString' },
                  ],
                  path: ['template', 'settings', 'carousel', 'bulletSize'],
                  defaultValue: 8,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      title: t('VisualEditorForm.tabs.itemPerRow.title'),
      layout: ['carousel', 'horizontal_grid'],
      sections: [
        {
          rows: [
            {
              fields: [
                {
                  name: 'perRowDesktop',
                  label: t('VisualEditorForm.fields.perRowDesktop.label'),
                  type: 'number',
                  min: 1,
                  max: 10,
                  validators: [
                    { type: 'notEmpty' },
                    { type: 'positiveIntegerString' },
                  ],
                  path: ['template', 'settings', '{{layout}}', 'perRowDesktop'],
                  defaultValue: 4,
                },
                {
                  name: 'perRowMobile',
                  label: t('VisualEditorForm.fields.perRowMobile.label'),
                  type: 'number',
                  min: 1,
                  max: 5,
                  validators: [
                    { type: 'notEmpty' },
                    { type: 'positiveIntegerString' },
                  ],
                  path: ['template', 'settings', '{{layout}}', 'perRowMobile'],
                  defaultValue: 2,
                },
                {
                  name: 'perRowWidescreen',
                  label: t('VisualEditorForm.fields.perRowWidescreen.label'),
                  type: 'number',
                  min: 1,
                  max: 16,
                  validators: [
                    { type: 'notEmpty' },
                    { type: 'positiveIntegerString' },
                  ],
                  path: [
                    'template',
                    'settings',
                    '{{layout}}',
                    'perRowWidescreen',
                  ],
                  defaultValue: 6,
                },
              ],
            },
          ],
        },
      ],
    },
  ],
})
