import React, { useEffect, useState } from "react"
import {
  Card,
  BlockStack,
  InlineStack,
  Text,
  Icon,
  Tooltip,
  Spinner,
} from "@shopify/polaris"
import {
  AlertCircleIcon,
  CheckCircleIcon,
} from "@shopify/polaris-icons"
import { useQuery } from 'react-query'
import { subDays } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { getSectionsStats } from '../../../../apis/analytics'
import { percentageFormatter } from '../../../../utils/formater'
import { useStoreLinks } from '../../../../hooks/useStoreLinks'
import { PageTypes } from "../../../../../backend/common/enums"

function StatusCheck({
  section,
  isPasswordProtected,
  isPlanLimitReached,
  isSectionDowngraded,
  domain,
  page
}) {
  const { t } = useTranslation()
  const { getLink } = useStoreLinks()
  const [blockPlacementStatus, setBlockPlacementStatus] = useState(null)
  const [isCheckingBlockPlacement, setIsCheckingBlockPlacement] = useState(false)

  // Calculate last 7 days range for stats
  const endDate = new Date()
  const startDate = subDays(endDate, 7)

  // Fetch section stats
  const { data: statsData, isLoading: statsLoading } = useQuery({
    queryKey: ['sectionStats', section?.id],
    queryFn: async () => {
      const { data, error } = await getSectionsStats({
        sections: [section?.id],
        dateGte: startDate.getTime() / 1000,
        dateLte: endDate.getTime() / 1000
      })
      if (error) return Promise.reject(error)
      return data?.stats?.find(stat => String(stat.section) === String(section?.id))
    },
    enabled: Boolean(section?.id),
  })
  // Check for block placement in HTML
  useEffect(() => {
    const checkBlockPlacement = async () => {
      if (isPasswordProtected) {
        setBlockPlacementStatus(false)
        return
      }
      // Check if page type is supported for testing
      const supportedPages = [PageTypes.PRODUCT_PAGE, PageTypes.COLLECTION, PageTypes.CART, PageTypes.HOME_PAGE]
      if (!supportedPages.includes(page?.type)) {
        setBlockPlacementStatus(null)
        return
      }
      setIsCheckingBlockPlacement(true)

      const pageUrl = await getLink(page?.type)
      try {
        const response = await fetch(`https://${domain.split('.').join('---')}.proxy.glood.co${pageUrl}`, {
          redirect: 'manual'
        })
        const html = await response.text()
        const widgetId = `rk-widget-${section?.id}`
        const isBlockPlaced = html.includes(widgetId)
        setBlockPlacementStatus(isBlockPlaced)
      } catch (error) {
        console.error('Error checking block placement:', error)
        setBlockPlacementStatus(false)
      }
      setIsCheckingBlockPlacement(false)
    }
    if (section?.id) {
      checkBlockPlacement()
    }
  }, [section?.id])

  const interactionRate = statsData?.interactionRate ?? 0
  const renderedSections = statsData?.sessionsWithRecommendationViews ?? 0
  const isAIRecommendationsEnabled = section.automaticEnabled
  const viewRate = statsData?.viewRate ?? 0

  const checks = [
    {
      label: t('StatusCheck.checks.passwordProtection.title'),
      condition: !isPasswordProtected,
      successMessage: t('StatusCheck.checks.passwordProtection.success'),
      criticalMessage: t('StatusCheck.checks.passwordProtection.critical'),
      isLoading: false,
      passwordProtected: false
    },
    {
      label: t('StatusCheck.checks.blockPlacement.title'),
      condition: blockPlacementStatus,
      successMessage: t('StatusCheck.checks.blockPlacement.success', { sectionId: section?.id }),
      notApplicableMessage: t('StatusCheck.checks.blockPlacement.notApplicable', { sectionId: section?.id }),
      criticalMessage: t('StatusCheck.checks.blockPlacement.critical', { sectionId: section?.id }),
      isLoading: isCheckingBlockPlacement,
      passwordProtected: true
    },
    {
      label: t('StatusCheck.checks.rendering.title'),
      condition: renderedSections !== 0,
      successMessage: t('StatusCheck.checks.rendering.success'),
      criticalMessage: t('StatusCheck.checks.rendering.critical'),
      isLoading: statsLoading,
      passwordProtected: false
    },
    {
      label: t('StatusCheck.checks.placement.title'),
      condition: viewRate >= 0.1,
      successMessage: t('StatusCheck.checks.placement.success', { viewRate: percentageFormatter(viewRate) }),
      criticalMessage: t('StatusCheck.checks.placement.critical', { viewRate: percentageFormatter(viewRate) }),
      isLoading: statsLoading,
      passwordProtected: false
    },
    {
      label: t('StatusCheck.checks.health.title'),
      condition: interactionRate >= 0.05,
      successMessage: t('StatusCheck.checks.health.success', { interactionRate: percentageFormatter(interactionRate) }),
      criticalMessage: t('StatusCheck.checks.health.critical', { interactionRate: percentageFormatter(interactionRate) }),
      isLoading: statsLoading,
      passwordProtected: false
    },
    {
      label: t('StatusCheck.checks.configuration.title'), 
      condition: isAIRecommendationsEnabled,
      successMessage: t('StatusCheck.checks.configuration.success'),
      criticalMessage: t('StatusCheck.checks.configuration.critical'),
      isLoading: false,
      passwordProtected: false
    },
    {
      label: t('StatusCheck.checks.planUpgrade.title'),
      condition: !isSectionDowngraded,
      successMessage: t('StatusCheck.checks.planUpgrade.success'),
      criticalMessage: t('StatusCheck.checks.planUpgrade.critical'),
      isLoading: false,
      passwordProtected: false
    },
    {
      label: t('StatusCheck.checks.hiddenSection.title'),
      condition: !isPlanLimitReached,
      successMessage: t('StatusCheck.checks.hiddenSection.success'),
      criticalMessage: t('StatusCheck.checks.hiddenSection.critical'),
      isLoading: false,
      passwordProtected: false
    },
  ]

  const renderCheckIcon = (check) => {
    if (check.isLoading) {
      return <Spinner size="small" accessibilityLabel={t('StatusCheck.checks.loading')} />
    }

    // If store is password protected and check is stats dependent
    if (isPasswordProtected && check.passwordProtected) {
      return (
        <Tooltip content={t('StatusCheck.checks.passwordProtected.warning')}>
          <Icon
            source={AlertCircleIcon}
            tone="critical"
          />
        </Tooltip>
      )
    }

    // Handle null/undefined condition
    if (check.condition === null) {
      return (
        <Tooltip content={t('StatusCheck.checks.notApplicable')}>
          <Icon
            source={AlertCircleIcon}
            tone="warning"
          />
        </Tooltip>
      )
    }
    // Regular check status
    return (
      <Tooltip content={check.condition ? check.successMessage : check.criticalMessage}>
        <Icon
          source={check.condition ? CheckCircleIcon : AlertCircleIcon }
          tone={check.tone || (check.condition ? "success" : "critical")}
        />
      </Tooltip>
    )
  }

  return (
    <Card padding="400">
      <BlockStack gap="300">
        <Text as="h2" variant="headingMd">
          {t('StatusCheck.title')}
        </Text>
        <Text as="p" variant="bodyMd" tone="subdued">
          {t('StatusCheck.helpText')}
        </Text>
        {checks.map((check, index) => (
          <InlineStack key={index} align="space-between" blockAlign="center">
            <InlineStack>
              <Text as="p" variant="bodyMd">
                {check.label}
              </Text>
            </InlineStack>
            <InlineStack blockAlign="center" style={{ minWidth: "24px" }}>
              {renderCheckIcon(check)}
            </InlineStack>
          </InlineStack>
        ))}
      </BlockStack>
    </Card>
  )
}

export default StatusCheck