import { camelizeKeys } from 'humps'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import debounce from 'lodash.debounce'
import { useTranslation } from 'react-i18next'
import { Banner, Box, InlineGrid, InlineStack, Spinner } from '@shopify/polaris'
import { useEffect, useRef, useState } from 'react'

import { getTemplateDetails } from '../apis/template'
import Loader from '../components/Loader'
import VisualEditorFormV3 from '../components/VisualEditorFormV3'
import { useDashboardDetails } from '../hooks/useDashboardDetails'
import { useShopDetails } from '../hooks/useShopDetails'
import queryClient from '../utils/query'
import NotFound from './NotFound'
import useStoreLinks from '../hooks/useStoreLinks'
import analytics, { MixPanelAnalytics } from '../utils/analytics'

export default function VisualEditorV2() {
  const { sectionId } = useParams()
  const { t } = useTranslation()
  const shopDetailsQuery = useShopDetails()
  const dashboardDetailsQuery = useDashboardDetails()
  const section = shopDetailsQuery.data?.sections?.find(
    (sec) => sec.id == sectionId,
  )
  const [frameUrl, setFrameUrl] = useState(null)
  const [frameLoading, setFrameLoading] = useState(true)
  const frameRef = useRef(null)
  const { getLink } = useStoreLinks()
  const page = shopDetailsQuery.data?.pages?.find(
    (page) => page.id == section?.page,
  )
  const templateQuery = useQuery({
    queryFn: async () => {
      const res = await getTemplateDetails(section.templateV3)
      if (res.error) {
        return Promise.reject(res.error)
      }
      return res.data
    },
    queryKey: ['templates', section?.templateV3],
    enabled: !!sectionId && !!section && !!section.templateV3,
  })
  const template = templateQuery.data?.template

  const invalidateData = async () => {
    await queryClient.invalidateQueries({
      queryKey: ['template', template.id],
    })
    await queryClient.invalidateQueries({
      queryKey: ['shopDetails'],
    })
  }

  const exitHandler = () => {
    setTimeout(() => {
      window.opener.postMessage('GLOODAI:V3EDITOR:CLOSE')
    }, 100)
  }

  const sendDataToFrame = debounce(function (data) {
    if (frameRef.current && frameRef.current.contentWindow) {
      frameRef.current.contentWindow.postMessage(
        {
          type: 'GLOODAI:EDITOR:DATA',
          data: data,
        },
        '*',
      )
    }
  }, 500)

  useEffect(() => {
    ;(async () => {
      if (!section || !page) {
        return
      }
      const { id: widgetId } = section
      const { type: pageType } = page
      const { domain } = dashboardDetailsQuery.data?.shop ?? {}
      try {
        const pageTypeUrl = await getLink(pageType)
        setFrameUrl(
          `https://${domain.split('.').join('---')}.proxy.glood.co${pageTypeUrl}?rk_section_id=${widgetId}&rk_editor_mode=preview`,
        )
      } catch (err) {
        setFrameUrl(null)
      }
    })()
  }, [section, page, dashboardDetailsQuery.data])

  if (
    shopDetailsQuery.isLoading ||
    templateQuery.isLoading ||
    dashboardDetailsQuery.isLoading
  ) {
    return <Loader />
  }

  if (!sectionId || !section || !template) {
    return <NotFound />
  }

  const isPasswordProtected = dashboardDetailsQuery.data?.config?.storefrontPasswordEnabled

  return (
    <VisualEditorFormV3
      data={{
        section,
        // since template settings is in snake case
        template: camelizeKeys(template),
        page,
      }}
      onFormSubmitComplete={async () => {
        analytics.trackVisualEditorEvent(
          MixPanelAnalytics.Actions.SAVE,
          section,
          page.type,
          template
        )
        await invalidateData()
        exitHandler()
      }}
      exitHandler={exitHandler}
      onFormChange={sendDataToFrame}
    >
      {isPasswordProtected ? (
        <Box padding={'400'}>
          <Banner
            title={t('VisualEditorForm.passwordProtectedText')}
            tone="critical"
          />
        </Box>
      ) : (
        <div
          style={{
            width: '100%',
            height: 'calc(100vh - 84px)',
            position: 'relative',
          }}
        >
          <iframe
            ref={frameRef}
            style={{
              width: '100%',
              height: 'calc(100vh - 84px)',
              opacity: frameLoading ? 0.5 : 1,
            }}
            src={frameUrl}
            onLoad={() => {
              setFrameLoading(false)
            }}
          />
          {frameLoading && (
            <>
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  padding: 'var(--p-space-200)',
                }}
              >
                <Banner>
                  <InlineGrid columns={'1fr auto'}>
                    <p>{t('contentsLoading')}</p>
                    <Spinner size="small" />
                  </InlineGrid>
                </Banner>
              </div>
              <div
                style={{
                  opacity: 0,
                  cursor: 'not-allowed',
                  width: '100%',
                  height: '100%',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              />
            </>
          )}
        </div>
      )}
    </VisualEditorFormV3>
  )
}
