import {
  Badge,
  BlockStack,
  InlineGrid,
  InlineStack,
  Link,
  SkeletonDisplayText,
  Text,
} from '@shopify/polaris'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import shopifyPlusImg from '../../../assets/shopifyPlus.png'
import { listBundles } from '../../../apis/bundles'
import { getCampaigns } from '../../../apis/checkoutUpsell'
import { getShopDetails } from '../../../apis/shop'
import { getDashboardDetails } from '../../../apis/dashbboard'

export function DescriptionCard() {
  const query = useQuery({
    queryKey: 'descriptionCard',
    queryFn: async () => {
      const dashboard = await getShopDetails(),
        campaign = await getCampaigns(),
        bundles = await listBundles(),
        details = await getDashboardDetails()
      return {
        sections: dashboard.data.sections.reduce(
          (prev, curr) => prev + (curr.enabled ? 1 : 0),
          0,
        ),
        campaigns: campaign.data.length,
        bundles: bundles.data.bundles.length,
        webPixelEnabled: details.data.config.webPixelEnabled,
        storefrontTokenCreated: details.data.config.storefrontAccessTokenCreated,
      }
    },
  })

  const { t } = useTranslation()

  const skeleton = <SkeletonDisplayText size="small" maxWidth={50} />

  const options = [
    {
      label: t('DescriptionCard.options.recommendations.text'),
      value: query.isLoading
        ? skeleton
        : t('DescriptionCard.options.recommendations.subText', {
            count: <strong>{query.data?.sections || 0}</strong>,
          }),
      icon: null,
      url: '/sections',
    },
    {
      label: t('DescriptionCard.options.checkoutUpsell.text'),
      value: query.isLoading
        ? skeleton
        : t('DescriptionCard.options.checkoutUpsell.subText', {
            count: <strong>{query.data?.campaigns || 0}</strong>,
          }),
      icon: (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '10px',
            height: 'auto',
            width: '64px',
          }}
        >
          <img
            src={shopifyPlusImg}
            alt="Shopify Plus"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          />
        </div>
      ),
      url: '/checkout-upsell',
    },
    {
      label: t('DescriptionCard.options.bundles.text'),
      value: query.isLoading
        ? skeleton
        : t('DescriptionCard.options.bundles.subText', {
            count: <strong>{query.data?.bundles || 0}</strong>,
          }),
      url: '/bundles',
    },
    {
      label: t('DescriptionCard.options.webPixel.text'),
      value: query.isLoading ? (
        skeleton
      ) : (
        <Badge tone={query.data?.webPixelEnabled ? 'success' : 'info'}>
          {query.data?.webPixelEnabled
            ? t('DescriptionCard.options.webPixel.enabled')
            : t('DescriptionCard.options.webPixel.disabled')}
        </Badge>
      ),
      url: '/settings',
    },
    {
      label: t('DescriptionCard.options.storefrontToken.text'),
      value: query.isLoading ? (
        skeleton
      ) : (
        <Badge tone={query.data?.storefrontTokenCreated ? 'success' : 'info'}>
          {query.data?.storefrontTokenCreated
            ? t('DescriptionCard.options.storefrontToken.created')
            : t('DescriptionCard.options.storefrontToken.notCreated')}
        </Badge>
      ),
      url: '/settings',
    },
  ]

  return (
    <div
      className="Polaris-Box"
      style={{
        '--pc-box-background': 'var(--p-color-bg-surface)',
        '--pc-box-min-height': '100%',
        '--pc-box-overflow-x': 'clip',
        '--pc-box-overflow-y': 'clip',
        '--pc-box-padding-block-start-xs': 'var(--p-space-400)',
        '--pc-box-padding-block-end-xs': 'var(--p-space-400)',
        '--pc-box-padding-inline-start-xs': 'var(--p-space-400)',
        '--pc-box-padding-inline-end-xs': 'var(--p-space-400)',
        height: '100%',
        border: '1px solid rgb(229, 231, 235)',
        borderRadius: 'var(--p-space-400)',
      }}
    >
      <BlockStack gap={'600'}>
        <BlockStack gap={'100'}>
          <Text variant="headingMd">{t('DescriptionCard.title')}</Text>
          <Text variant="bodyMd">{t('DescriptionCard.description')}</Text>
        </BlockStack>
        <InlineGrid columns={'1fr auto'} gap={'200'}>
          {options.map((option, index) => (
            <Fragment key={index}>
              <InlineStack gap={'200'}>
                <Link url={option.url} monochrome>
                  <Text>{option.label}</Text>
                </Link>
                {option.icon}
              </InlineStack>
              <Link removeUnderline url={option.url} monochrome>
                <Text alignment="end">{option.value}</Text>
              </Link>
            </Fragment>
          ))}
        </InlineGrid>
      </BlockStack>
    </div>
  )
}
