import {
  BlockStack,
  Card,
  FormLayout,
  InlineStack,
  Text
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

import * as WidgetsList from '../../../../constants/widgets'

export default function AboutSection({
  form,
  page = 'create'
}) {
  const activeWidget = Object.values(WidgetsList)
    .map((widget) => ({
      value: widget.value,
      title: widget.label,
      subtitle: widget.description,
      icon: widget.icon,
    }))
    .filter((widget) => widget.value === form.fields.widgetType.value)[0]

  return (
    <Card id="details">
      <FormLayout>
        <RecommendationTypeCard
          {...activeWidget}
          pageName={page}
          checked={true}
          onClick={() => {}}
        />
      </FormLayout>
    </Card>
  )
}

const RecommendationTypeCard = ({
  title,
  pageName,
  subtitle,
  icon,
}) => {
  const { t } = useTranslation()
  return (
    <BlockStack gap='200'>
      <InlineStack gap={'200'} blockAlign='center'>
        <img style={{paddingBottom:'1px'}} src={icon} width={25} height={25} alt="icon" />
        <Text as="h6" fontWeight="semibold">
          {title}
        </Text>
      </InlineStack>
      <Text as="p">{subtitle}</Text>
      <InlineStack align='space-between'>
        <Text as="p" fontWeight='medium'>{'Page'}</Text>
        {t(`Pages.${pageName}.label`)}
      </InlineStack>
    </BlockStack>
  )
}