import { BrowserRouter, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { NavigationMenu } from '@shopify/app-bridge-react'
import { useEffect } from 'react'
import { TrackUserStore, TrackPerformanceMetrics } from './components/MixPanelAnalytics'

import { AppBridgeProvider, QueryProvider, PolarisProvider } from './components'
import Application from './container/Application'
import analytics, { MixPanelAnalytics } from './utils/analytics'

export default function App() {
  // Any .tsx or .jsx files in /pages will become a route
  // See documentation for <Routes /> for more info
  const { t } = useTranslation()
  const pages = import.meta.globEager('./pages/**/!(*.test.[jt]sx)*.([jt]sx)')

  const ScrollToTop = () => {
    const location = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);
    return null;
  }

  return (
    <PolarisProvider>
      <BrowserRouter>
      <ScrollToTop />
        <AppBridgeProvider>
          <QueryProvider>
            <TrackUserStore/>
            <TrackPerformanceMetrics />
            <NavigationMenu
              navigationLinks={[
                {
                  label: t('Links.sections'),
                  destination: '/sections',
                },
                {
                  label: t('Links.checkoutUpsell'),
                  destination: '/checkout-upsell',
                },
                {
                  label: t('Links.bundles'),
                  destination: '/bundles',
                },
                {
                  label: t('Links.analytics'),
                  destination: '/analytics/store-analytics',
                },
                {
                  label: t('Links.settings'),
                  destination: '/settings',
                },
                {
                  label: t('Links.pricing'),
                  destination: '/pricing',
                },
              ]}
              matcher={(link, location) => {
                const first = link.destination.split('/')[1]
                const pathName = location.pathname.split('/')[1]
                return first === pathName
              }}
            />
            <Application />
          </QueryProvider>
        </AppBridgeProvider>
      </BrowserRouter>
    </PolarisProvider>
  )
}
