import {
  BlockStack,
  ColorPicker,
  Text,
  hsbToHex,
  hexToRgb,
  rgbToHsb,
  Box,
  InlineStack,
  TextField,
  Popover,
  InlineGrid,
} from '@shopify/polaris'
import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export default function InputColor({
  label,
  value='',
  onChange,
  helpText,
  error
}) {
  const [popoverActive, setPopoverActive] = useState(false)
  const {t} = useTranslation()
  function isValidColor(color = '') {
    // Create a temporary div element to test if the color is valid
    const testElement = document.createElement('div')
    testElement.style.color = color

    // If the color was successfully set, it's valid
    return testElement.style.color !== ''
  }
  return (
    <Popover
      activator={
        <>
          <InlineGrid gap="200" columns={'1fr auto'}>
            <TextField
              label={label}
              value={value}
              onChange={onChange}
              helpText={helpText}
              onFocus={() => setPopoverActive(true)}
              error={error ? error : !isValidColor(value) ? t('invalidColor') : undefined}
            />
            <div
              onClick={() => setPopoverActive(true)}
              style={{
                height: '2rem',
                width: '2rem',
                borderRadius: '100%',
                border: '1px solid',
                borderColor: 'black',
                backgroundColor: value,
                cursor: 'pointer',
                position: 'relative',
                transform: 'translateY(23px)',
              }}
            ></div>
          </InlineGrid>
        </>
      }
      active={popoverActive}
      autofocusTarget="first-node"
      onClose={() => setPopoverActive(false)}
    >
      <ColorPicker
        onChange={(val) => onChange(hsbToHex(val))}
        color={rgbToHsb(hexToRgb(value))}
      />
    </Popover>
  )
}
