import { PAGE_TYPES} from '../../constants/page'
import { BOUGHT_TOGETHER } from '../../constants/widgets/index'

const createBreakpointSchema = (breakpoint) => ({
  gutter: {
    type: 'number',
    title: { key: 'visualEditorV3.form.spacing', args: { breakpoint } },
    helpText: { key: 'visualEditorV3.form.spacingHelp' },
    path: `template.settings.breakpoints.${breakpoint}.gutter`,
    validation: {
      required: true,
      min: 0,
      max: 100,
    },
    suffix: 'px',
    defaultValue: breakpoint === 'small' ? 10 : 20,
  },
  cardsPerView: {
    type: 'number',
    title: { key: 'visualEditorV3.form.cardsPerView' },
    helpText: {
      key: 'visualEditorV3.form.cardsPerViewHelp',
      args: { breakpoint },
    },
    path: `template.settings.breakpoints.${breakpoint}.cardsPerView`,
    validation: {
      required: true,
      min: 1,
      max: 8,
    },
    suffix: '',
    defaultValue: breakpoint === 'small' ? 2 : breakpoint === 'medium' ? 3 : 4,
  },
  widgetTitleAlignment: {
    type: 'select',
    title: { key: 'visualEditorV3.form.widgetTitleAlignment' },
    options: [
      { label: { key: 'visualEditorV3.form.alignment.left' }, value: 'left' },
      {
        label: { key: 'visualEditorV3.form.alignment.center' },
        value: 'center',
      },
      { label: { key: 'visualEditorV3.form.alignment.right' }, value: 'end' },
    ],
    path: `template.settings.breakpoints.${breakpoint}.widgetTitleAlignment`,
    validation: {
      required: true,
    },
    suffix: '',
    defaultValue: breakpoint === 'small' ? 'left' : 'center',
  },
  productTitleAlignment: {
    type: 'select',
    title: { key: 'visualEditorV3.form.productTitleAlignment' },
    options: [
      { label: { key: 'visualEditorV3.form.alignment.left' }, value: 'left' },
      {
        label: { key: 'visualEditorV3.form.alignment.center' },
        value: 'center',
      },
      { label: { key: 'visualEditorV3.form.alignment.right' }, value: 'end' },
    ],
    path: `template.settings.breakpoints.${breakpoint}.productTitleAlignment`,
    validation: {
      required: true,
    },
    suffix: '',
    defaultValue: breakpoint === 'small' ? 'left' : 'center',
  },
  comparePriceVisible: {
    type: 'boolean',
    title: { key: 'visualEditorV3.form.showComparePrice' },
    path: `template.settings.breakpoints.${breakpoint}.comparePriceVisible`,
    defaultValue: breakpoint !== 'small',
  },
  imageWidth: {
    type: 'number',
    title: { key: 'visualEditorV3.form.imageWidth' },
    path: `template.settings.breakpoints.${breakpoint}.imageWidth`,
    validation: {
      required: true,
      min: 50,
      max: 1000,
    },
    suffix: 'px',
    defaultValue: 300,
  },
  imageHeight: {
    type: 'number',
    title: { key: 'visualEditorV3.form.imageHeight' },
    path: `template.settings.breakpoints.${breakpoint}.imageHeight`,
    validation: {
      required: true,
      min: 50,
      max: 1000,
    },
    suffix: 'px',
    defaultValue: 300,
  },
  justifyWidgetSubtitle: {
    type: 'select',
    title: { key: 'visualEditorV3.form.widgetSubtitleAlignment' },
    options: [
      { label: { key: 'visualEditorV3.form.alignment.left' }, value: 'left' },
      {
        label: { key: 'visualEditorV3.form.alignment.center' },
        value: 'center',
      },
      { label: { key: 'visualEditorV3.form.alignment.right' }, value: 'end' },
    ],
    path: `template.settings.breakpoints.${breakpoint}.justifyWidgetSubtitle`,
    validation: {
      required: true,
    },
    suffix: '',
    defaultValue: breakpoint === 'small' ? 'left' : 'center',
  },
})

const formFields = {
  layout: {
    type: 'select',
    title: { key: 'visualEditorV3.form.layout' },
    section: 'layout',
    options: [
      {
        label: { key: 'visualEditorV3.form.layouts.amazonBoughtTogether' },
        value: 'amazon_bought_together',
      },
      {
        label: { key: 'visualEditorV3.form.layouts.carousel' },
        value: 'carousel',
      },
      {
        label: { key: 'visualEditorV3.form.layouts.horizontalGrid' },
        value: 'horizontal_grid',
      },
    ],
    validation: {
      required: true,
    },
    suffix: '',
    path: 'section.layout',
    optionFilter: (options, { section, page })=>{
      let copy = [...options]
      if(page.type !== PAGE_TYPES.product_page.value || section.type !== BOUGHT_TOGETHER.value){
        copy = copy.filter((option) => option.value !== 'amazon_bought_together')
      }
      return copy
    },
    defaultValue: 'carousel',
  },
  title: {
    type: 'text',
    title: { key: 'visualEditorV3.form.title' },
    path: 'section.title',
    validation: {
      required: true,
    },
    suffix: '',
    defaultValue: 'Recommended Products',
  },

  maxContent: {
    type: 'number',
    title: { key: 'visualEditorV3.form.maxContent' },
    path: 'section.maxContent',
    validation: {
      required: true,
      min: 2,
      max: 1000,
    },
    suffix: '',
    defaultValue: 12,
  },
  breakpoints: {
    small: createBreakpointSchema('small'),
    medium: createBreakpointSchema('medium'),
    large: createBreakpointSchema('large'),
  },

  outOfStock: {
    textColor: {
      type: 'color',
      title: { key: 'visualEditorV3.form.outOfStock.textColor' },
      path: 'template.settings.outOfStock.textColor',
      validation: {
        required: true,
        color: true,
      },
      suffix: '',
    },
    backgroundColor: {
      type: 'color',
      title: { key: 'visualEditorV3.form.outOfStock.backgroundColor' },
      path: 'template.settings.outOfStock.backgroundColor',
      validation: {
        required: true,
        color: true,
      },
      suffix: '',
    },
  },

  carousel: {
    library: {
      type: 'select',
      title: { key: 'visualEditorV3.form.carousel.library' },
      path: 'template.settings.carousel.library',
      options: [
        {
          label: { key: 'visualEditorV3.form.carousel.libraries.swiper' },
          value: 'swiper',
        },
      ],
      validation: {
        required: true,
      },
      suffix: '',
      defaultValue: 'swiper',
    },
    showPagination: {
      type: 'boolean',
      title: { key: 'visualEditorV3.form.carousel.showPagination' },
      path: 'template.settings.carousel.showPagination',
      defaultValue: true,
    },
    suffix: '',
  },

  productCard: {
    color: {
      type: 'color',
      title: { key: 'visualEditorV3.form.productCard.textColor' },
      path: 'template.settings.productCard.color',
      validation: {
        required: true,
        color: true,
      },
      suffix: '',
      defaultValue: '#222',
    },
    priceColor: {
      type: 'color',
      title: { key: 'visualEditorV3.form.productCard.priceColor' },
      path: 'template.settings.productCard.priceColor',
      validation: {
        required: true,
        color: true,
      },
      suffix: '',
      defaultValue: '#222',
    },
    titleColor: {
      type: 'color',
      title: { key: 'visualEditorV3.form.productCard.titleColor' },
      path: 'template.settings.productCard.titleColor',
      validation: {
        required: true,
        color: true,
      },
      suffix: '',
      defaultValue: '#222',
    },
    vendorPosition: {
      type: 'select',
      title: { key: 'visualEditorV3.form.productCard.vendorPosition' },
      path: 'template.settings.productCard.vendorPosition',
      options: [
        {
          label: {
            key: 'visualEditorV3.form.productCard.vendorPositions.hidden',
          },
          value: 'hidden',
        },
        {
          label: {
            key: 'visualEditorV3.form.productCard.vendorPositions.above',
          },
          value: 'above',
        },
        {
          label: {
            key: 'visualEditorV3.form.productCard.vendorPositions.below',
          },
          value: 'below',
        },
      ],
      validation: {
        required: true,
      },
      suffix: '',
      defaultValue: 'hidden',
    },
    addToCartMode: {
      type: 'select',
      title: { key: 'visualEditorV3.form.productCard.addToCartMode' },
      path: 'template.settings.productCard.addToCartMode',
      options: [
        {
          label: {
            key: 'visualEditorV3.form.productCard.addToCartModes.cardHover',
          },
          value: 'card_hover',
        },
        {
          label: {
            key: 'visualEditorV3.form.productCard.addToCartModes.imageHover',
          },
          value: 'image_hover',
        },
        {
          label: {
            key: 'visualEditorV3.form.productCard.addToCartModes.fixed',
          },
          value: 'fixed',
        },
      ],
      validation: {
        required: true,
      },
      suffix: '',
      defaultValue: 'card_hover',
    },
    backgroundColor: {
      type: 'color',
      title: { key: 'visualEditorV3.form.productCard.backgroundColor' },
      path: 'template.settings.productCard.backgroundColor',
      validation: {
        required: true,
        color: true,
      },
      suffix: '',
      defaultValue: 'transparent',
    },
    imageHoverMode: {
      type: 'select',
      title: { key: 'visualEditorV3.form.productCard.imageHoverMode' },
      path: 'template.settings.productCard.imageHoverMode',
      options: [
        {
          label: {
            key: 'visualEditorV3.form.productCard.imageHoverModes.secondary',
          },
          value: 'secondary',
        },
        {
          label: {
            key: 'visualEditorV3.form.productCard.imageHoverModes.zoom',
          },
          value: 'zoom',
        },
        {
          label: {
            key: 'visualEditorV3.form.productCard.imageHoverModes.none',
          },
          value: 'none',
        },
      ],
      validation: {
        required: true,
      },
      suffix: '',
      defaultValue: 'secondary',
    },
    imageObjectFit: {
      type: 'select',
      title: { key: 'visualEditorV3.form.productCard.imageFit' },
      path: 'template.settings.productCard.imageObjectFit',
      options: [
        {
          label: { key: 'visualEditorV3.form.productCard.imageFits.contain' },
          value: 'contain',
        },
        {
          label: { key: 'visualEditorV3.form.productCard.imageFits.cover' },
          value: 'cover',
        },
      ],
      validation: {
        required: true,
      },
      suffix: '',
      defaultValue: 'contain',
    },
    discountColor: {
      type: 'color',
      title: { key: 'visualEditorV3.form.productCard.discountColor' },
      path: 'template.settings.productCard.discountColor',
      validation: {
        required: true,
        color: true,
      },
      suffix: '',
      defaultValue: '#000',
    },
    discountVisible: {
      type: 'boolean',
      title: { key: 'visualEditorV3.form.productCard.discountVisible' },
      path: 'template.settings.productCard.discountVisible',
      defaultValue: true,
    },
    addToCartColor: {
      type: 'color',
      title: { key: 'visualEditorV3.form.productCard.addToCartColor' },
      path: 'template.settings.productCard.addToCartColor',
      validation: {
        required: true,
        color: true,
      },
      suffix: '',
      defaultValue: '#fff',
    },
    imageAspectRatio: {
      type: 'text',
      title: { key: 'visualEditorV3.form.productCard.imageAspectRatio' },
      path: 'template.settings.productCard.imageAspectRatio',
      validation: {
        required: true,
        imageAspectRatio: true,
      },
      suffix: '',
      defaultValue: '1:1',
    },
    showDiscountPill: {
      type: 'boolean',
      title: { key: 'visualEditorV3.form.productCard.showDiscountPill' },
      path: 'template.settings.productCard.showDiscountPill',
      defaultValue: true,
    },
    discountBackground: {
      type: 'color',
      title: { key: 'visualEditorV3.form.productCard.discountBackground' },
      path: 'template.settings.productCard.discountBackground',
      validation: {
        required: true,
        color: true,
      },
      defaultValue: 'transparent',
    },
    priceColorCompare: {
      type: 'color',
      title: { key: 'visualEditorV3.form.productCard.priceColorCompare' },
      path: 'template.settings.productCard.priceColorCompare',
      validation: {
        required: true,
        color: true,
      },
      defaultValue: '#aaa',
    },
    discountLabelColor: {
      type: 'color',
      title: { key: 'visualEditorV3.form.productCard.discountLabelColor' },
      path: 'template.settings.productCard.discountLabelColor',
      validation: {
        required: true,
        color: true,
      },
      defaultValue: '#ffffff',
    },
    priceColorOriginal: {
      type: 'color',
      title: { key: 'visualEditorV3.form.productCard.priceColorOriginal' },
      path: 'template.settings.productCard.priceColorOriginal',
      validation: {
        required: true,
        color: true,
      },
      defaultValue: '#5C5C5C',
    },
    priceCompareAtMode: {
      type: 'select',
      title: { key: 'visualEditorV3.form.productCard.priceCompareAtMode' },
      path: 'template.settings.productCard.priceCompareAtMode',
      options: [
        {
          label: {
            key: 'visualEditorV3.form.productCard.priceCompareAtModes.before',
          },
          value: 'before',
        },
        {
          label: {
            key: 'visualEditorV3.form.productCard.priceCompareAtModes.after',
          },
          value: 'after',
        },
      ],
      validation: {
        required: true,
      },
      defaultValue: 'before',
    },
    addToCartBackground: {
      type: 'color',
      title: { key: 'visualEditorV3.form.productCard.addToCartBackground' },
      path: 'template.settings.productCard.addToCartBackground',
      validation: {
        required: true,
        color: true,
      },
      defaultValue: '#000',
    },
    showQuantitySelector: {
      type: 'boolean',
      title: { key: 'visualEditorV3.form.productCard.showQuantitySelector' },
      path: 'template.settings.productCard.showQuantitySelector',
      defaultValue: false,
    },
    discountLabelVisible: {
      type: 'boolean',
      title: { key: 'visualEditorV3.form.productCard.discountLabelVisible' },
      path: 'template.settings.productCard.discountLabelVisible',
      defaultValue: true,
    },
    discountLabelBackground: {
      type: 'color',
      title: { key: 'visualEditorV3.form.productCard.discountLabelBackground' },
      path: 'template.settings.productCard.discountLabelBackground',
      validation: {
        required: true,
        color: true,
      },
      defaultValue: '#ff0000',
    },
    redirectAfterAddToCart: {
      type: 'boolean',
      title: { key: 'visualEditorV3.form.productCard.redirectAfterAddToCart' },
      path: 'template.settings.productCard.redirectAfterAddToCart',
      defaultValue: true,
    },
    variantSelectorType: {
      type: 'select',
      title: {
        key: 'visualEditorV3.form.productCard.variantSelectorType.title',
      },
      path: 'template.settings.productCard.variantSelectorType',
      options: [
        {
          label: {
            key: 'visualEditorV3.form.productCard.variantSelectorTypes.integrated',
          },
          value: 'integrated',
        },
        {
          label: {
            key: 'visualEditorV3.form.productCard.variantSelectorTypes.swatchWithAtc',
          },
          value: 'swatch_with_atc',
        },
        {
          label: {
            key: 'visualEditorV3.form.productCard.variantSelectorTypes.swatch',
          },
          value: 'swatch',
        },
        {
          label: {
            key: 'visualEditorV3.form.productCard.variantSelectorTypes.selector',
          },
          value: 'selector',
        },
      ],
      validation: {
        required: true,
      },
      defaultValue: 'integrated',
    },
    disabledAddToCartBackground: {
      type: 'color',
      title: {
        key: 'visualEditorV3.form.productCard.disabledAddToCartBackground',
      },
      path: 'template.settings.productCard.disabledAddToCartBackground',
      validation: {
        required: true,
        color: true,
      },
      defaultValue: '#00000070',
    },
    disableAddToCartButton: {
      type: 'boolean',
      title: { key: 'visualEditorV3.form.productCard.disableAddToCartButton' },
      path: 'template.settings.productCard.disableAddToCartButton',
      defaultValue: false,
    },
    atcErrorText: {
      type: 'text',
      title: {
        key: 'visualEditorV3.form.productCard.atcErrorText',
      },
      path: 'template.translations.primaryLocale.atcErrorText',
      validation: {
        required: true,
      },
      defaultValue: 'Error adding to cart',
    },
    atcText: {
      type: 'text',
      title: {
        key: 'visualEditorV3.form.productCard.atcText',
      },
      path: 'template.translations.primaryLocale.atcText',
      validation: {
        required: true,
      },
      defaultValue: 'Add to Cart',
    },
    addedToCartText: {
      type: 'text',
      title: {
        key: 'visualEditorV3.form.productCard.addedToCartText',
      },
      path: 'template.translations.primaryLocale.addedToCartText',
      validation: {
        required: true,
      },
      defaultValue: 'Added to Cart',
    },
  },

  amazonBoughtTogether: {
    priceCompareAtMode: {
      type: 'select',
      title: {
        key: 'visualEditorV3.form.amazonBoughtTogether.priceCompareAtMode',
      },
      path: 'template.settings.amazonBoughtTogether.priceCompareAtMode',
      options: [{ label: 'Before', value: 'before' }],
      validation: {
        required: true,
      },
      defaultValue: 'before',
    },
    buttonBackgroundColor: {
      type: 'color',
      title: {
        key: 'visualEditorV3.form.amazonBoughtTogether.buttonBackgroundColor',
      },
      path: 'template.settings.amazonBoughtTogether.buttonBackgroundColor',
      validation: {
        required: true,
        color: true,
      },
      defaultValue: '#000',
    },
    addToCartText: {
      type: 'text',
      title: {
        key: 'visualEditorV3.form.amazonBoughtTogether.addToCartText',
      },
      path: 'template.translations.primaryLocale.fbtAddToCartText',
      validation: {
        required: true,
      },
      defaultValue: 'Add to Cart',
    },
    addedToCartText: {
      type: 'text',
      title: {
        key: 'visualEditorV3.form.amazonBoughtTogether.addedToCartText',
      },
      path: 'template.translations.primaryLocale.fbtAddedToCartText',
      validation: {
        required: true,
      },
      defaultValue: 'Added to Cart',
    },
    saveText: {
      type: 'text',
      title: {
        key: 'visualEditorV3.form.amazonBoughtTogether.saveText',
      },
      path: 'template.translations.primaryLocale.fbtSaveText',
      validation: {
        required: true,
      },
      defaultValue: 'Save',
    },
    totalPriceText: {
      type: 'text',
      title: {
        key: 'visualEditorV3.form.amazonBoughtTogether.totalPriceText',
      },
      path: 'template.translations.primaryLocale.totalPriceText',
      defaultValue: 'Total Price',
    },
  },
}

const formSchema = {
  options: [
    {
      title: {
        key: 'visualEditorV3.sectionSettings.title',
      },
      fields: [
        [formFields.title],
        [formFields.maxContent],
        [formFields.layout],
        [
          {
            id: 'breakpointsSettings',
            title: {
              key: 'visualEditorV3.sectionSettings.breakpoints.title',
            },
            fields: [
              {
                id: 'smallBreakpoint',
                title: {
                  key: 'visualEditorV3.sectionSettings.breakpoints.small',
                },
                fields: [
                  [formFields.breakpoints.small.widgetTitleAlignment],
                  [formFields.breakpoints.small.gutter],
                  [formFields.breakpoints.small.cardsPerView],
                ],
              },
              {
                id: 'mediumBreakpoint',
                title: {
                  key: 'visualEditorV3.sectionSettings.breakpoints.medium',
                },
                fields: [
                  [formFields.breakpoints.medium.widgetTitleAlignment],
                  [formFields.breakpoints.medium.gutter],
                  [formFields.breakpoints.medium.cardsPerView],
                ],
              },
              {
                id: 'largeBreakpoint',
                title: {
                  key: 'visualEditorV3.sectionSettings.breakpoints.large',
                },
                fields: [
                  [formFields.breakpoints.large.widgetTitleAlignment],
                  [formFields.breakpoints.large.gutter],
                  [formFields.breakpoints.large.cardsPerView],
                ],
              },
            ],
          },
        ],
      ],
      show: ({ section, template }) =>
        section.layout === 'carousel' ||
        section.layout === 'horizontal_grid' ||
        section.layout === 'amazon_bought_together',
      id: 'sectionSettings',
    },
    {
      title: {
        key: 'visualEditorV3.imageSettings.title',
      },
      fields: [
        [formFields.productCard.imageAspectRatio],
        [formFields.productCard.imageHoverMode],
        [formFields.productCard.imageObjectFit],
        [
          {
            id: 'imageBreakpointsSettings',
            title: {
              key: 'visualEditorV3.imageSettings.breakpoints.title',
            },
            fields: [
              {
                id: 'smallImageBreakpoint',
                title: {
                  key: 'visualEditorV3.imageSettings.breakpoints.small',
                },
                fields: [
                  [
                    formFields.breakpoints.small.imageWidth,
                    formFields.breakpoints.small.imageHeight,
                  ],
                ],
              },
              {
                id: 'mediumImageBreakpoint',
                title: {
                  key: 'visualEditorV3.imageSettings.breakpoints.medium',
                },
                fields: [
                  [
                    formFields.breakpoints.medium.imageWidth,
                    formFields.breakpoints.medium.imageHeight,
                  ],
                ],
              },
              {
                id: 'largeImageBreakpoint',
                title: {
                  key: 'visualEditorV3.imageSettings.breakpoints.large',
                },
                fields: [
                  [
                    formFields.breakpoints.large.imageWidth,
                    formFields.breakpoints.large.imageHeight,
                  ],
                ],
              },
            ],
          },
        ],
      ],
      show: ({ section, template }) =>
        section.layout === 'carousel' ||
        section.layout === 'horizontal_grid' ||
        section.layout === 'amazon_bought_together',
      id: 'imageSettings',
    },
    {
      title: {
        key: 'visualEditorV3.form.productCard.title',
      },
      fields: [
        [formFields.productCard.backgroundColor],
        [formFields.productCard.color],
        [formFields.productCard.showDiscountPill],
        [formFields.productCard.showQuantitySelector],
        [formFields.productCard.titleColor],
        [formFields.productCard.vendorPosition],
        [formFields.productCard.variantSelectorType],
        {
          id: 'atcSettings',
          title: {
            key: 'visualEditorV3.form.productCard.atc.title',
          },
          fields: [
            [formFields.productCard.addToCartBackground],
            [formFields.productCard.addToCartColor],
            [formFields.productCard.disableAddToCartButton],
            [formFields.productCard.disabledAddToCartBackground],
          ],
          show: ({ section, template }) =>
            section.layout === 'carousel' ||
            section.layout === 'horizontal_grid',
        },
        {
          id: 'texts',
          title: {
            key: 'visualEditorV3.form.productCard.texts.title',
          },
          fields: [
            [formFields.productCard.atcText],
            [formFields.productCard.addedToCartText],
            [formFields.productCard.atcErrorText],
          ],
        },
      ],
      show: ({ section, template }) =>
        section.layout === 'carousel' ||
        section.layout === 'horizontal_grid' ||
        section.layout === 'amazon_bought_together',
      id: 'productCard',
    },
    {
      id: 'priceSettings',
      title: {
        key: 'visualEditorV3.form.productCard.price.title',
      },
      fields: [
        [formFields.productCard.priceColorCompare],
        [formFields.productCard.priceColorOriginal],
        [formFields.productCard.priceCompareAtMode],
        {
          id: 'priceBreakpointsSettings',
          title: {
            key: 'visualEditorV3.form.productCard.price.breakpoints.title',
          },
          fields: [
            {
              id: 'smallPriceBreakpoint',
              title: {
                key: 'visualEditorV3.form.productCard.price.breakpoints.small',
              },
              fields: [[formFields.breakpoints.small.comparePriceVisible]],
            },
            {
              id: 'mediumPriceBreakpoint',
              title: {
                key: 'visualEditorV3.form.productCard.price.breakpoints.medium',
              },
              fields: [[formFields.breakpoints.medium.comparePriceVisible]],
            },
            {
              id: 'largePriceBreakpoint',
              title: {
                key: 'visualEditorV3.form.productCard.price.breakpoints.large',
              },
              fields: [[formFields.breakpoints.large.comparePriceVisible]],
            },
          ],
        },
      ],
    },
    {
      id: 'discountSettings',
      title: {
        key: 'visualEditorV3.form.productCard.discount.title',
      },
      fields: [
        [formFields.productCard.discountColor],
        [formFields.productCard.discountBackground],
        [formFields.productCard.discountLabelColor],
        [formFields.productCard.discountLabelVisible],
      ],
    },
    {
      title: {
        key: 'visualEditorV3.carouselSettings.title',
      },
      fields: [[formFields.carousel.showPagination]],
      show: ({ section, template }) => section.layout === 'carousel',
      id: 'carousel',
    },
    {
      title: {
        key: 'visualEditorV3.amazonBoughtTogether.title',
      },
      fields: [
        [formFields.amazonBoughtTogether.buttonBackgroundColor],
        [formFields.amazonBoughtTogether.addToCartText],
        [formFields.amazonBoughtTogether.addedToCartText],
        [formFields.amazonBoughtTogether.saveText],
        [formFields.amazonBoughtTogether.totalPriceText],
      ],
      show: ({ section, template }) =>
        section.layout === 'amazon_bought_together',
      id: 'amazonBoughtTogether',
    },
  ],
}

export default formSchema