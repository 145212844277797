import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { getGlobalRecommendations } from '../apis/recommendation'

export const useGlobalRecommendations = (widgetType) => {
  const { data, isLoading, error } = useQuery(
    ['globalRecommendations', { widgetType }],
    async () => {
      const res = await getGlobalRecommendations(widgetType)
      if (res.error) {
        return []
      }
      return res.data || []
    }
  )

  const globalRecommendationsCount = useMemo(() => {
    if (!data) return 0
    return data.length
  }, [data])

  const hasGlobalRecommendations = globalRecommendationsCount > 0

  return {
    count: globalRecommendationsCount,
    hasGlobalRecommendations,
    isLoading,
    error,
    recommendations: data
  }
}