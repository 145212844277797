import { useState } from 'react'
import {
  Button,
  Card,
  IndexTable,
  Page,
  Avatar,
  Text,
  Badge,
  InlineStack,
  Popover,
  Scrollable,
  Divider,
  Box,
  InlineGrid,
  BlockStack,
  Link,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { LockIcon, PlusIcon, SettingsIcon } from '@shopify/polaris-icons'

import { useRedirect } from '../../../hooks'
import { useShopDetails } from '../../../hooks/useShopDetails'
import { areFeaturesEnabled } from '../../../utils/features'
import { FEATURES } from '../../../constants/features'
import { PricingModal, usePricing } from '../../../components/PricingModal'
import { useTemplates } from '../../../hooks/useTemplates'

const PAGE_SIZE = 10

function SectionComponent({
  sectionName,
  sectionId,
  style = {},
  page,
  sectionType,
  showExtraInfo = false,
}) {
  const { t } = useTranslation()
  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <Box padding={'300'}>
        <InlineGrid columns={'auto 1fr'} gap={'200'} alignItems="start">
          <Box>
            <Avatar customer size="lg" />
          </Box>
          <Box>
            <Link monochrome removeUnderline url={`/sections/${sectionId}`}>
              <Text variant="headingSm">{sectionName}</Text>
            </Link>
            {showExtraInfo ? (
              <Text variant="bodySm">
                ID: {sectionId} | {t('DefaultText.page')}:{' '}
                {t(`Pages.${page.type}.label`)} |{' '}
                {t('DefaultText.recommendationType')}:{' '}
                {t(`Widgets.${sectionType}.label`)}
              </Text>
            ) : (
              <Text variant="bodySm">ID: {sectionId}</Text>
            )}
          </Box>
        </InlineGrid>
      </Box>
    </div>
  )
}

function SectionPopOver({ sections, pages }) {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div
      style={{
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      id="activator"
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <Popover
        activator={<Badge size="small">{`+${sections.length - 2}`}</Badge>}
        active={isOpen}
        preferredAlignment="right"
      >
        <Popover.Pane fixed>
          <Scrollable
            shadow
            style={{
              position: 'relative',
              width: '400px',
              maxHeight: '350px',
              padding: 'var(--p-space-200) var(--p-space-200) ',
              borderBottomLeftRadius: 'var(--p-border-radius-200)',
              borderBottomRightRadius: 'var(--p-border-radius-200)',
            }}
          >
            {sections.map((section, index) => (
              <>
                <SectionComponent
                  sectionId={section.id}
                  sectionName={section.title}
                  style={{ padding: 'var(--p-space-200)' }}
                  page={pages.find((p) => p.id == section.page)}
                  sectionType={section.type}
                  showExtraInfo
                />
                {index != sections.length - 1 && <Divider />}
              </>
            ))}
          </Scrollable>
        </Popover.Pane>
      </Popover>
    </div>
  )
}

function Templates() {
  const { templates, templateLoading } = useTemplates()
  const { data: { sections = [], pages = [] } = {}, isLoading: shopLoading } =
    useShopDetails()
  const featureEnabled = areFeaturesEnabled(
    [FEATURES.CUSTOM_TEMPLATES],
    window.shopify.data,
  )
  const pricing = usePricing()
  const { t } = useTranslation()
  const { redirectToLink } = useRedirect()
  const [pageNumber, setPageNumber] = useState(1)

  const resourceName = {
    singular: 'template',
    plural: 'templates',
  }

  const totalPages = Math.ceil(templates.length / PAGE_SIZE)
  const startIndex = (pageNumber - 1) * PAGE_SIZE
  const endIndex = Math.min(startIndex + PAGE_SIZE, templates.length) + 1

  const visibleTemplates = templates
    .slice(startIndex, endIndex - 1)
    .map((template) => {
      template.sections = sections.filter(
        (section) => template.version == 3 ? section.templateV3 == template.id : section.template === template.id,
      )
      return template
    })

  const isLoading = templateLoading || shopLoading

  return (
    <Page
      title={t('Templates.Details.title')}
      subtitle={t('Templates.Details.subTitle')}
      backAction={{
        content: 'back',
        url: '/settings',
      }}
      primaryAction={
        <Button
          icon={featureEnabled ? PlusIcon : LockIcon}
          variant="primary"
          url={featureEnabled && '/settings/templates/create'}
          onClick={() => {
            if (!featureEnabled) {
              pricing.open({
                features: [FEATURES.CUSTOM_TEMPLATES],
              })
            }
          }}
        >
          Create template
        </Button>
      }
    >
      <Card padding="0">
        <IndexTable
          loading={isLoading}
          resourceName={resourceName}
          itemCount={endIndex - startIndex - 1}
          headings={[
            { title: t('Templates.Details.heading.template') },
            { title: t('Templates.Details.heading.type') },
            { title: t('Templates.Details.heading.sections') },
            { title: t('Templates.Details.heading.version') },
          ]}
          pagination={{
            hasNext: pageNumber * PAGE_SIZE < templates.length,
            onNext: () => setPageNumber(pageNumber + 1),
            hasPrevious: pageNumber > 1,
            onPrevious: () => setPageNumber(pageNumber - 1),
            label: `${pageNumber} of ${totalPages}`,
          }}
          selectable={false}
          selectedItemsCount={0}
          bulkActions={[]}
          promotedBulkActions={[]}
        >
          {visibleTemplates.map(
            ({ id, name, type, sections, version }, index) => {
              return (
                <IndexTable.Row
                  key={id}
                  position={index}
                  onClick={() => {
                    redirectToLink({
                      url: `/settings/templates/${id}/configure`,
                    })
                  }}
                >
                  <IndexTable.Cell>
                    <Text fontWeight="bold">{name}</Text>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    {t(`Templates.type.${type}`)}
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <div
                      style={{
                        width: 'max-content',
                      }}
                    >
                      <InlineStack gap="400" align="center">
                        {sections.length > 0 ? (
                          <SectionComponent
                            sectionId={sections[0].id}
                            sectionName={sections[0].title}
                          />
                        ) : (
                          <Text>NA</Text>
                        )}
                        {sections.length > 1 && (
                          <SectionComponent
                            sectionId={sections[1].id}
                            sectionName={sections[1].title}
                          />
                        )}
                        {sections.length > 2 && (
                          <SectionPopOver sections={sections} pages={pages} />
                        )}
                      </InlineStack>
                    </div>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    <Badge>V{version}</Badge>
                  </IndexTable.Cell>
                </IndexTable.Row>
              )
            },
          )}
        </IndexTable>
      </Card>
      <PricingModal modal={pricing} />
    </Page>
  )
}

export default Templates
