import { BlockStack, Box, Card, Checkbox, Text } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

export default function SectionPlacement({ form }) {
  const { t } = useTranslation()

  const handleChange = (checked) => {
    form.fields.extra.onChange({
      ...form.fields.extra.value,
      requireAppBlock: checked,
    })
  }

  return (
    <BlockStack gap={'050'}>
      <Text as="strong">
        {t('Section.Edit.Sections.SectionPlacement.title', {
          defaultValue: 'Section Placement',
        })}
      </Text>
      <Checkbox
        label={t('Section.Edit.Sections.SectionPlacement.requireAppBlock', {
          defaultValue: 'Only show section when placed using app block',
        })}
        helpText={t('Section.Edit.Sections.SectionPlacement.description', {
          defaultValue: 'Control how this section appears in your storefront',
        })}
        checked={form.fields.extra.value?.requireAppBlock || false}
        onChange={handleChange}
      />
    </BlockStack>
  )
}
