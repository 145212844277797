import React from 'react'
import {
  BlockStack,
  Card,
  FormLayout,
  Select,
  TextField,
  Text
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'

import * as RANKING_CRITERIA from '../../../../constants/widgets/rankingCriteria'
import * as FILTER_CRITERIA from '../../../../constants/widgets/filterCriteria'
import * as FALLBACK_CRITERIA from '../../../../constants/widgets/fallbackCriteria'
import { SIMILAR_PRODUCTS } from '../../../../constants/widgets'

function AdvanceConfig({ form, showPricePercentageThreshold }) {
  const { t } = useTranslation()

  return (
    <Card padding={'0'} id="advance">
      <BlockStack gap={'200'}>
        <div style={{ 
          borderBottom: 'var(--p-border-width-025) solid var(--p-color-border)', 
          padding: 'var(--p-space-400)'
        }}>
          <Text as="strong" variant="headingSm">
            {t('Section.Edit.Sections.AdvanceConfig.title')}
          </Text>
          <Text as="p" variant="bodyMd">
            {t('Section.Edit.Sections.AdvanceConfig.description')}
          </Text>
        </div>

        <div style={{ padding: 'var(--p-space-400)' }}>
          <FormLayout>
            <FormLayout.Group>
              {showPricePercentageThreshold && (
                <TextField
                  type="number"
                  min={0}
                  max={999}
                  label={t('Section.Edit.Sections.AdvanceConfig.FormFields.PPT.label')}
                  helpText={t('Section.Edit.Sections.AdvanceConfig.FormFields.PPT.helpText')}
                  {...form.fields.pricePercentageThreshold}
                />
              )}
              <Select
                label={t('Section.Edit.Sections.AdvanceConfig.FormFields.PRC.label')}
                helpText={t('Section.Edit.Sections.AdvanceConfig.FormFields.PRC.helpText')}
                {...form.fields.productRankingCriteria}
                options={Object.values(RANKING_CRITERIA)}
              />
            </FormLayout.Group>
          </FormLayout>

          <FormLayout>
            <FormLayout.Group>
              <Select
                label={t('Section.Edit.Sections.AdvanceConfig.FormFields.PFC.label')}
                helpText={
                  form.fields.widgetType !== SIMILAR_PRODUCTS.value 
                    ? t('Section.Edit.Sections.AdvanceConfig.FormFields.PFC.available')
                    : t('Section.Edit.Sections.AdvanceConfig.FormFields.PFC.helpText')
                }
                {...form.fields.productFilterCriteria}
                options={Object.values(FILTER_CRITERIA)}
                disabled={form.fields.widgetType !== SIMILAR_PRODUCTS.value}
              />
              <Select
                label={t('Section.Edit.Sections.AdvanceConfig.FormFields.FC.label')}
                helpText={t('Section.Edit.Sections.AdvanceConfig.FormFields.FC.helpText')}
                {...form.fields.fallbackCriteria}
                options={Object.values(FALLBACK_CRITERIA)}
              />
            </FormLayout.Group>
          </FormLayout>

          <FormLayout>
            <FormLayout.Group>
              <TextField
                type="number"
                min={0}
                max={99999}
                label={t('Section.Edit.Sections.AdvanceConfig.FormFields.MinPrice.label')}
                {...form.fields.minPrice}
              />
              <TextField
                type="number"
                min={0}
                max={99999}
                label={t('Section.Edit.Sections.AdvanceConfig.FormFields.MaxPrice.label')}
                {...form.fields.maxPrice}
              />
            </FormLayout.Group>
          </FormLayout>
        </div>
      </BlockStack>
    </Card>
  )
}

export default AdvanceConfig
