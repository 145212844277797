import { useChoiceField, useField, useForm } from '@shopify/react-form'
import { useTranslation } from 'react-i18next'
import { updateShopConfig } from '../../../../../apis/dashbboard'
import {
  BlockStack,
  Box,
  Button,
  Checkbox,
  Form,
  InlineStack,
  Label,
  Text,
} from '@shopify/polaris'

export default function Recommendation({ dashboardData }) {
  const { t } = useTranslation()
  const allowedBots = ['googlebot', 'bingbot', 'facebookbot']
  const form = useForm({
    fields: {
      allowSeoBots: useChoiceField(
        !Boolean(dashboardData.config.data?.blockSeoBots),
      ),
      allowedBots: useField(dashboardData.config.data?.allowedBots || allowedBots),
    },
    onSubmit: async (data) => {
      const payload = {
        data: {
          ...dashboardData.config.data,
          blockSeoBots: !data.allowSeoBots.checked,
          allowedBots: data.allowedBots,
        },
      }

      const { error } = await updateShopConfig(payload)

      if (error) {
        return {
          status: 'fail',
          errors: [
            {
              message: error,
            },
          ],
        }
      }
      shopify.toast.show(
        t('DefaultText.updateSuccess', {
          text: t('DefaultText.settings'),
        }),
      )
      return {
        status: 'success',
      }
    },
  })

  return (
    <Form onSubmit={form.submit}>
      <BlockStack gap={'300'}>
        <Box>
          <Text as="strong">
            {t('SettingsPage.Sections.Advanced.Section.recommendation.title')}
          </Text>
        </Box>
        <Box padding={'100'}>
          <BlockStack gap="400">
            <Checkbox
              {...form.fields.allowSeoBots}
              label={t(
                'SettingsPage.Sections.Advanced.Section.recommendation.seoBots',
              )}
            />
            <BlockStack gap="100">
              <Label>
                {t(
                  'SettingsPage.Sections.Advanced.Section.recommendation.allowedBotsLabel',
                )}
              </Label>
              <BlockStack>
                {allowedBots.map((bot) => (
                  <Checkbox
                  disabled={!form.fields.allowSeoBots.checked}
                    checked={form.fields.allowedBots.value.includes(bot)}
                    onChange={() => {
                      let bots = form.fields.allowedBots.value
                      const include = bots.includes(bot)
                      if (!include) {
                        bots.push(bot)
                      } else {
                        bots = bots.filter((b) => b !== bot)
                      }
                      form.fields.allowedBots.onChange(bots)
                    }}
                    label={t(
                      `SettingsPage.Sections.Advanced.Section.recommendation.allowedBots.${bot}`,
                    )}
                  />
                ))}
              </BlockStack>
            </BlockStack>
          </BlockStack>
        </Box>
        <InlineStack align="end">
          <Button loading={form.submitting} submit>
            {t('SettingsPage.Sections.Advanced.Section.recommendation.btnCta')}
          </Button>
        </InlineStack>
      </BlockStack>
    </Form>
  )
}
