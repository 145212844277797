import {
  Page,
  Layout,
  Card,
  Form,
  FormLayout,
  Select,
  TextField,
  Button,
  InlineStack,
  BlockStack,
  InlineGrid,
  Box,
  Text,
} from '@shopify/polaris'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useContextualSaveBar } from '@shopify/app-bridge-react'
import { notEmpty, useField, useForm } from '@shopify/react-form'

import { createTemplate, getTemplates } from '../../../apis/template'
import Loader from '../../../components/Loader'
import { useRedirect } from '../../../hooks/useRedirect'
import queryClient from '../../../utils/query'
import { TEMPLATE_TYPES } from '../../../constants/templates'
import analytics, { MixPanelAnalytics } from '../../../utils/analytics'

function CreateTemplate() {
  const { t } = useTranslation()
  const { redirectToLink } = useRedirect()
  const { data = {}, isLoading } = useQuery({
    queryFn: async () => {
      const res = await getTemplates()
      if (res.error) {
        return Promise.reject(res.error)
      }
      return res.data
    },
    queryKey: 'templateList',
  })
  const saveBar = useContextualSaveBar()
  const templates = data?.templates ?? []

  const templateNameField = useField({
    value: '',
    validates: [notEmpty('Template Name cannot be empty')],
  })

  const sourceTemplateField = useField(null)

  const templateType = useField({
    value: '',
    validates: [notEmpty('Please select an option')],
  })

  const templateVersion = useField(1)

  const createForm = useForm({
    fields: {
      templateNameField,
      sourceTemplateField,
      templateType,
      templateVersion,
    },
    onSubmit: async (data) => {
      const res = await createTemplate({
        name: data.templateNameField,
        copyTemplateId: data.sourceTemplateField,
        type: data.templateType,
        version: data.templateVersion,
      })
      if (res.error) {
        shopify.toast.show(t('Templates.createError'), {
          isError: true,
        })
      } else {
        saveBar.hide()
        analytics.trackTemplateEvent(MixPanelAnalytics.Actions.CREATE, res.data.template)
        await queryClient.invalidateQueries(['templates'])
        redirectToLink({
          url: `/settings/templates/${res.data.template.id}/configure`,
          external: false,
        })
        return {
          status: 'success',
        }
      }
    },
  })

  const options = templates
    .filter(({ type }) => type === templateType.value)
    .map(({ name, id }) => {
      return {
        label: name,
        value: id.toString(),
      }
    })

  useEffect(() => {
    createForm.fields.sourceTemplateField.onChange(null)
  }, [createForm.fields.templateType.value])

  useEffect(()=>{
    saveBar.saveAction.setOptions({
      onAction: createForm.submit,
      loading: createForm.submitting
    })
    saveBar.discardAction.setOptions({
      disabled: !createForm.dirty,
      onAction: createForm.reset
    })
  },[createForm.submitting, createForm.dirty])

  useEffect(()=>{
    if(createForm.dirty){
      saveBar.show()
    } else {
      saveBar.hide()
    }
  },[createForm.dirty])

  if (isLoading) {
    return <Loader />
  }

  return (
    <Page
      backAction={{ url: '/settings/templates' }}
      title={t('Templates.Create.title')}
      subtitle={t('Templates.Create.subtitle')}
    >
      <BlockStack gap={'300'}>
        <Card>
          <TextField
            {...createForm.fields.templateNameField}
            autoComplete="text"
            type="text"
            label={t('Templates.Create.Form.Name.title')}
            placeholder={t('Templates.Create.Form.Name.placeholder')}
          />
        </Card>
        <Card>
          <BlockStack gap={'300'}>
            <Select
              label={t('Templates.Create.Form.Type.title')}
              options={Object.values(TEMPLATE_TYPES).map((type) => ({
                label: t(`Templates.type.${type}`),
                value: type,
              }))}
              {...createForm.fields.templateType}
              placeholder={t('Templates.Create.Form.Type.placeholder')}
            />
            <InlineGrid columns={'1fr 1fr'} gap={'300'}>
              <Select
                disabled={!Boolean(createForm.fields.templateType.value)}
                onChange={(val) => {
                  createForm.fields.sourceTemplateField.onChange(parseInt(val))
                  const template = templates.find((t) => t.id == val)
                  createForm.fields.templateVersion.onChange(template.version)
                }}
                label={t('Templates.Create.Form.Source.title')}
                value={createForm.fields.sourceTemplateField.value?.toString()}
                options={options}
                placeholder={t('Templates.Create.Form.Source.placeholder')}
              />
              <Select
                disabled={
                  !createForm.fields.templateType.value ||
                  createForm.fields.sourceTemplateField.value
                }
                onChange={(val) =>
                  createForm.fields.templateVersion.onChange(parseInt(val))
                }
                label={t('Templates.Create.Form.Version.title')}
                value={createForm.fields.templateVersion.value?.toString()}
                options={[
                  {
                    label: 'V1',
                    value: '1',
                  },
                  {
                    label: 'V3',
                    value: '3',
                  },
                ]}
              />
            </InlineGrid>
          </BlockStack>
        </Card>
      </BlockStack>
    </Page>
  )
}

export default CreateTemplate
