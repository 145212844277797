import { react, useState, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Autocomplete,
  Icon,
  InlineGrid,
  InlineStack,
  Tag,
  Text,
  Button,
  Box,
  BlockStack,
} from '@shopify/polaris'
import { DeleteIcon, PlusIcon, SearchIcon } from '@shopify/polaris-icons'

const PickerComponent = ({
  tagsTitle,
  tagsDescription,
  tagOptions,
  tagBtnCta,
  selectedTags,
  setSelectedTags,
  onTagChange,
  query,
  setQuery,
  filteredTags,
  allTags,
  setAllTags,
}) => {
  const [suggestion, setSuggestion] = useState('')

  const handleChange = useCallback(
    (value) => {
      const suggestion =
        value &&
        allTags.find((suggestion) =>
          suggestion.toLowerCase().startsWith(value.toLowerCase()),
        )
      setSuggestion(suggestion)
      setQuery(value)
    },
    [filteredTags],
  )

  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === 'Enter' || event.key === 'Tab') {
        setSuggestion('')
      } else if (event.key === 'Backspace') {
        setSuggestion('')
      }
    },
    [suggestion],
  )

  const {t}  = useTranslation()

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [handleKeyDown])

  return (
    <BlockStack gap={400}>
      {tagsTitle ? (
        <Box paddingInlineStart={'400'} paddingBlockStart={'400'}>
          <Text as="strong">{tagsTitle}</Text>
          <Text>{tagsDescription}</Text>
        </Box>
      ) : null}
      <Box paddingInline={'400'} paddingBlockEnd={'400'}>
        <InlineStack blockAlign="center">
          <Box width="100%">
            <InlineGrid columns={'1fr auto'} gap={'200'}>
              <Box paddingBlockEnd={'200'}>
                <Autocomplete
                  allowMultiple
                  options={tagOptions}
                  selected={selectedTags}
                  onSelect={(newSelectedTags) => {
                    setSelectedTags(newSelectedTags)
                    onTagChange(newSelectedTags)
                  }}
                  textField={
                    <Autocomplete.TextField
                      onChange={handleChange}
                      label="Tags"
                      value={query}
                      prefix={<Icon source={SearchIcon} />}
                      placeholder="Search all tags"
                      suggestion={suggestion}
                      autoComplete="off"
                    />
                  }
                  actionBefore={
                    query && !allTags.includes(query)
                      ? {
                          accessibilityLabel: 'Add new tag',
                          content: `Add ${query}`,
                          icon: PlusIcon,
                          onAction: () => {
                            const newTags = [...allTags, query]
                            setAllTags(newTags)
                            setSelectedTags([...selectedTags, query])
                            onTagChange([...selectedTags, query])
                            setQuery('')
                          },
                        }
                      : undefined
                  }
                />
              </Box>
              <Box paddingBlockEnd={'200'}>
                <Button
                  icon={PlusIcon}
                  onClick={() => {
                    if (query === '') {
                      shopify.toast.show(t('ProductTagList.emptyFieldText'), {
                        isError: true,
                      })
                      return
                    }
                    const newTags = [...allTags, query]
                    setAllTags(newTags)
                    setSelectedTags([...selectedTags, query])
                    onTagChange([...selectedTags, query])
                    setQuery('')
                  }}
                >
                  {tagBtnCta}
                </Button>
              </Box>
            </InlineGrid>
            <InlineStack gap={'200'}>
              {selectedTags?.map((tag) => (
                <Tag
                  key={tag}
                  onRemove={() => {
                    const copy =
                      selectedTags?.filter((value) => value !== tag) ?? []
                    setSelectedTags(copy)
                    onTagChange(copy)
                  }}
                >
                  {tag}
                </Tag>
              ))}
            </InlineStack>
          </Box>
        </InlineStack>
      </Box>
    </BlockStack>
  )
}

export default PickerComponent
