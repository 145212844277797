import React from 'react'
import { Box, Button, Card, Divider, InlineGrid, Layout, Text } from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { openChat } from '../../../components/ExternalScripts'

function TemplatesSection() {
  const { t } = useTranslation()
  return (
    <Layout.AnnotatedSection
      title={t('SettingsPage.Sections.Template.title')}
      description={t('SettingsPage.Sections.Template.description')}
    >
      <Card padding={'0'}>
      <Box padding={'400'}>
          <Text as="strong">{t('SettingsPage.Sections.Template.Upgrade.heading')}</Text>
          <InlineGrid columns={'1fr auto'} gap={'100'} alignItems="center">
            <Text>{t('SettingsPage.Sections.Template.Upgrade.subHeading')}</Text>
            <Button onClick={()=>{openChat("Hi, please help me upgrade from V2 to V3 Dashboard")}}>
            {t('SettingsPage.Sections.Setting.section2.btnCta')}
            </Button>
          </InlineGrid>
        </Box>
        <Divider/>
        <Box padding={'400'}>
          <Text as="strong">{t('SettingsPage.Sections.Template.heading')}</Text>
          <InlineGrid columns={'1fr auto'} gap={'100'} alignItems="center">
            <Text>{t('SettingsPage.Sections.Template.subHeading')}</Text>
            <Button url="/settings/templates">
              {t('SettingsPage.Sections.Template.btnCta')}
            </Button>
          </InlineGrid>
        </Box>
      </Card>
    </Layout.AnnotatedSection>
  )
}

export default TemplatesSection
