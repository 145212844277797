import { Page, Text } from '@shopify/polaris'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { createCampaign, updateCampaign } from '../../../../apis/checkoutUpsell'
import { useRedirect } from '../../../../hooks'
import CampaignUpsertForm from '../common/components/Upsert'
import CampaignSkeleton from '../common/components/Skeleton'
import { getCheckoutUpsellConfig } from '../../../../apis/checkoutUpsell'
import analytics, { MixPanelAnalytics } from '../../../../utils/analytics'

function CampaignCreate() {
  const { t } = useTranslation()
  const { redirectToLink } = useRedirect()
  const configQuery = useQuery({
    queryKey: ['checkoutUpsellConfig'],
    queryFn: async () => {
      const { data, error } = await getCheckoutUpsellConfig()
      if (error) {
        return Promise.reject(error)
      }
      return data
    },
  })
  const checkoutConfig = configQuery.data ?? {}

  if (configQuery.isLoading) {
    return <CampaignSkeleton />
  }

  return (
    <Page
      backAction={{
        url: '/checkout-upsell',
        content: t('CheckoutUpsell.CampaignCreate.back'),
      }}
      title={t('CheckoutUpsell.CampaignCreate.title')}
      additionalMetadata={t('CheckoutUpsell.CampaignDetail.additionalMetadata')}
    >
      <CampaignUpsertForm
        checkoutConfig={checkoutConfig}
        onSubmit={formSubmitHandler}
        currentValues={{
          postCheckoutData: {
            enabled: false,
            settings: {},
          },
        }}
      />
    </Page>
  )

  async function formSubmitHandler(fieldValues, cb) {
    const payload = {
      name: fieldValues.name,
      startDate: fieldValues.duration?.start
        ? fieldValues.duration.start.valueOf() / 1000
        : null,
      endDate: fieldValues.duration?.end
        ? fieldValues.duration.end.valueOf() / 1000
        : null,
      postCheckoutData: fieldValues.postCheckoutData,
    }
    const {
      error: createError,
      data,
    } = await createCampaign(payload)
    analytics.trackCheckoutCampaignEvent(
      MixPanelAnalytics.Actions.CREATE_INITIATED,
      payload,
    )
    if (createError) {
      throw new Error(createError.message)
    }
    const { error: updateError } = await updateCampaign(data.id, {
      triggerType: fieldValues.triggerType,
      triggerGroup: fieldValues.triggerGroup,
      status: fieldValues.status,
      products: fieldValues.products,
      postCheckoutData: fieldValues.postCheckoutData,
    })

    if (updateError) {
      throw new Error(updateError.message)
    }

    analytics.trackCheckoutCampaignEvent(
      MixPanelAnalytics.Actions.CREATE_COMPLETED,
      {
        ...data,
        startDate: data.startTime,
        endDate: data.endTime,
      },
    )
    cb()
    shopify.toast.show(
      t('CheckoutUpsell.CampaignCreate.success', {
        name: fieldValues.name,
      }),
    )
    setTimeout(() => {
      redirectToLink({
        url: `/checkout-upsell/${data.id ?? 1}`,
        external: false,
      })
    }, 200)
  }
}

export default CampaignCreate
