import { BlockStack, Card, Text, SkeletonDisplayText, InlineStack } from '@shopify/polaris'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { getSectionsStats } from '../../../../apis/analytics'
import { numberFormater, percentageFormatter } from '../../../../utils/formater'
import { subDays } from 'date-fns'

const DAYS_RANGE = 7

function AnalyticsSection({ sectionId }) {
  const { t } = useTranslation()

  // Calculate last 7 days range
  const endDate = new Date()
  const startDate = subDays(endDate, DAYS_RANGE)
  const statsQuery = useQuery({
    queryKey: ['sectionStats', sectionId],
    queryFn: async () => {
      const { data, error } = await getSectionsStats({
        sections: [sectionId],
        dateGte: startDate.getTime() / 1000,
        dateLte: endDate.getTime() / 1000
      })
      if (error) return Promise.reject(error)
      return data?.stats?.find(stat => String(stat.section) === String(sectionId))
    },
  })

  if (statsQuery.isLoading) {
    return (
      <Card padding='400'>
        <BlockStack gap='400'>
          <SkeletonDisplayText size="small" />
          <SkeletonDisplayText size="small" />
          <SkeletonDisplayText size="small" />
        </BlockStack>
      </Card>
    )
  }
  return (
    <Card padding='400'>
      <BlockStack gap='200'>
        <Text as="h2" variant="headingMd">
          {t('PerformanceSection.lastDaysPerformance', { days: DAYS_RANGE })}
        </Text>

        <InlineStack align='space-between'>
          <Text>{t('PerformanceSection.totalSessions')}</Text>
          <Text variant="bodyLg" as="p">{numberFormater(statsQuery.data?.totalSessions ?? 0)}</Text>
        </InlineStack>

        <InlineStack align='space-between'>
          <Text>{t('PerformanceSection.totalSessionsWithRecommendations')}</Text>
          <Text variant="bodyLg" as="p">{numberFormater(statsQuery.data?.sessionsWithRecommendationViews ?? 0)}</Text>
        </InlineStack>

        <InlineStack align='space-between'>
          <Text>{t('PerformanceSection.viewRate')}</Text>
          <Text variant="bodyLg" as="p">{percentageFormatter(statsQuery.data?.viewRate ?? 0)}</Text>
        </InlineStack>

        <InlineStack align='space-between'>
          <Text>{t('PerformanceSection.interactionRate')}</Text>
          <Text variant="bodyLg" as="p">{percentageFormatter(statsQuery.data?.interactionRate ?? 0)}</Text>
        </InlineStack>
      </BlockStack>
    </Card>
  )
}

export default AnalyticsSection