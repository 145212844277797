import React, { useState, useCallback, useEffect } from 'react'
import { Layout, Card, FormLayout, Button, Select, Text } from '@shopify/polaris'
import { updateSection } from '../../../../apis/section'
import queryClient from '../../../../utils/query'
import { useTranslation } from 'react-i18next'

export default function Status({form, isEnabled, handleStatusChange, loadingState}) {
  const { loading, setLoading } = loadingState;
  const [selected, setSelected] = useState(isEnabled ? 1 : 0)
  const { t } = useTranslation()
  
  const handleSelectChange =  async(value) =>{
      setSelected(Number(value));
      handleStatusChange(Boolean(Number(value) == 1))
    } 


  useEffect(() => {
    setSelected(form.fields.enabled.value ? 1 : 0);
  }, [form.fields.enabled.value]);


  return (
    <Card id="status">
      <FormLayout>       
        <Select
        label="Status"
          onChange={handleSelectChange}
          options={STATUS}
          value={selected}
          disabled={loading}
        />
      </FormLayout>
    </Card>
  )
}

const STATUS = [
  { value: 0, label: 'Disabled' },
  { value: 1, label: 'Enabled' },
]