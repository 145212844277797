import React, { useState } from 'react'
import {
  Button,
  Box,
  Card,
  InlineGrid,
  Text,
  TextField,
  Tooltip,
  BlockStack,
  InlineStack,
  Link,
  FormLayout,
  Divider,
  DataTable,
  ExceptionList,
} from '@shopify/polaris'
import {
  ClipboardCheckFilledIcon,
  ClipboardIcon,
  NoteIcon,
} from '@shopify/polaris-icons'
import { useTranslation } from 'react-i18next'
import ContactUsLink from '../../../../components/ContactUsLink'
import { openChat } from '../../../../components/ExternalScripts'
import { TEMPLATE_TYPES } from '../../../../constants/templates'
import SectionPlacement from './sectionPlacement'
import CustomModal from '../../../../components/CustomModal'
import { useEffect } from 'react'

function LocationSection({ form, sectionId, type, templates = [] }) {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  const content = `<div id='rk-widget-${sectionId}' class='rk-${type}'></div>`
  const [copied, setCopied] = useState(false)
  const v3TemplatesEnabled =
    templates.filter(
      (template) =>
        template.type === TEMPLATE_TYPES.WIDGET && template.version == 3,
    ).length > 0

  const v3Template = templates.find(({ id }) => {
    return form.fields.templateV3.value === id
  })

  return (
    <Box>
      <Card padding={'0'}>
        <Box padding={'400'}>
          <BlockStack gap={'200'}>
            <InlineGrid columns={'1fr auto auto'} gap={'200'}>
              <BlockStack gap={'100'}>
                <Text as="strong" variant="headingSm">
                  {t('Section.Edit.Sections.LocationPosition.title')}
                </Text>
                <Text as="p" variant="bodyMd">
                  {t('Section.Edit.Sections.LocationPosition.description')}
                </Text>
              </BlockStack>
              <InlineStack gap={'200'}>
                <ContactUsLink type="link" showLiveSupport={true} />
                <Box paddingBlockStart={'400'} paddingBlockEnd={'400'}>
                  <Button
                    onClick={() => {
                      document.getElementById('location-tut-modal').show()
                    }}
                    size="slim"
                  >
                    {t('DefaultText.tutorial')}
                  </Button>
                </Box>
              </InlineStack>
            </InlineGrid>
            <TextField
              label={t(
                'Section.Edit.Sections.LocationPosition.FormFields.Location.label',
              )}
              helpText={t(
                'Section.Edit.Sections.LocationPosition.FormFields.Location.helpText',
              )}
              {...form.fields.location}
            />
            <TextField
              type="number"
              label={t(
                'Section.Edit.Sections.LocationPosition.FormFields.Position.label',
              )}
              helpText={t(
                'Section.Edit.Sections.LocationPosition.FormFields.Position.helpText',
              )}
              {...form.fields.position}
            />
          </BlockStack>
        </Box>
        {v3TemplatesEnabled && (
          <>
            <Divider />
            <Box padding={'400'}>
              <InlineGrid alignItems="start" columns={'1fr auto'} gap={'300'}>
                <BlockStack gap={'050'}>
                  <Text as="strong" variant="headingSm">
                    {t('LocationModal.ParentCard.title')}
                  </Text>
                  <Text tone="subdued">
                    {t('LocationModal.ParentCard.description')}
                  </Text>
                </BlockStack>
                <Box>
                  <Button
                    variant="primary"
                    onClick={() => {
                      setShow(true)
                    }}
                  >
                    {t('LocationModal.ParentCard.cta')}
                  </Button>
                </Box>
              </InlineGrid>
            </Box>
          </>
        )}
        <Divider />
        <Box padding={'400'}>
          <BlockStack gap={'100'}>
            <Text as="strong">
              {t('Section.Details.DesignDetails.CustomAlignment.title')}
            </Text>
            <InlineStack gap={'400'}>
              <Box background="bg-fill-active" padding={'100'}>
                <code>{content}</code>
              </Box>
              <Tooltip
                content={
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {copied
                      ? t(
                          'Section.Details.DesignDetails.CustomAlignment.doneCta',
                        )
                      : t('Section.Details.DesignDetails.CustomAlignment.cta')}
                  </span>
                }
              >
                <Button
                  icon={copied ? ClipboardCheckFilledIcon : ClipboardIcon}
                  variant="secondary"
                  onClick={() => {
                    navigator.clipboard.writeText(String(content))
                    setCopied(true)
                    setTimeout(() => {
                      setCopied(false)
                    }, 3000)
                  }}
                />
              </Tooltip>
            </InlineStack>
          </BlockStack>
        </Box>
        {v3TemplatesEnabled && (
          <>
            <Divider />
            <Box padding={'400'}>
              <SectionPlacement form={form} />
            </Box>
          </>
        )}
      </Card>
      <ui-modal id="location-tut-modal" variant="large">
        <div
          style={{
            padding: 'var(--p-space-400)',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          Instructions video will be uploaded soon here. For now, You can
          contact our support team for any help from{' '}
          <a onClick={openChat}>here</a>.
        </div>
        <ui-title-bar
          title={t(
            'CheckoutUpsell.CheckoutUpsellConfig.Placement.card.insModalTitle',
          )}
        ></ui-title-bar>
      </ui-modal>
      <BreakpointLocationUpdateModal
        form={form}
        show={show}
        setShow={setShow}
        template={v3Template}
      />
    </Box>
  )
}

export default LocationSection

function BreakpointLocationUpdateModal({ form, show, setShow, template }) {
  const defaultBreakpointObj = {
    position: form.fields.position.value,
    location: form.fields.location.value,
  }
  const breakpoints = form.fields.extra.value?.breakpoints || {
    small: defaultBreakpointObj,
    medium: defaultBreakpointObj,
    large: defaultBreakpointObj,
  }
  const { t } = useTranslation()

  const handleChange = (size, obj = {}) => {
    // Create a new object instead of mutating the existing one
    const newBreakpoints = {
      ...breakpoints,
      [size]: {
        ...breakpoints[size],
        ...obj,
      },
    }

    // Update the entire extra object at once
    form.fields.extra.onChange({
      ...form.fields.extra.value,
      breakpoints: newBreakpoints,
    })
  }

  useEffect(() => {
    setShow(false)
  }, [form.submitting])

  return (
    <CustomModal
      maxWidth={'1000px'}
      show={show}
      variant="large"
      setShow={setShow}
      title={t('LocationModal.title')}
    >
      <Box padding={'200'}>
        <Text tone='subdued'>
          <strong>{t('LocationModal.noteText')}</strong>{' '}
          {t('LocationModal.locationHelptext')}
        </Text>
      </Box>
      <Divider/>
      <Box padding={'0'} background="bg-fill">
        <DataTable
          columnContentTypes={['text', 'text', 'text', 'text']}
          headings={[
            t('LocationModal.screen.title'),
            t('LocationModal.location'),
            t('LocationModal.position'),
            t('LocationModal.screen.size'),
          ]}
          hoverable={false}
          rows={[
            [
              <Text variant="headingMd">
                {t('LocationModal.screen.small')}
              </Text>,
              <TextField
                value={breakpoints.small.location}
                onChange={(value) => handleChange('small', { location: value })}
              />,
              <TextField
                value={breakpoints.small.position}
                onChange={(value) =>
                  handleChange('small', { position: parseInt(value) })
                }
                type="number"
              />,
              <TextField value={getScreenSizeRange('small')} disabled />,
            ],
            [
              <Text variant="headingMd">
                {t('LocationModal.screen.medium')}
              </Text>,
              <TextField
                value={breakpoints.medium.location}
                onChange={(value) =>
                  handleChange('medium', { location: value })
                }
              />,
              <TextField
                value={breakpoints.medium.position}
                onChange={(value) =>
                  handleChange('medium', { position: parseInt(value) })
                }
                type="number"
              />,
              <TextField value={getScreenSizeRange('medium')} disabled />,
            ],
            [
              <Text variant="headingMd">
                {t('LocationModal.screen.large')}
              </Text>,
              <TextField
                value={breakpoints.large.location}
                onChange={(value) => handleChange('large', { location: value })}
              />,
              <TextField
                value={breakpoints.large.position}
                onChange={(value) =>
                  handleChange('large', { position: parseInt(value) })
                }
                type="number"
              />,
              <TextField value={getScreenSizeRange('large')} disabled />,
            ],
          ]}
        />
        <Box padding={'200'}>
          <ExceptionList
            items={[
              {
                icon: NoteIcon,
                description: t('LocationModal.note', {
                  link: (
                    <Link
                      target="_blank"
                      url="https://docs.glood.ai/for-developers/section-template/schemas#template-object"
                    >
                      {t('LocationModal.docs')}
                    </Link>
                  ),
                }),
              },
            ]}
          />
        </Box>
      </Box>
    </CustomModal>
  )

  function getScreenSizeRange(screen) {
    const breakpoints = template?.settings?.breakpoints

    const small = breakpoints?.small?.screen_size || 490,
      medium = breakpoints?.medium?.screen_size || 767,
      large = breakpoints?.large?.screen_size || 1024

    if (screen == 'small') {
      return `${small}px - ${medium - 1}px`
    }
    if (screen == 'medium') {
      return `${medium}px - ${large - 1}px`
    }

    return large + 'px+'
  }
}
