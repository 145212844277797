import { Divider, InlineGrid } from '@shopify/polaris'
import { Preview, SideBar, TopBar } from './components'
import { useForm } from '@shopify/react-form'
import { useTranslation } from 'react-i18next'
import { useEffect } from 'react'

import useFields from './hooks/useFields'
import { updateCampaign } from '../../../../../apis/checkoutUpsell'

import './tailwind.js'
import analytics, { MixPanelAnalytics } from '../../../../../utils/analytics.js'

export default function Editor({ campaign, initData }) {
  const {t} = useTranslation()
  const form = useForm({
    fields: useFields(campaign.postCheckoutData.settings ?? {}),
    onSubmit: async (values) => {
      const textConfig = {...values.texts}
      delete values.translations
      delete values.texts

      values.translations = textConfig
      const { error, data } = await updateCampaign(campaign.id, {
        postCheckoutData: {
          ...campaign.postCheckoutData,
          settings: values,
        },
      })
      if (error) {
        return {
          status: 'error',
        }
      }
      analytics.trackCheckoutCampaignEvent(MixPanelAnalytics.Actions.UPDATE,data.campaign)
      window.shopify.toast.show(
        t('DefaultText.updateSuccess',{
          text: campaign.name,
        }),
      )
      setTimeout(() => {
        window.opener.postMessage('GAI-close-post-checkout-editor')
      }, 100)
      return {
        status: 'success',
      }
    },
  })

  useEffect(() => {
    if (form.submitErrors.length > 0) {
      window.shopify.toast.show(form.submitErrors[0].message, {
        isError: true,
      })
    }
  }, [form.submitErrors])

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
      }}
    >
      <TopBar form={form} />
      <Divider />
      <div
        style={{
          width: '100%',
          height: 'calc(100% - 58px)',
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
        }}
      >
        <SideBar form={form} />
        <Preview randomProductId={initData.randomProductId} form={form} />
      </div>
    </div>
  )
}
