import {
  BlockStack,
  Box,
  Button,
  Card,
  Divider,
  InlineGrid,
  InlineStack,
  Layout,
  Text,
} from '@shopify/polaris'
import { CheckIcon } from '@shopify/polaris-icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { executeTask } from '../../../apis/task'

export default function StorefrontSettings({
  dashboardData,
  dashboardLoading,
  invalidateDashboard,
}) {
  const { t } = useTranslation()
  const cardsInfo = [
    {
      title: t('StorefrontSettings.StorefrontAccessTokenBanner.title'),
      description: t(
        'StorefrontSettings.StorefrontAccessTokenBanner.description',
      ),
      cb: async () => {
        try {
          const { error } = await executeTask(TaskTypes.CREATE_STOREFRONT_TOKEN)
          if (error) {
            throw new Error(error)
          }
          shopify.toast.show(
            t('StorefrontSettings.StorefrontAccessTokenBanner.success'),
          )
          invalidateDashboard()
        } catch (e) {
          console.log(
            'Error while sending execute request to create storefront token',
            e,
          )
          shopify.toast.show(t('DefaultText.errorText'),{
            isError: true
          })
        }
      },
      cta: dashboardData?.config?.storefrontAccessTokenCreated
        ? t('StorefrontSettings.StorefrontAccessTokenBanner.ctaCreated')
        : t('StorefrontSettings.StorefrontAccessTokenBanner.ctaNotCreated'),
      done: dashboardData?.config?.storefrontAccessTokenCreated,
      loading: dashboardLoading,
    },
    {
      title: t('StorefrontSettings.WebPixelBanner.title'),
      description: t('StorefrontSettings.WebPixelBanner.description'),
      cb: async () => {
        try {
          const { error } = await executeTask(TaskTypes.ENABLE_WEBPIXEL)
          if (error) {
            throw new Error(error)
          }
          shopify.toast.show(t('StorefrontSettings.WebPixelBanner.success'))
          invalidateDashboard()
        } catch (e) {
          console.log(
            'Error while sending execute request to enable web pixel',
            e,
          )
          shopify.toast.show(t('DefaultText.errorText'),{
            isError: true
          })
        }
      },
      cta: dashboardData?.config?.webPixelEnabled
        ? t('StorefrontSettings.WebPixelBanner.ctaEnabled')
        : t('StorefrontSettings.WebPixelBanner.ctaNotEnabled'),
      done: dashboardData?.config?.webPixelEnabled,
      loading: dashboardLoading,
    },
  ]

  return (
    <Layout.AnnotatedSection
      title={t('StorefrontSettings.title')}
      description={t('StorefrontSettings.description')}
    >
      <Card padding={'0'}>
        <BlockStack>
          {cardsInfo.map((info, index) => (
            <>
              <SettingCard {...info} key={index.toString()} />
              {index != cardsInfo.length - 1 && <Divider />}
            </>
          ))}
        </BlockStack>
      </Card>
    </Layout.AnnotatedSection>
  )
}

function SettingCard({ title, description, cb, cta, done, loading: _loading }) {
  const [loading, setLoading] = useState(false)
  return (
    <Box padding={'400'}>
      <InlineGrid columns={'1fr auto'} gap={'400'}>
        <BlockStack gap="050">
          <Text variant="headingSm">{title}</Text>
          <Text variant="bodyMd" color="subdued">
            {description}
          </Text>
        </BlockStack>
        <Box>
          <Button
            loading={loading || _loading}
            icon={done ? CheckIcon : undefined}
            disabled={done}
            onClick={async () => {
              setLoading(true)
              await cb()
              setLoading(false)
            }}
          >
            {cta}
          </Button>
        </Box>
      </InlineGrid>
    </Box>
  )
}

const TaskTypes = {
  CREATE_STOREFRONT_TOKEN: 'create_storefront_token',
  ENABLE_WEBPIXEL: 'enable_webpixel',
}
