import { SHOPIFY_GRAPHQL_API_ENDPOINT } from '../config'

export async function fetchProduct(id, fetchAll = false) {
  try {
    const res = await fetch(SHOPIFY_GRAPHQL_API_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({
        query: `
          query GetProduct($id: ID!) {
            product(id: $id) {
              id
              title
              vendor
              handle
              featuredImage {
                url
                id
              }
              tags
              productType
              variants(first: 50) {
                nodes {
                  title
                  displayName
                  id
                  image {
                    id
                    originalSrc: url
                  }
                  price
                  compareAtPrice
                  product {
                    id
                  }
                  selectedOptions {
                    name
                    value
                  }
                }
              }
              images(first: 5) {
                nodes {
                  originalSrc: url
                  id
                }
              }
              options(first: 50) {
                id
                name
                values
              }
            }
          }
          `,
        variables: { id: 'gid://shopify/Product/' + id },
      }),
    })
    const { data } = await res.json()
    if (fetchAll) {
      return {
        error: null,
        data: {
          ...data.product,
          images: data.product.images.nodes,
          variants: data.product.variants.nodes,
        },
      }
    }
    return {
      error: null,
      data: {
        id: data.product.id.replace('gid://shopify/Product/', ''),
        images: data.product.featuredImage?.url
          ? [data.product.featuredImage?.url]
          : [],
        title: data.product.title,
        vendor: data.product.vendor,
        handle: data.product.handle,
      },
    }
  } catch (e) {
    return {
      error: e,
      data: null,
    }
  }
}

export async function fetchProductList(cursor) {
  const query = `
        query {
            products(first: 100 ${cursor ? `,after: "${cursor}"` : ''}) {
                edges {
                    node {
                      id
                      title
                      handle
                      vendor
                      featuredImage {
                        url
                        id
                      }
                      handle
                    }
                    cursor
                }
            }
        }
    `
  try {
    const res = await fetch(SHOPIFY_GRAPHQL_API_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({
        query,
      }),
    })
    const { data } = await res.json()
    return {
      error: null,
      data: data.products.edges.map(({ node, cursor }) => ({
        id: node.id.replace('gid://shopify/Product/', ''),
        images: node.featuredImage?.url ? [node.featuredImage?.url] : [],
        title: node.title,
        vendor: node.vendor,
        handle: node.handle,
        cursor,
      })),
    }
  } catch (e) {
    return {
      error: e,
      data: null,
    }
  }
}

export async function fetchCollection(collectionId) {
  const query = `
    query {
      collection(id: "gid://shopify/Collection/${collectionId}"){
        id
        title
        handle
        image {
          id
          url
        }
      }
    }`
  try {
    const res = await fetch(SHOPIFY_GRAPHQL_API_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({
        query,
      }),
    })
    const { data } = await res.json()
    return {
      data: {
        id: data.collection.id.replace('gid://shopify/Collection/', ''),
        images: data.collection.image?.url ? [data.collection.image.url] : [],
        title: data.collection.title,
        handle: data.collection.handle,
      },
      error: null,
    }
  } catch (e) {
    return {
      error: e,
      data: null,
    }
  }
}

export async function getDiscounts() {
  const { data: count = 250 } = await getDiscountCount()
  const query = `
    query {
    codeDiscountNodes(first: ${count}) {
          nodes {
            id
            codeDiscount {
              ... on DiscountCodeBasic {
                title
                codes(first: 1){
                  nodes{
                    code
                  }
                }
                customerGets {
                  value {
                    ... on DiscountAmount {
                      amount {
                        amount
                      }
                    }
                    ... on DiscountPercentage {
                      percentage
                    }
                  }
                }
              }
            }
          }
    }
  }`
  try {
    const res = await fetch(SHOPIFY_GRAPHQL_API_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({
        query,
      }),
    })
    const response = await res.json()
    const { data } = response
    return {
      data: (data.codeDiscountNodes.nodes ?? [])
        .map((d) => ({
          id: d.id,
          title: d.codeDiscount?.title ?? '',
          code: d.codeDiscount?.codes?.nodes[0]?.code ?? '',
          amount: Boolean(
            parseFloat(d.codeDiscount?.customerGets?.value?.amount?.amount),
          )
            ? parseFloat(d.codeDiscount?.customerGets?.value?.amount?.amount)
            : null,
          percentage: d.codeDiscount?.customerGets?.value?.percentage ?? null,
        }))
        .filter((d) => d.code.includes('GLOOD')),
      error: null,
    }
  } catch (e) {
    console.log('error while fetching discount nodes', e)
    return {
      error: e,
      data: null,
    }
  }
}

export async function createDiscount(payload) {
  const query = `
    mutation discountCodeBasicCreate($basicCodeDiscount: DiscountCodeBasicInput!) {
      discountCodeBasicCreate(basicCodeDiscount: $basicCodeDiscount) {
        codeDiscountNode {
          codeDiscount {
            ... on DiscountCodeBasic {
              title
              codes(first: 10) {
                nodes {
                  code
                }
              }
              startsAt
              endsAt
              customerSelection {
                ... on DiscountCustomerAll {
                  allCustomers
                }
              }
              customerGets {
                value {
                  ... on DiscountPercentage {
                    percentage
                  }
                }
                items {
                  ... on AllDiscountItems {
                    allItems
                  }
                }
              }
              appliesOncePerCustomer
            }
          }
        }
        userErrors {
          field
          code
          message
        }
      }
    }`
  const input = {
    title: payload.title,
    code: payload.code,
    startsAt: new Date(),
    endsAt: null,
    customerSelection: {
      all: true,
    },
    customerGets: {
      value: {},
      items: {
        all: true,
      },
    },
    appliesOncePerCustomer: true,
  }
  if (payload.type === 'percentage') {
    input.customerGets.value = {
      percentage: payload.value / 100,
    }
  } else {
    input.customerGets.value = {
      discountAmount: {
        amount: payload.value,
        appliesOnEachItem: false,
      },
    }
  }
  try {
    const res = await fetch(SHOPIFY_GRAPHQL_API_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({
        query,
        variables: {
          basicCodeDiscount: input,
        },
      }),
    })
    const response = await res.json()
    console.log('response from create discount', response)
    const { data } = response
    return {
      data: data.discountCodeBasicCreate.codeDiscountNode.codeDiscount,
      error: null,
    }
  } catch (e) {
    return {
      error: e,
      data: null,
    }
  }
}

export async function getDiscountCount() {
  const query = `
    query discountCodesCount {
      discountCodesCount {
        count
      }
    }`
  try {
    const res = await fetch(SHOPIFY_GRAPHQL_API_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({
        query,
      }),
    })
    const data = await res.json()
    return {
      data: data?.discountCodesCount?.count,
      error: null,
    }
  } catch (e) {
    return {
      error: e,
      data: null,
    }
  }
}

export async function checkShopEligibleForBundles() {
  const query = `
    query {
      shop {
        features{
          bundles{
            eligibleForBundles
          }
        }
      }
    }
  `

  try {
    const res = await fetch(SHOPIFY_GRAPHQL_API_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({
        query,
      }),
    })
    const data = await res.json()
    return {
      data: data?.data?.shop?.features?.bundles?.eligibleForBundles,
      error: null,
    }
  } catch (e) {
    return {
      error: e,
      data: null,
    }
  }
}

export async function fetchProductTypes() {
  const response = await fetch(SHOPIFY_GRAPHQL_API_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify({
      query: `
        query {
          shop {
            productTypes(first:250){
              edges{
                node
              }
            }
          }
        }
      `,
    }),
  })

  const { data } = await response.json()

  return data.shop.productTypes.edges.map(({ node }) => node)
}

export async function fetchPublications() {
  const response = await fetch(SHOPIFY_GRAPHQL_API_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify({
      query: `
        {
          publications(first: 250) {
            nodes {
              id
              name
            }
          }
        }
      `,
    }),
  })

  const { data } = await response.json()

  return data.publications.nodes.map(({ id, name }) => ({
    id: parseInt(id.replace('gid://shopify/Publication/', '')),
    name,
  }))
}

export async function fetchAutomaticDiscount(id){
  const GRAPHQL_QUERY = `
    query discountDetails($discountId: ID!) {
          discountNode(id: $discountId) {
              id
              discount {
                ... on DiscountAutomaticApp {
                    status
                }
              }
          }
      }
  `
  const response = await fetch(SHOPIFY_GRAPHQL_API_ENDPOINT, {
    method: 'POST',
    body: JSON.stringify({
      query: GRAPHQL_QUERY,
      variables: {
        discountId: 'gid://shopify/DiscountNode/' + id
      }
    }),
  })

  const { data } = await response.json()

  return data.discountNode?.discount || null
}

export async function fetchAppDetails(){
  const query = `
      {
      app  {
          isPostPurchaseAppInUse
      }
    }`

  const res = await fetch(SHOPIFY_GRAPHQL_API_ENDPOINT,{
    method: 'POST',
    body: JSON.stringify({
      query
    })
  })

  const data = await res.json()
  return data.data.app
}

export async function getRandomProduct(){
  const query = `
        query {
            products(first: 1) {
                edges {
                    node {
                      id
                    }
                }
            }
        }`

  const res = await fetch(SHOPIFY_GRAPHQL_API_ENDPOINT,{
    method: 'POST',
    body: JSON.stringify({
      query
    })
  })

  const data = await res.json()

  return parseInt(data?.data?.products?.edges[0]?.node?.id.replace('gid://shopify/Product/', ''))
}

export async function fetchCollectionIdByHandle(handle) {
  const query = `
    query collectionByHandle($handle: String!) {
      collectionByHandle(handle: $handle) {
        id
      }
    }
  `

  try {
    const res = await fetch(SHOPIFY_GRAPHQL_API_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({
        query,
        variables: {
          handle,
        },
      }),
    })

    const data = await res.json()
    const id = data?.data?.collectionByHandle?.id
    return {
      error: null,
      data: id ? parseInt(id.replace('gid://shopify/Collection/', '')) : null,
    }
  } catch (e) {
    return {
      error: e,
      data: null,
    }
  }
}

export async function fetchProductTags(fetchAll = true) {
  try {
    const query = `
      query GetProductTags($first: Int) {
      productTags(first: $first) {
        edges {
          node
        }
    }
  }
    `;

    const res = await fetch(SHOPIFY_GRAPHQL_API_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({
        query,
        variables: { first: fetchAll ? 250 : 10 }, 
      }),
    });

    const { data, errors } = await res.json();

    if (errors) {
      throw new Error(errors.map((error) => error.message).join(', '));
    }

    if (!data || !data.productTags) {
      throw new Error('Error fetching product tags');
    }

    const tags = data.productTags.edges.map((edge) => edge.node);

    return {
      error: null,
      data: tags,
    };
  } catch (e) {
    return {
      error: e.message || e,
      data: null,
    };
  }
}


export async function fetchProductVendors(fetchAll = true) {
  try {
    const query = `
      query GetProductVendors($first: Int) {
      productVendors(first: $first) {
        edges {
          node
        }
    }
  }
    `;

    const res = await fetch(SHOPIFY_GRAPHQL_API_ENDPOINT, {
      method: 'POST',
      body: JSON.stringify({
        query,
        variables: { first: fetchAll ? 250 : 10 }, 
      }),
    });

    const { data, errors } = await res.json();

    if (errors) {
      throw new Error(errors.map((error) => error.message).join(', '));
    }

    if (!data || !data.productVendors) {
      throw new Error('Error fetching product vendors');
    }

    const tags = data.productVendors.edges.map((edge) => edge.node);

    return {
      error: null,
      data: tags,
    };
  } catch (e) {
    return {
      error: e.message || e,
      data: null,
    };
  }
}




