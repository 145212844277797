import React from 'react'
import { useForm, useField, notEmpty } from '@shopify/react-form'
import { useTranslation } from 'react-i18next'
import {
  BlockStack,
  Box,
  Button,
  Card,
  Collapsible,
  Form,
  InlineError,
  InlineGrid,
  InlineStack,
  Text,
  TextField,
  useBreakpoints,
} from '@shopify/polaris'
import { useState } from 'react'

import CustomModal from '../CustomModal'
import illustration from './illustration.png'
import analytics from '../../utils/analytics'

export default function FeedbackModal({
  open,
  setOpen,
  onSubmit,
  skipModal = false,
}) {
  const { t } = useTranslation()
  const breakpoints = useBreakpoints()
  const [close, setClose] = useState(true)
  const form = useForm({
    fields: {
      rating: useField({
        value: null,
        validates: [notEmpty(t('FeedbackModal.form.rating.required'))],
      }),
      message: useField({
        value: '',
        validates: [notEmpty(t('FeedbackModal.form.message.required'))],
      }),
    },
    onSubmit: async (vals) => {
      try {
        await onSubmit(vals)
        analytics.trackFeedback({
          rating: vals.rating,
          feedback: vals.message
        })
        setClose(false)
        return {
          status: 'success',
        }
      } catch (error) {
        setClose(false)
        return {
          status: 'fail',
        }
      }
    },
  })

  const content = (
    <Form>
      <BlockStack gap={'300'}>
        <Text as="h2" variant="bodyMd">
          {t('FeedbackModal.form.rating.label')}
        </Text>
        <InlineStack gap={'150'} align={'start'} blockAlign="center">
          {Array.from({ length: 10 }).map((_, index) => (
            <div
              onClick={() => form.fields.rating.onChange(index + 1)}
              style={{
                padding: breakpoints.smUp
                  ? 'var(--p-space-200)'
                  : 'var(--p-space-100)',
                borderRadius: '50%',
                backgroundColor:
                  form.fields.rating.value === index + 1
                    ? '#3F3F3F'
                    : 'var(--p-color-bg-surface-tertiary-active)',
                color:
                  form.fields.rating.value === index + 1
                    ? 'var(--p-color-bg-fill)'
                    : 'var(--p-color-bg-fill-brand-hover)',
                cursor: 'pointer',
                width: breakpoints.smUp ? 36 : 24,
                height: breakpoints.smUp ? 36 : 24,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {index + 1}
            </div>
          ))}
        </InlineStack>
        <Collapsible open={form.fields.rating.value !== null && close}>
          <BlockStack gap={'300'}>
            {form.fields.rating.error && (
              <InlineError message={form.fields.rating.error} />
            )}
            <TextField
              multiline={2}
              label={t('FeedbackModal.form.message.label')}
              placeholder={t('FeedbackModal.form.message.placeholder')}
              {...form.fields.message}
            />
            <Box>
              <Button
                type="submit"
                variant="primary"
                disabled={form.submitting}
                onClick={form.submit}
              >
                {t('FeedbackModal.submitCta')}
              </Button>
            </Box>
          </BlockStack>
        </Collapsible>
      </BlockStack>
    </Form>
  )

  if (skipModal) {
    return (
      <Card>
        <InlineGrid columns={'1fr auto'} gap={'300'} alignItems="start">
          <BlockStack gap={'300'}>
            <Text variant="headingMd">{t('FeedbackModal.title')}</Text>
            {content}
          </BlockStack>
          <div
            style={{
              paddingRight: 'var(--p-space-400)',
              paddingTop: 'var(--p-space-400)',
            }}
          >
            <img width={100} src={illustration} />
          </div>
        </InlineGrid>
      </Card>
    )
  }

  return (
    <CustomModal
      variant="medium"
      show={open}
      setShow={setOpen}
      title={t('FeedbackModal.title')}
      maxWidth={'700px'}
      overlayZIndex={1000}
    >
      <Box padding={'400'}>{content}</Box>
    </CustomModal>
  )
}
