import React, { createContext, useContext } from 'react'
import { useShopStatus } from '../../hooks/useShopStatus'

const context = createContext({
  shopStatus: {
    data: null,
    isLoading: false,
    isError: false,
  }
})

export const ShopifyProvider = ({ children, shopStatusQuery }) => {
  return (
    <context.Provider value={{
      shopStatus: shopStatusQuery.data
    }}>
      {children}
    </context.Provider>
  )
}

export const useShopify = () => {
  return useContext(context)
}
