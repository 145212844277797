import {
  Banner,
  BlockStack,
  Box,
  Button,
  Card,
  FormLayout,
  InlineError,
  InlineGrid,
  InlineStack,
  Link,
  Select,
  Text,
} from '@shopify/polaris'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

import * as LayoutTemplates from '../../../../constants/layouts'
import { getTemplates } from '../../../../apis/template'
import { PAGE_TYPES } from '../../../../constants/page'
import { BOUGHT_TOGETHER } from '../../../../constants/widgets'
import { openChat } from '../../../../components/ExternalScripts'
import { TEMPLATE_TYPES } from '../../../../constants/templates'
import LayoutConfiguration from './layoutConfiguration'
import { BookOpenIcon, SettingsIcon } from '@shopify/polaris-icons'
import analytics, { MixPanelAnalytics } from '../../../../utils/analytics'

export default function SelectTemplate({
  loadingState,
  form,
  dashboardData,
  templates = [],
  section,
}) {
  const { loading } = loadingState
  const { t } = useTranslation()
  const templateLayouts = Object.values(LayoutTemplates).map((layout) => ({
    ...layout,
    label: t(`Layout.${layout.value}.label`),
    description: t(`Layout.${layout.value}.supportText`),
    enabled:
      layout.value === LayoutTemplates.AMAZON_BOUGHT_TOGETHER.value
        ? form.fields.page.value === PAGE_TYPES.product_page.value &&
          form.fields.widgetType.value === BOUGHT_TOGETHER.value
        : true,
  }))
  const [show, setShow] = useState(false)
  const templateEnabled = dashboardData.config.templatesEnabled
  const templateList = [
    ...(form.fields.template.value === null
      ? [{ label: '-', value: null }]
      : []),
    ...templates
      .filter(
        (template) =>
          template.type === TEMPLATE_TYPES.WIDGET && template.version != 3,
      )
      .map((template) => ({
        label: template.name,
        value: template.id,
      })),
  ]

  const v3Templates = templates.filter(
    (template) =>
      template.type === TEMPLATE_TYPES.WIDGET && template.version == 3,
  )

  const templateListV3 = [
    ...(form.fields.templateV3.value === null
      ? [{ label: '-', value: null }]
      : []),
    ...v3Templates.map((template) => ({
      label: template.name,
      value: template.id,
    })),
  ]

  const v3TemplatesEnabled = v3Templates.length > 0
  const isAnyV3TemplateSelected =
    v3TemplatesEnabled && form.fields.templateV3.value

  const v1VisualEditor = (
    <Button
      onClick={() => {
        analytics.trackVisualEditorEvent(
          MixPanelAnalytics.Actions.OPEN,
          section,
          form.fields.page.value,
          templates.find(t=>t.id == section.template)
        )
        document.getElementById('visual-editor-modal')?.show()
      }}
      size="slim"
      icon={SettingsIcon}
    >
      {t('Section.Edit.Sections.SelectTemplate.visualEditorCTA')}
    </Button>
  )

  useEffect(() => {
    if (form.submitting) {
      setShow(false)
    }
  }, [form.submitting])

  return (
    <BlockStack gap={'300'}>
      <Box>
        {templateEnabled ? (
          <Card padding={'0'}>
            <Box padding={'400'}>
              <BlockStack gap={'200'}>
                <InlineGrid
                  columns={isAnyV3TemplateSelected ? '1fr' : '1fr auto'}
                  gap={'200'}
                >
                  <Text as="strong">
                    {t(
                      'Section.Edit.Sections.SelectTemplate.visualEditorTitle',
                    )}
                  </Text>
                  {!isAnyV3TemplateSelected && v1VisualEditor}
                  {isAnyV3TemplateSelected && (
                    <InlineGrid columns={'1fr 1fr'} gap={'300'}>
                      {v1VisualEditor}
                      <Button
                        icon={SettingsIcon}
                        onClick={() => {
                          analytics.trackVisualEditorEvent(
                            MixPanelAnalytics.Actions.OPEN,
                            section,
                            form.fields.page.value,
                            templates.find(t=>t.id == section.templateV3)
                          )
                          document
                            .getElementById('visual-editor-modal-v3')
                            ?.show()
                        }}
                        size="slim"
                      >
                        {t(
                          'Section.Edit.Sections.SelectTemplate.visualEditorV3CTA',
                        )}
                      </Button>
                    </InlineGrid>
                  )}
                </InlineGrid>
                <FormLayout>
                  <FormLayout>
                    <InlineGrid columns={'1fr'} gap={'100'} alignItems="end">
                      <Select
                        disabled={loading}
                        label={t(
                          'Section.Edit.Sections.SelectTemplate.templateSelectHeading',
                        )}
                        options={templateList}
                        value={form.fields.template.value}
                        onChange={(val) =>
                          form.fields.template.onChange(parseInt(val))
                        }
                      />
                      <Text>
                        {t(
                          'Section.Edit.Sections.SelectTemplate.configureText',
                        )}{' '}
                        <Link
                          url={`/settings/templates/${form.fields.template.value}/configure`}
                        >
                          {t('DefaultText.clickHere')}
                        </Link>{' '}
                      </Text>
                    </InlineGrid>
                  </FormLayout>
                  {v3TemplatesEnabled && (
                    <FormLayout>
                      <InlineGrid columns={'1fr'} gap={'100'} alignItems="end">
                        <Select
                          disabled={loading}
                          label={t(
                            'Section.Edit.Sections.SelectTemplateV3.templateSelectHeading',
                          )}
                          options={templateListV3}
                          value={form.fields.templateV3.value}
                          onChange={(val) =>
                            form.fields.templateV3.onChange(parseInt(val))
                          }
                        />
                        {form.fields.templateV3.value && (
                          <Text>
                            {t(
                              'Section.Edit.Sections.SelectTemplate.configureText',
                            )}{' '}
                            <Link
                              url={`/settings/templates/${form.fields.templateV3.value}/configure`}
                            >
                              {t('DefaultText.clickHere')}
                            </Link>{' '}
                          </Text>
                        )}
                      </InlineGrid>
                    </FormLayout>
                  )}
                  <FormLayout>
                    <BlockStack gap={'200'}>
                      {form.fields.layout?.allErrors?.length > 0 && (
                        <InlineError
                          message={form.fields.layout.allErrors[0]}
                        />
                      )}
                      <Select
                        label={t(
                          'Section.Edit.Sections.SelectTemplate.selectLayoutText',
                        )}
                        options={templateLayouts.filter(
                          ({ enabled }) => enabled,
                        )}
                        value={form.fields.layout.value}
                        onChange={(val) => form.fields.layout.onChange(val)}
                        helpText={
                          v3TemplatesEnabled && (
                            <>
                              {t(
                                'Section.Edit.Sections.SelectTemplate.configureBreakpointsText',
                              ) + ' '}
                              {
                                <Link onClick={() => setShow(true)}>
                                  {t(
                                    'Section.Edit.Sections.SelectTemplate.configureBreakpoints',
                                  )}
                                </Link>
                              }
                            </>
                          )
                        }
                      />
                    </BlockStack>
                  </FormLayout>
                </FormLayout>
              </BlockStack>
            </Box>
          </Card>
        ) : (
          <Card>
            <Banner>
              <Text>
                You are on older version of Glood.AI therefore template is not
                enabled for your store. In order to use template, kindly{' '}
                <Link onClick={openChat}>contact support</Link>.
              </Text>
            </Banner>
          </Card>
        )}
      </Box>
      <LayoutConfiguration form={form} show={show} setShow={setShow} />
    </BlockStack>
  )
}
