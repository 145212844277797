import {
  Badge,
  BlockStack,
  Button,
  Card,
  InlineGrid,
  InlineStack,
  Tag,
  Text,
  TextField,
} from '@shopify/polaris'
import { XIcon } from '@shopify/polaris-icons'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { fetchCollectionIdByHandle } from '../../../../../apis/shopify'

function CollectionSelection({ collections, onChange }) {
  const { t } = useTranslation()

  const query = useQuery({
    queryKey: ['collections', collections],
    queryFn: async () => {
      const _collectionIds = await Promise.all(
        collections.map(async (handle) => {
          const res = await fetchCollectionIdByHandle(handle)
          if (res.data) {
            return res.data
          }
        }),
      )
      return _collectionIds
    },
  })

  const collectionIds = query.data ?? []

  return (
    <Card>
      <BlockStack gap={'300'} id='recommendation-collection'>
        <InlineGrid columns={'1fr auto'} gap={'200'}>
          <BlockStack>
            <Text as="strong">
              {t(
                'Section.Edit.Sections.Recommendation.CollectionSelection.title',
              )}
            </Text>
            <Text>
              {t(
                'Section.Edit.Sections.Recommendation.CollectionSelection.description',
              )}
            </Text>
          </BlockStack>
          <div>
            <Button
              onClick={async () => {
                try {
                  const items = await shopify.resourcePicker({
                    type: 'collection',
                    multiple: 20,
                    selectionIds: collectionIds.map((id) => ({
                      id: 'gid://shopify/Collection/' + id,
                    })),
                  })
                  if (items) {
                    onChange(items.map((item) => item.handle))
                  }
                } catch (e) {
                  window.shopify.toast.show(t('noProductUpdate'), {
                    isError: true,
                  })
                }
              }}
            >
              {t(
                'Section.Edit.Sections.Recommendation.CollectionSelection.addCollectionCTA',
              )}
            </Button>
          </div>
        </InlineGrid>
        <InlineStack gap={'200'}>
          {collections.map((collection) => (
            <Tag
              onRemove={() => {
                onChange(
                  collections.filter(
                    (_collection) => collection != _collection,
                  ),
                )
              }}
            >
              {collection}
            </Tag>
          ))}
        </InlineStack>
      </BlockStack>
    </Card>
  )
}

export default CollectionSelection
