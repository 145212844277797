import {
  Page,
  BlockStack,
  Box,
  useBreakpoints,
  Link,
  Banner,
  CalloutCard,
  Grid,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'

import ProductTour from '../../components/ProductTour/index.jsx'
import FeedbackModal from '../../components/FeedbackForm/index.jsx'
import AppNotEnabledBanner from '../../components/AppNotEnabledBanner/index.jsx'
import OnboardingSteps from '../../components/OnboardingSteps/index.jsx'
import { useDashboardDetails } from '../../hooks/useDashboardDetails.js'
import { useRedirect } from '../../hooks/useRedirect.js'
import UsageSurpassedBanner from '../../components/UsageSurpassedBanner/index.jsx'
import {
  PricingModal,
  usePricing,
} from '../../components/PricingModal/index.js'
import { HubspotClient } from '../../models/hubspot.js'
import { openChat } from '../../components/ExternalScripts/index.jsx'
import { useAppEmbed } from '../../hooks/useAppEmbed.js'
import SyncCard from '../../components/SyncCard'
import { Analytics } from './sections/analytics.jsx'
import { DescriptionCard } from './sections/description.jsx'
import { SectionsCard } from './sections/sections.jsx'
import { useShopify } from '../../components/ShopifyWrapper'
import illustration from '../../assets/partnership.png'

const SURPASS_THRESHOLD = 0.8

const HSClient = new HubspotClient({
  portalId: '22080564',
  formId: '1dfd6ed0-f905-42f3-95ba-be1121e0cbe5',
})

export default function HomePage() {
  const pricing = usePricing()
  const { t } = useTranslation()
  const dashboardDetails = useDashboardDetails()
  const { data: dashboardData, isLoading: dashboardLoading } = dashboardDetails
  const [showTutModal, setTutModal] = useState(false)

  const { redirectToLink } = useRedirect()
  const breakpoints = useBreakpoints()

  const bannerData = {
    usageSurpassed:
      dashboardData?.billingCycle?.currentUsage >=
      dashboardData?.billingCycle?.maxWidgetServeHardLimit,
    usageAboutToSurpass:
      dashboardData?.billingCycle?.currentUsage /
        dashboardData?.billingCycle?.maxWidgetServeHardLimit >
        SURPASS_THRESHOLD &&
      dashboardData?.billingCycle?.currentUsage <
        dashboardData?.billingCycle?.maxWidgetServeHardLimit,
  }
  const { appEmbedUrl, isAppEmbedded, isLoading: appLoading } = useAppEmbed()
  const [showOnboardingStepsBanner, setShowOnboardingStepsBanner] = useState(
    window.shopify?.data?.onboarding?.openOnboardingStepsBanner
  )
  const appEnableTutCompleted =
    dashboardData?.config?.onboarding?.appEnableTutCompleted ?? false
  const onboardingSteps =
    dashboardData?.config?.onboarding?.onboardingSteps ?? {}
  const numberOfCreatedSections =
    dashboardData?.setup?.createdSectionsCount ?? 0

  const shopifyData = useShopify()
  const isAgency = shopifyData?.shopStatus?.user?.collaborator || false

  return (
    <>
      <Page
        title={showOnboardingStepsBanner ? null : t('DashboardPage.title')}
        subtitle={
          showOnboardingStepsBanner || appEnableTutCompleted
            ? null
            : t('DashboardPage.subtitle')
        }
      >
        {/* Point to be remembered: all keys must be same as that of locale file */}
        {/* All props must follow this format otherwise perfomance section won't work */}
        <div
          style={{
            paddingLeft: breakpoints.smDown ? 'var(--p-space-400)' : null,
            paddingRight: breakpoints.smDown ? 'var(--p-space-400)' : null,
          }}
        >
          <BlockStack gap={'400'}>
            <BlockStack>
              <UsageSurpassedBanner
                dashboardData={dashboardData}
                show={!dashboardLoading && bannerData.usageSurpassed}
                onUpgrade={() => {
                  redirectToLink({
                    url: '/pricing',
                    external: false,
                  })
                }}
                cta={t('SettingsPage.Sections.Account.viewPricing')}
              />
              {!dashboardLoading && bannerData.usageAboutToSurpass && (
                <Banner tone="warning">
                  <p>
                    {t('SettingsPage.Sections.Account.usageAboutToSurpass', {
                      cta:
                        dashboardData?.shopPlan?.onAnnual ||
                        dashboardData?.shopPlan?.isExtendedUsageEnabled ? (
                          <Link url="/pricing">
                            {' '}
                            {t('SettingsPage.Sections.Account.upgradeBtnCta')}
                          </Link>
                        ) : (
                          <Link>{t('contactUs')}</Link>
                        ),
                    })}
                  </p>
                </Banner>
              )}
            </BlockStack>
            {showOnboardingStepsBanner && !dashboardLoading && (
              <div
                style={{
                  width: '100%',
                }}
              >
                <OnboardingSteps
                  props={{
                    setFeedbackModalOpen: window.openFeedbackModal,
                    onboarding: {
                      ...(dashboardData?.config?.onboarding ?? {}),
                      onboardingSteps: {
                        ...(dashboardData?.config?.onboarding
                          ?.onboardingSteps ?? {}),
                        'onboarding-step-1':
                          numberOfCreatedSections > 0 &&
                          onboardingSteps['onboarding-step-1'] === 'completed'
                            ? 'completed'
                            : 'incomplete',
                        'onboarding-step-2':
                          numberOfCreatedSections > 0 &&
                          onboardingSteps['onboarding-step-2'] === 'completed'
                            ? 'completed'
                            : 'incomplete',
                        'onboarding-step-3':
                          numberOfCreatedSections > 0 &&
                          onboardingSteps['onboarding-step-3'] === 'completed'
                            ? 'completed'
                            : 'incomplete',
                        'onboarding-step-4':
                          isAppEmbedded ||
                          dashboardData?.config?.storefrontPasswordEnabled 
                            ? 'completed'
                            : 'incomplete',
                        'onboarding-step-5':
                          parseInt(dashboardData?.shopPlan?.price ?? 0) > 0 &&
                          onboardingSteps['onboarding-step-5'] === 'completed'
                            ? 'completed'
                            : 'incomplete',
                      },
                    },
                  }}
                  onClose={() => {
                    setShowOnboardingStepsBanner(false)
                  }}
                  loading={dashboardLoading}
                />
              </div>
            )}
            <AppNotEnabledBanner
              appEmbedUrl={appEmbedUrl}
              appEnabled={isAppEmbedded}
              loading={appLoading}
            />
            <Grid
              columns={{
                xs: 1,
                sm: 1,
                md: 2,
                lg: 2,
                xl: 2,
              }}
              gap={'300'}
            >
              <Grid.Cell>
                <DescriptionCard />
              </Grid.Cell>
              <Grid.Cell>
                <SyncCard />
              </Grid.Cell>
            </Grid>
            {isAgency && (
              <CalloutCard
                title={t('DashboardPage.AgencyUser.title')}
                illustration={illustration}
                primaryAction={{
                  content: t('DashboardPage.AgencyUser.button'),
                  onAction: () => {
                    openChat()
                  },
                  variant: "primary",
                }}
              >
                <p>
                  {t('DashboardPage.AgencyUser.description2')}
                </p>
              </CalloutCard>
            )}
            <Analytics />
            <SectionsCard />
            <FeedbackModal
              skipModal={true}
              onSubmit={async function feedbackHandler(data) {
                const payload = {
                  'TICKET.hs_ticket_category': 'APP_FEEDBACK',
                  email: window.shopify.data.user.email,
                  'TICKET.subject': 'In App Feedback',
                  'TICKET.content': data.message,
                  'TICKET.feedback_rating': data.rating,
                  shopify_domain: window.shopify.data.shop.myshopifyDomain,
                }
                await HSClient.sendForm(payload)
                return {
                  status: 'success',
                }
              }}
            />
            <Box padding={'1000'} />
          </BlockStack>
        </div>
        <Box padding={'300'} />
      </Page>
      <ProductTour
        onClose={() => {
          setTutModal(false)
        }}
        initiallyOpen={showTutModal}
      />
      <PricingModal modal={pricing} dashboardDetails={dashboardDetails} />
      <ui-modal id="tut-modal" variant="large">
        <div
          style={{
            padding: 'var(--p-space-400)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Instructions video will be uploaded soon here. For now, You can
          contact our support team for any help.
        </div>
        <ui-title-bar
          title={t(
            'CheckoutUpsell.CheckoutUpsellConfig.Placement.card.insModalTitle',
          )}
        ></ui-title-bar>
      </ui-modal>
    </>
  )
}
