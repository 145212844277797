import {
  BlockStack,
  Box,
  Button,
  Divider,
  InlineGrid,
  InlineStack,
  Scrollable,
  Text,
} from '@shopify/polaris'
import {
  DesktopIcon,
  ExitIcon,
  MobileIcon,
  TabletIcon,
} from '@shopify/polaris-icons'
import { notEmpty, useField, useForm } from '@shopify/react-form'
import get from 'lodash.get'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { decamelizeKeys } from 'humps'
import merge from 'lodash.merge'

import { updateSection } from '../../apis/section'
import { updateTemplate } from '../../apis/template'
import FormOptionsList from './component/FormOptionsList'
import FormOptionParser from './component/FormOptionsParser'
import formSchema from './formSchema'
import { parseFormFields } from './utils'

const COLUMN_HEIGHT = 'calc(100vh - 64px)'

export default function VisualEditorFormV3({
  children,
  data,
  onFormSubmitComplete,
  onFormChange,
  exitHandler,
}) {
  const [activeOption, setActiveOption] = useState(null)
  const { t } = useTranslation()
  const [activeBreakpoint, setActiveBreakpoint] = useState('medium')
  // Use the function
  const fields = buildFieldsObject(formSchema.options, data)
  const parsedFields = parseFormFields(fields)
  const form = useForm({
    fields,
    onSubmit: async (values) => {
      try {
        await updateSection(data.section.id, {
          ...values.section,
        })
        const dataCopy = {
          ...data
        }
        const payload = merge(
          {
            settings: decamelizeKeys(dataCopy.template.settings),
            translations: decamelizeKeys(dataCopy.template.translations || {}),
          },
          {
            settings: decamelizeKeys(values.template.settings),
            translations: decamelizeKeys(values.template.translations || {}),
          },
        )
        await updateTemplate(dataCopy.template.id, payload)
        if (typeof onFormSubmitComplete === 'function') {
          await onFormSubmitComplete()
        }
        shopify.toast.show(t('DefaultText.changesSaved'))
        return {
          status: 'success',
        }
      } catch (error) {
        console.log('Error in V# editor form onSumit', error)
        shopify.toast.show(t('DefaultText.defaultError'), {
          isError: true,
        })
        return {
          status: 'fail',
          errors: [
            {
              message: t('DefaultText.defaultError'),
            },
          ],
        }
      }
    },
  })

  useEffect(() => {
    if (form.submitErrors.length > 0) {
      shopify.toast.show(form.submitErrors[0].message, {
        isError: true,
      })
    }
  }, [form.submitErrors])

  useEffect(() => {
    if (typeof onFormChange === 'function') {
      const dataCopy = {
        ...data
      }
      const _data = {
        section: parsedFields.section,
        template: {
          ...parsedFields.template,
          settings: merge(
            decamelizeKeys(dataCopy.template.settings),
            decamelizeKeys(parsedFields.template.settings),
          ),
          translations: merge(
            decamelizeKeys(dataCopy.template.translations || {}),
            decamelizeKeys(parsedFields.template.translations || {}),
          ),
        },
      }
      _data.section.id = data.section.id
      _data.template.id = data.template.id
      onFormChange(_data)
    }
  }, [parsedFields])

  return (
    <Box width="100vw" minHeight="100vh" background="bg">
      <Box padding={'300'} background="bg-fill">
        <InlineGrid columns={'1fr 1fr'}>
          <BlockStack align="center">
            <Box>
              <InlineStack gap={'300'}>
                <Button
                  icon={ExitIcon}
                  size="large"
                  variant="plain"
                  onClick={exitHandler}
                />
                <Text variant="headingSm" tone="subdued">
                  {t('visualEditorV3.title')}
                </Text>
              </InlineStack>
            </Box>
          </BlockStack>
          <Box>
            <InlineStack align="end" gap={'300'}>
              <Box
                background="bg-surface-brand-hover"
                padding={'100'}
                borderRadius="200"
              >
                <InlineStack gap={'200'}>
                  <Button
                    icon={DesktopIcon}
                    variant={
                      activeBreakpoint === 'large' ? 'secondary' : 'plain'
                    }
                    onClick={() => setActiveBreakpoint('large')}
                    size="medium"
                  />
                  <Button
                    icon={TabletIcon}
                    variant={
                      activeBreakpoint === 'medium' ? 'secondary' : 'plain'
                    }
                    onClick={() => setActiveBreakpoint('medium')}
                    size="medium"
                  />
                  <Button
                    icon={MobileIcon}
                    variant={
                      activeBreakpoint === 'small' ? 'secondary' : 'plain'
                    }
                    onClick={() => setActiveBreakpoint('small')}
                    size="medium"
                  />
                </InlineStack>
              </Box>
              <Box paddingInlineEnd={'100'}>
                <Button
                  variant="primary"
                  size="large"
                  loading={form.submitting}
                  onClick={form.submit}
                >
                  {t('DefaultText.save')}
                </Button>
              </Box>
            </InlineStack>
          </Box>
        </InlineGrid>
      </Box>
      <Divider />
      <InlineGrid
        gap="300"
        columns={activeBreakpoint === 'large' ? '1fr' : activeOption ? '300px 1fr 343px' : '300px 1fr'}
      >
        {
          activeBreakpoint != 'large' && (
            <Box
            minHeight={COLUMN_HEIGHT}
            overflowY="scroll"
            background="bg-fill"
            borderInlineEndWidth="0165"
            borderColor="border"
          >
            <FormOptionsList
              setActiveOption={setActiveOption}
              activeOption={activeOption}
              fields={fields}
            />
          </Box>
          )
        }

        <Box minHeight={COLUMN_HEIGHT} paddingBlock={'300'}>
          <div
            style={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: activeBreakpoint === 'large' ? '0 var(--p-space-400)' : '0',
            }}
          >
            <Box
              borderRadius="200"
              minHeight="100%"
              minWidth={BREAKPOINT_WIDTH_MAP[activeBreakpoint]}
              background="bg-fill"
            >
              {children}
            </Box>
          </div>
        </Box>

        {activeOption && activeBreakpoint !== 'large' && (
          <Scrollable
            style={{
              height: COLUMN_HEIGHT,
            }}
          >
            <InlineGrid columns={'1fr var(--p-space-1200)'}>
            <Box
              minHeight={COLUMN_HEIGHT}
              overflowY="scroll"
              paddingInlineStart="400"
              background="bg-fill"
              borderColor="border"
              borderInlineStartWidth="0165"
            >
              <FormOptionParser
                onClose={() => {
                  setActiveOption(null)
                }}
                activeOptionId={activeOption}
                fields={fields}
                data={data}
              />
            </Box>
            <Box background="bg-fill" paddingBlockStart={'300'} paddingInlineStart={'300'}>
                <Button icon={ExitIcon} variant='plain' onClick={()=>{
                  setActiveOption(null)
                }}/>
            </Box>
            </InlineGrid>
          </Scrollable>
        )}
      </InlineGrid>
    </Box>
  )

  function buildFieldsObject(schema, data) {
    // First flatten the schema into an array of field definitions
    const flattenedFields = []

    function flattenSchema(schemaItem) {
      if (Array.isArray(schemaItem)) {
        schemaItem.forEach((item) => flattenSchema(item))
      } else if (schemaItem?.fields) {
        flattenSchema(schemaItem.fields)
      } else if (schemaItem?.type) {
        // This is a field definition
        flattenedFields.push(schemaItem)
      }
    }

    flattenSchema(schema)

    // Now build the nested object structure based on paths
    const fields = {}

    flattenedFields.forEach((field) => {
      if (!field.path) return

      // Split the path into parts (e.g. "template.settings.productCard.variantSelectorType")
      const pathParts = field.path.split('.')

      // Start at the root of our fields object
      let current = fields
      const value = get(data, field.path) || field.dafaultValue 
      // Build the nested structure
      pathParts.forEach((part, index) => {
        if (index === pathParts.length - 1) {
          // This is the last part, so set the actual field
          current[part] = useField({
            value: value || '',
            validates: Object.entries(field.validation || {}).reduce(
              (acc, [key, value]) => {
                if (value == false) return acc
                switch (key) {
                  case 'required':
                    return [
                      ...acc,
                      notEmpty(
                        t('visualEditorV3.errorText.required', {
                          field: t(field.title.key),
                        }),
                      ),
                    ]
                  case 'color':
                    return [
                      ...acc,
                      color(
                        value,
                        t('visualEditorV3.errorText.color', {
                          field: t(field.title.key),
                        }),
                      ),
                    ]
                  case 'min':
                    return [
                      ...acc,
                      minValue(
                        value,
                        t('visualEditorV3.errorText.min', {
                          field: t(field.title.key),
                          min: value,
                        }),
                      ),
                    ]
                  case 'max':
                    return [
                      ...acc,
                      maxValue(
                        value,
                        t('visualEditorV3.errorText.max', {
                          field: t(field.title.key),
                          max: value,
                        }),
                      ),
                    ]
                  case 'imageAspectRatio':
                    return [
                      ...acc,
                      imageAspectRatio(
                        t('visualEditorV3.errorText.imageAspectRatio', {
                          field: t(field.title.key),
                        }),
                      ),
                    ]
                }
              },
              [],
            ),
          })
        } else {
          // Create nested object if it doesn't exist
          current[part] = current[part] || {}
          current = current[part]
        }
      })
    })

    return fields

    function color(value, errorText) {
      return (value) => {
        // Return null if empty value
        if (!value) return null

        // Create a temporary div to test the color
        const testDiv = document.createElement('div')
        const originalColor = testDiv.style.color

        // Try to set the color
        testDiv.style.color = value

        // If color didn't change, it means the value was invalid
        const isValid = testDiv.style.color !== originalColor

        return isValid ? null : errorText
      }
    }

    function minValue(min, errorText) {
      return (value) => {
        if (!value) return null
        if (typeof value === 'string') {
          return value.length >= min ? null : errorText
        }
        return value >= min ? null : errorText
      }
    }

    function maxValue(max, errorText) {
      return (value) => {
        if (!value) return null
        if (typeof value === 'string') {
          return value.length <= max ? null : errorText
        }
        return value <= max ? null : errorText
      }
    }

    function imageAspectRatio(errorText) {
      return (value) => {
        if (!value) return null
        const aspectRatio = value.split(':')
        const width = Number(aspectRatio[0] || 'null')
        if (isNaN(width)) return errorText
        const height = Number(aspectRatio[1] || 'null')
        if (isNaN(height)) return errorText
        return null
      }
    }
  }
}

const BREAKPOINT_WIDTH_MAP = {
  small: '400px',
  medium: '700px',
  large: '100%',
}
