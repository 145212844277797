import React, { useCallback, useEffect, useState } from 'react'
import {
  Badge,
  Banner,
  BlockStack,
  Box,
  Card,
  InlineGrid,
  InlineStack,
  Page,
  SkeletonPage,
  Spinner,
  useBreakpoints,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { PlusIcon } from '@shopify/polaris-icons'
import { useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import ProductSequenceEdit from '../../../components/ProductSequenceEdit'
import { WIDGET_TYPES } from '../../../constants/widgets'
import {
  updateGlobalRecommendations,
} from '../../../apis/recommendation'
import queryClient from '../../../utils/query'
import { getProducts } from '../../../apis/products'
import { getQueryParams } from '../../../utils/router'
import { canAccessResourcePicker } from '../../../utils/access'
import Button from '../../../components/Button'
import { useGlobalRecommendations } from '../../../hooks/useGlobalRecommendations'

const LIMIT = 100

function GlobalRecommendation({ skipPage, widgetType: widgetTypeProp }) {
  const { t } = useTranslation()
  const { widgetType: widgetTypeParam } = useParams()
  const [productAdding, setProductAdding] = useState(false)
  let widgetType = widgetTypeProp || widgetTypeParam
  const breakpoints = useBreakpoints()
  const params = getQueryParams()

  const { recommendations: globalRecommendations, isLoading: recommendationsLoading, count } = useGlobalRecommendations(widgetType)

  const productQuery = useQuery({
    queryKey: ['globalRecommendations', { widgetType }, 'products'],
    queryFn: async () => {
      const pIds = globalRecommendations.map(({ productId }) =>
        typeof productId === 'string'
          ? parseInt(productId.replace('gid://shopify/Product/', ''))
          : productId,
      )
      const productsData = (await getProducts(pIds)).data.products
      const products = pIds
        .map((id) => productsData.find((p) => p.id === id))
        .filter((p) => p !== undefined)
        .map((p) => ({
          id: p.id,
          images: p.image ? [p.image?.src] : [],
          title: p.title,
          vendor: p.vendor,
          handle: p.handle,
          productType: p.product_type,
        }))
      return products
    },
    enabled: !recommendationsLoading && count > 0,
  })

  const productData = productQuery.data || []
  const [products, setProducts] = useState(productData)

  const updateHandler = async (oldProducts, newProducts, setProductAdding) => {
    setProductAdding(true)
    await update(oldProducts, newProducts, widgetType, t)
    setProductAdding(false)
  }

  const addProductHandler = useCallback(async () => {
    try {
      const selected = await shopify.resourcePicker({
        type: 'product',
        multiple: LIMIT,
        selectionIds: (products ?? []).map(({ id }) => ({
          id: 'gid://shopify/Product/' + id,
        })),
        filter: {
          variants: false,
          archived: false,
        },
      })
      if (!selected) return
      const newProds = selected.map((p) => ({
        ...p,
        id: parseInt(p.id.replace('gid://shopify/Product/', '')),
      }))
      setProducts(newProds)
      updateHandler(productData, newProds, setProductAdding)
    } catch (e) {
      shopify.toast.show(t('noProductUpdate'), {
        isError: true,
      })
    }
  }, [products, setProducts])

  useEffect(() => {
    if (productData.length > 0) {
      setProducts(productData)
    }
  }, [productData])

  const canPickProducts = canAccessResourcePicker(window.shopify.data)

  if (recommendationsLoading) {
    return <LoadingScreen />
  }

  const Wrapper = ({ children }) =>
    skipPage ? (
      children
    ) : (
      <Page
        titleMetadata={
          <InlineStack gap="300">
            <Badge tone="info">{WIDGET_TYPES[widgetType].label}</Badge>
          </InlineStack>
        }
        backAction={{
          url: params.back ?? '/settings',
        }}
        primaryAction={
          canPickProducts ? (
            {
              icon: PlusIcon,
              content: t(
                'RecommendationPage.GlobalRecommendation.primaryActionContent',
              ),
              onAction: addProductHandler,
            }
          ) : (
            <Button icon={PlusIcon} disabled={true} tooltipText={t('noProductPermission')}>
              {t(
                'RecommendationPage.GlobalRecommendation.primaryActionContent',
              )}
            </Button>
          )
        }
        title={t('RecommendationPage.GlobalRecommendation.title')}
        subtitle={t('RecommendationPage.GlobalRecommendation.description')}
        fullWidth
      >
        {children}
      </Page>
    )

  return (
    <Wrapper>
      <Box padding={breakpoints.smUp ? '0' : '400'}>
        <BlockStack gap="400">
          {skipPage && (
            <InlineStack align="end">
              <Button
                variant="primary"
                onClick={addProductHandler}
                disabled={
                  products.length > 25 || productAdding || !canPickProducts
                }
                tooltipText={!canPickProducts && t('noProductPermission')}
              >
                {t(
                  'RecommendationPage.GlobalRecommendation.primaryActionContent',
                )}
              </Button>
            </InlineStack>
          )}
          <Banner tone="info">
            <p>{t('RecommendationPage.GlobalRecommendation.info')}</p>
          </Banner>
          <Card padding={'0'}>
            {productAdding && (
              <Box padding={'200'}>
                <Banner tone="info">Saving.....</Banner>
              </Box>
            )}
            <ProductSequenceEdit
              items={products}
              onChange={(prods) => {
                setProducts(prods)
                updateHandler(productData, prods, setProductAdding)
              }}
              loading={productAdding}
              showSkeleton={productQuery.isLoading}
            />
          </Card>
        </BlockStack>
      </Box>
      <div
        style={{
          padding: 'var(--p-space-400)',
        }}
      />
    </Wrapper>
  )
}

export default GlobalRecommendation

function LoadingScreen() {
  const { t } = useTranslation()
  return (
    <SkeletonPage
      fullWidth
      title={t('RecommendationPage.GlobalRecommendation.title')}
    >
      <Card>
        <InlineStack align="center">
          <BlockStack>
            <Spinner />
          </BlockStack>
        </InlineStack>
      </Card>
    </SkeletonPage>
  )
}

function parsePayload(oldProducts, newProducts) {
  const parsed = newProducts.map((p) => {
    return {
      product: p.id,
      enabled: true,
    }
  })

  const disabled = []

  oldProducts.forEach((old) => {
    if (!newProducts.find((p) => p.id === old.id)) {
      disabled.push({
        product: old.id,
        enabled: false,
      })
    }
  })

  return [...parsed, ...disabled]
}

const update = async (oldProducts, newProducts, widgetType, t) => {
  let payload = []
  payload = parsePayload(oldProducts, newProducts)
  const res = await updateGlobalRecommendations(widgetType, payload)
  if (res.error) {
    shopify.toast.show(
      t('RecommendationPage.GlobalRecommendation.errorToast'),
      {
        isError: true,
      },
    )
  } else {
    shopify.toast.show(
      t('RecommendationPage.GlobalRecommendation.successToast'),
    )
    await queryClient.refetchQueries(['globalRecommendations', { widgetType }])
    await queryClient.refetchQueries([
      'globalRecommendations',
      { widgetType },
      'products',
    ])
  }
}
