import { CART, COLLECTION, PRODUCT } from '../constants/page.js'

/**
 * Hook for getting store links based on page type
 * @returns {Object} Object containing getLink function
 */
export function useStoreLinks() {
  /**
   * Get a store link based on page type
   * @param {string} pageType - Type of page (product, collection, cart)
   * @returns {Promise<string>} URL path for the specified page type
   */
  const getLink = async (pageType) => {
    let res = null
    switch (pageType) {
      case PRODUCT.value:
        res = await fetch('shopify:admin/api/graphql.json', {
          method: 'POST',
          body: JSON.stringify({
            query: `
              {
                products(first: 1, query: "published_status:approved AND status:ACTIVE AND published_at:*") {
                  edges {
                    node {
                      handle
                    }
                  }
                }
              }
              `,
          }),
        })
        const { data } = await res.json()
        return `/products/${data.products.edges[0].node.handle}`
      case COLLECTION.value:
        res = await fetch('shopify:admin/api/graphql.json', {
          method: 'POST',
          body: JSON.stringify({
            query: `
                {
                  collections(first: 1, query: "status:ACTIVE AND published_at:*") {
                    edges {
                      node {
                        handle
                      }
                    }
                  }
                }
              `,
          }),
        })
        const { data: data2 } = await res.json()
        return `/collections/${data2.collections.edges[0].node.handle}`
      case CART.value:
        return '/cart'
      default:
        return '/'
    }
  }

  return { getLink }
}

export default useStoreLinks 