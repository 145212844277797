import { useTranslation } from 'react-i18next'
import { Box, Page } from '@shopify/polaris'

import RecommendationConfig from './Config'
import { useDashboardDetails } from '../../hooks/useDashboardDetails'
import { getQueryParams } from '../../utils/router'

export function Recommendation() {
  const { t } = useTranslation()
  const { data: dashboardData, isLoading } = useDashboardDetails()
  const params = getQueryParams()

  return (
    <Page
      backAction={{
        content: 'back',
        url: '/sections'
      }}
      title={t('RecommendationPage.title')}
      subtitle={t('RecommendationPage.description')}
    >
      {isLoading ? (
        <>Loading</>
      ) : (
        <Box>
          <RecommendationConfig selectedTab={params.recSection} backActionUrl = '/recommendation' dashboardData={dashboardData} />
        </Box>
      )}
    </Page>
  )
}

export {
  RecommendationConfig
}
