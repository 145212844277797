import React, { useCallback, useEffect, useState } from 'react'
import debounce from 'lodash.debounce'
import { useTranslation } from 'react-i18next'
import {
  Autocomplete,
  Card,
  Divider,
  Icon,
  InlineGrid,
  InlineStack,
  ResourceItem,
  ResourceList,
  Tag,
  Text,
  Avatar,
  BlockStack,
  Banner,
  Box,
} from '@shopify/polaris'
import { DeleteIcon, PlusIcon, SearchIcon } from '@shopify/polaris-icons'

import { canAccessResourcePicker } from '../../utils/access'
import Button from '../Button'
import { fetchProductTags } from '../../apis/shopify'
import PickerComponent from '../PickerComponent'

const PAGE_WINDOW_SIZE = 5

export default function ProductTagList({
  productSelectionTitle,
  productSelectionButtonCta,
  productSelectionDescription,
  products,
  onProductSelectionChange,
  tagsTitle,
  tagsDescription,
  tags = [],
  onTagChange,
  queryPlaceholder,
  tagBtnCta,
  separatedLayout = false,
  helpText = null,
  id,
}) {
  const { t } = useTranslation()
  const [selectedProducts, setSelectedProducts] = useState([])
  const [page, setPage] = useState(0)
  const [allTags, setAllTags] = useState([])
  const [query, setQuery] = useState('')
  const [error, setError] = useState(null)
  const [filteredTags, setFilteredTags] = useState([])
  const [selectedTags, setSelectedTags] = useState(tags)

  const fetchTags = useCallback(
    debounce(async () => {
      if (allTags.length > 0) return
      const { data, error } = await fetchProductTags(true)
      if (!error) {
        setAllTags(data)
      } else {
        setError(error)
      }
    }, 300),
    [allTags],
  )

  useEffect(() => {
    if (!query) return
    fetchTags()
  }, [query])

  const handleSearch = useCallback(() => {
    if (!query) {
      setFilteredTags(allTags)
      return
    }
    const lowerCaseQuery = query.toLowerCase()
    setFilteredTags(
      allTags.filter((tag) => tag.toLowerCase().includes(lowerCaseQuery)),
    )
  }, [query, allTags])

  useEffect(() => {
    handleSearch()
  }, [query, handleSearch])

  const tagOptions = filteredTags.map((tag) => ({
    value: tag,
    label: tag,
  }))

  const canPickProducts = canAccessResourcePicker(window.shopify.data)

  return (
    <LayoutWrapper
      id={id}
      separated={separatedLayout}
      helpText={helpText}
      productsComponent={
        <>
          <div
            style={{
              padding: 'var(--p-space-400)',
              display: 'flex',
              alignItems: 'start',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <Text as="strong">{productSelectionTitle}</Text>
              <Text>{productSelectionDescription}</Text>
            </div>
            <Button
              toolTipText={
                !canPickProducts ? t('noProductPermission') : undefined
              }
              onClick={async () => {
                try {
                  const selected = await shopify.resourcePicker({
                    type: 'product',
                    multiple: 20,
                    selectionIds: (products ?? []).map(({ id }) => ({
                      id:
                        typeof id === 'number'
                          ? 'gid://shopify/Product/' + id
                          : !id.includes('gid://shopify/Product/')
                            ? 'gid://shopify/Product/' + id
                            : id,
                    })),
                    filter: {
                      variants: false,
                      archived: false,
                    },
                  })
                  if (selected) {
                    const processed = new Array(...selected).map((product) => ({
                      aiGenerated: false,
                      handle: product.handle,
                      id: product.id,
                      images: product.images.map(
                        ({ originalSrc }) => originalSrc,
                      ),
                      title: product.title,
                      vendor: product.vendor,
                    }))
                    onProductSelectionChange(processed)
                  } else {
                    onProductSelectionChange(products ?? [])
                  }
                } catch (e) {
                  window.shopify.toast.show(t('noProductUpdate'), {
                    isError: true,
                  })
                }
              }}
              disabled={!canPickProducts}
            >
              {productSelectionButtonCta}
            </Button>
          </div>
          <Divider borderColor="border" />
          {products?.length > 0 && (
            <>
              <ResourceList
                selectable={canPickProducts}
                resourceName={{ singular: 'product', plural: 'products' }}
                items={products.slice(
                  PAGE_WINDOW_SIZE * page,
                  PAGE_WINDOW_SIZE * (page + 1),
                )}
                pagination={{
                  hasPrevious: page > 0,
                  hasNext: products.length > PAGE_WINDOW_SIZE * (page + 1),
                  onNext: () => setPage(page + 1),
                  onPrevious: () => setPage(page - 1),
                }}
                renderItem={ProductCard}
                promotedBulkActions={
                  canPickProducts
                    ? [
                        {
                          icon: DeleteIcon,
                          destructive: true,
                          content: 'Delete',
                          onAction: () => {
                            const newList = products.filter(
                              (item) =>
                                !selectedProducts.find(
                                  (productId) => productId === item.id,
                                ),
                            )
                            onProductSelectionChange(newList)
                            setSelectedProducts([])
                          },
                        },
                      ]
                    : []
                }
                selectedItems={canPickProducts ? selectedProducts : undefined}
                onSelectionChange={setSelectedProducts}
              />
            </>
          )}
        </>
      }
      tagsComponent={
        <PickerComponent
          tagsTitle={tagsTitle}
          tagsDescription={tagsDescription}
          tagOptions={tagOptions}
          tagBtnCta={tagBtnCta}
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
          onTagChange={onTagChange}
          query={query}
          setQuery={setQuery}
          filteredTags={filteredTags}
          allTags={allTags}
          setAllTags={setAllTags}
        />
      }
    />
  )
}

const ProductCard = (item) => {
  const { title, handle, id, url, images } = item
  const imageSrc =
    images.length > 0
      ? typeof images[0] === 'string'
        ? images[0]
        : images[0].src
      : 'https://boltagency.ca/content/images/2020/03/placeholder-images-product-1_large.png'
  return (
    <ResourceItem
      id={id}
      url={url}
      media={<Avatar size="md" name={title} source={imageSrc} />}
      accessibilityLabel={`Remove ${title}`}
      name={title}
    >
      <Text variant="bodyMd" fontWeight="bold" as="h3">
        {title}
      </Text>
      <div>
        ID{' '}
        {typeof id === 'string' ? id.replace('gid://shopify/Product/', '') : id}
        {' | '}Handle {handle}
      </div>
    </ResourceItem>
  )
}

function LayoutWrapper({
  tagsComponent,
  productsComponent,
  separated = false,
  helpText,
  id,
}) {
  if (!separated) {
    return (
      <Card padding={'0'}>
        <BlockStack gap={'0'} id={id}>
          {helpText && (
            <div
              style={{
                padding:
                  'var(--p-space-400) var(--p-space-400) 0 var(--p-space-400) ',
              }}
            >
              <Banner>{helpText}</Banner>
            </div>
          )}
          <Box padding={'0'}>
            {productsComponent}
            <Divider borderColor="border" />
            {tagsComponent}
          </Box>
        </BlockStack>
      </Card>
    )
  }

  return (
    <BlockStack gap={'400'} id={id}>
      {helpText && (
        <div
          style={{
            padding:
              'var(--p-space-400) var(--p-space-400) 0 var(--p-space-400) ',
          }}
        >
          <Banner>{helpText}</Banner>
        </div>
      )}
      <Card padding={'0'}>{tagsComponent}</Card>
      <Card padding={'0'}>{productsComponent}</Card>
    </BlockStack>
  )
}
