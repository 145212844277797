import { Button, InlineGrid, Text, Tooltip, Box } from '@shopify/polaris'
import { LockIcon, SettingsIcon } from '@shopify/polaris-icons'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

export default function RecommendationSettingCard({
  title,
  description,
  onClick,
  btnText,
  disabled,
  upgradeRequired,
}) {
  const { t } = useTranslation()
  const Wrapper = disabled ? Tooltip : Fragment
  return (
    <Wrapper
      {...(disabled
        ? { content: disabled ? t('DefaultText.settingNotAvailable') : null }
        : {})}
    >
      <div
        style={{
          opacity: disabled ? 0.6 : 1,
          height: '100%',
          padding: 'var(--p-space-400)',
          borderTop: 'var(--p-border-width-025) solid var(--p-color-border)',
          borderBottom: 'var(--p-border-width-025) solid var(--p-color-border)',
        }}
      >
          <InlineGrid columns={'1fr auto'} alignItems="start">
            <Box>
              <Text as="strong">{title}</Text>
              <Text>{description}</Text>
            </Box>
            <Button
              onClick={onClick}
              disabled={disabled}
              icon={upgradeRequired ? LockIcon : SettingsIcon}
            >
              {btnText}
            </Button>
          </InlineGrid>
      </div>
    </Wrapper>
  )
}
