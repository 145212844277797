import {
  Badge,
  BlockStack,
  Box,
  Card,
  Text,
  IndexTable,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextField,
  useBreakpoints,
  useIndexResourceState,
  Divider,
  Icon,
  IndexFilters,
  Button,
  Popover,
  Scrollable,
  InlineGrid,
  Tooltip,
  EmptyState,
  IndexFiltersMode,
  useSetIndexFiltersMode,
} from '@shopify/polaris'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react'
import {
  ChevronDownIcon,
  DeleteIcon,
  LockIcon,
  PlusIcon,
  SearchIcon,
} from '@shopify/polaris-icons'
import { format } from 'date-fns'
import { useSearchParams } from 'react-router-dom'

import { fetchProduct } from '../../../apis/shopify'
import {
  currencyFormatter,
  numberFormater,
  percentageFormatter,
} from '../../../utils/formater'
import { useDashboardDetails } from '../../../hooks/useDashboardDetails'
import { useRedirect } from '../../../hooks'
import { FEATURES } from '../../../constants/features'
import { areFeaturesEnabled } from '../../../utils/features'
import { PricingModal, usePricing } from '../../../components/PricingModal'
import { useListBundles } from '../hooks/useListBundles'
import WidgetTabChart from '../../../components/WidgetTabChart'
import DateRangePopover from '../../../components/DateTimeRangePopover'
import { useLocalSettings } from '../../../components/LocalSettings'
import { getAggregatedAnalytics, getBundleStats } from '../../../apis/bundles'
import emptyPicture from './assets/empty-state.png'

const MAX_BUNDLES_PER_PAGE = 10


export default function BundleListScreen() {
  const { t } = useTranslation()
  const breakpoints = useBreakpoints()
  const pricing = usePricing()
  const { settings, saveSettings } = useLocalSettings()
  const [_durationFilter, setDurationFilter] = useSearchParams({
    start: new Date(new Date().setDate(new Date().getDate() - 7)),
    end: new Date(),
  })

  const durationFilter = {
    start: new Date(_durationFilter.get('start')),
    end: new Date(_durationFilter.get('end')),
  }

  const handleDurationFilterChange = useCallback(
    (value) =>
      setDurationFilter({
        start: value.start.toISOString(),
        end: value.end.toISOString(),
      }),
    [],
  )

  const { data: dashboardData, isLoading: dashboardLoading } =
    useDashboardDetails()
  const isDateSame =
    new Date(
      new Date(durationFilter.start).setHours(0, 0, 0, 0) + 24 * 3600 * 1000,
    )
      .toISOString()
      .split('T')[0] ===
    new Date(new Date(durationFilter.end).setHours(23, 59, 59, 999))
      .toISOString()
      .split('T')[0]

  const aggregatedAnalyticsQuery = useQuery({
    queryKey: ['bundleAnalytics', durationFilter],
    queryFn: async () => {
      const { dateGte, dateLte } = getDateRangeFromTimeFilter(durationFilter)
      const response = await getAggregatedAnalytics({
        dateGte,
        dateLte,
      })
      if (response.data?.ok) {
        const {
          data: { stats },
        } = response
        // Create aggregates array for backward compatibility
        const aggregates = []
        // Add daily data if available
        if (stats.daily && stats.daily.length > 0) {
          stats.daily.forEach((day) => {
            aggregates.push({
              date: day.date,
              totalRevenue: day.totalRevenue || 0,
              totalOrders: day.totalCheckoutsCompleted || 0,
              totalSessions: day.totalCheckoutsWithBundleViews || 0,
              aov: day.totalAov || 0,
              conversionRate: day.conversionRate || 0,
            })
          })
        }

        return {
          aggregates,
          stats: {
            totalRevenue: stats.totalRevenue || 0,
            totalOrders: stats.totalCheckoutsCompleted || 0,
            totalSessions: stats.totalCheckoutsWithBundleViews || 0,
            aov: stats.totalAov || 0,
            conversionRate: stats.conversionRate || 0,
          },
          bundles: stats.bundles || {},
        }
      }

      return {
        aggregates: [],
        stats: {
          totalRevenue: 0,
          totalOrders: 0,
          totalSessions: 0,
          aov: 0,
          conversionRate: 0,
        },
        bundles: {},
      }
    },
    enabled: true,
  })

  const bundleFeatureEnabled = areFeaturesEnabled(
    [FEATURES.BUNDLES],
    window.shopify.data,
  )

  const analyticsAggregates = aggregatedAnalyticsQuery.data?.aggregates ?? []
  const analyticsData = {
    options: [
      {
        label: t(`Bundles.Chart.attributedRevenue.title`),
        value: currencyFormatter(
          window.shopify.data.shop.currency,
          aggregatedAnalyticsQuery.data?.stats?.totalRevenue || 0,
        ),
      },
      {
        label: t(`Bundles.Chart.conversion.title`),
        value: percentageFormatter(
          aggregatedAnalyticsQuery.data?.stats?.conversionRate || 0,
        ),
      },
      {
        label: t(`Bundles.Chart.aov.title`),
        value: currencyFormatter(
          window.shopify.data.shop.currency,
          aggregatedAnalyticsQuery.data?.stats?.aov || 0,
        ),
      },
      {
        label: t(`Bundles.Chart.orderPlaced.title`),
        value: numberFormater(
          aggregatedAnalyticsQuery.data?.stats?.totalOrders || 0,
        ),
      },
    ],
    stats: [
      {
        data:
          analyticsAggregates.map((agg) => ({
            key: format(new Date(agg.date), 'MMM dd'),
            value: agg.totalRevenue,
          })) || [],
        name: t(`Bundles.Chart.attributedRevenue.title`),
        yAxisFormatter: (val) =>
          currencyFormatter(window.shopify.data.shop.currency, val),
        tooltipFormatter: (val) =>
          currencyFormatter(window.shopify.data.shop.currency, val),
      },
      {
        data:
          analyticsAggregates.map((agg) => ({
            key: format(new Date(agg.date), 'MMM dd'),
            value: agg.conversionRate,
          })) || [],
        name: t(`Bundles.Chart.conversion.title`),
        yAxisFormatter: (val) => percentageFormatter(val),
        tooltipFormatter: (val) => percentageFormatter(val),
      },
      {
        data:
          analyticsAggregates.map((agg) => ({
            key: format(new Date(agg.date), 'MMM dd'),
            value: agg.aov,
          })) || [],
        name: t(`Bundles.Chart.aov.title`),
        yAxisFormatter: (val) =>
          currencyFormatter(window.shopify.data.shop.currency, val),
        tooltipFormatter: (val) =>
          currencyFormatter(window.shopify.data.shop.currency, val),
      },
      {
        data:
          analyticsAggregates.map((agg) => ({
            key: format(new Date(agg.date), 'MMM dd'),
            value: agg.totalOrders,
          })) || [],
        name: t(`Bundles.Chart.orderPlaced.title`),
        yAxisFormatter: (val) => numberFormater(val),
        tooltipFormatter: (val) => numberFormater(val),
      },
    ],
  }

  const updateHandler = () => {
    const activePlan = pricing.plans.find(
      (plan) => plan.id === dashboardData.shopPlan.plan,
    )
    if (
      parseFloat(activePlan.price) < parseFloat(dashboardData.shopPlan.price)
    ) {
      pricing.open({
        showContactCTA: true,
      })
    } else {
      pricing.open({
        features: [FEATURES.BUNDLES],
      })
    }
  }

  return (
    <Page
      title={t('BundleList.title')}
      subtitle={t('BundleList.description')}
      titleMetadata={
        <div
          style={{
            height: '100%',
            display: 'grid',
            alignItems: 'center',
          }}
        >
          {!bundleFeatureEnabled && (
            <Button
              loading={dashboardLoading}
              variant="monochromePlain"
              icon={LockIcon}
              onClick={updateHandler}
            />
          )}
        </div>
      }
      primaryAction={{
        content: t('BundleList.create'),
        url: bundleFeatureEnabled && '/bundles/create/custom',
        icon: !bundleFeatureEnabled ? LockIcon : PlusIcon,
        onAction: () => {
          if (!bundleFeatureEnabled) {
            updateHandler()
          }
        },
      }}
      backAction={{
        url: '/',
      }}
    >
      <Box padding={breakpoints.smUp ? '0' : '400'}>
        <BlockStack gap={'400'}>
          <WidgetTabChart
            dateFilter={
              <Box style={{
                display: 'flex',
                alignItems: 'center', // Aligns children vertically in the center
                justifyContent: 'center', // Aligns children horizontally in the center
              }}>
                <BlockStack gap={'200'} align="center">
                  <DateRangePopover
                    durationFilter={durationFilter}
                    handleDurationFilterChange={handleDurationFilterChange}
                  />
                </BlockStack>
              </Box>
            }
            loading={aggregatedAnalyticsQuery.isLoading}
            loadingBlocks={4}
            stats={analyticsData.options}
            data={analyticsData.stats}
            columns={{
              xs: 3,
              sm: 4,
              md: 5,
              lg: 5,
            }}
          />
          <BundleManager
            refresh={aggregatedAnalyticsQuery.refetch}
            timeFilter={{
              gtle: durationFilter.start.valueOf(),
              lte: durationFilter.end.valueOf(),
            }}
            updateHandler={updateHandler}
          />
        </BlockStack>
      </Box>
      <PricingModal modal={pricing} />
    </Page>
  )
}

function BundleManager({ timeFilter: _timeFilter, refresh, updateHandler }) {
  const { t } = useTranslation()
  const breakpoints = useBreakpoints()
  const { redirectToLink } = useRedirect()
  const [time] = useSearchParams()
  const bundleFeatureEnabled = areFeaturesEnabled(
    [FEATURES.BUNDLES],
    window.shopify.data,
  )

  const timeFilter = {
    start: time.get('start')
      ? new Date(time.get('start'))
      : new Date(_timeFilter.gtle),
    end: time.get('end')
      ? new Date(time.get('end'))
      : new Date(_timeFilter.lte),
  }
  const [queryValue, setQueryValue] = useState('')
  const [page, setPage] = useState(1)
  const [bundles, setBundles] = useState([])
  const [statusFilter, setStatusFilter] = useState([])
  const isSameDates =
    new Date(new Date(timeFilter.start).setHours(0, 0, 0, 0) + 24 * 3600 * 1000)
      .toISOString()
      .split('T')[0] ===
    new Date(new Date(timeFilter.end).setHours(23, 59, 59, 999))
      .toISOString()
      .split('T')[0]

  // for filters
  const itemStrings = [
    {
      label: t('BundleList.filter.status.all'),
      value: 'all',
    },
    {
      label: t('BundleList.filter.status.options.active'),
      value: 'active',
    },
    {
      label: t('BundleList.filter.status.options.inactive'),
      value: 'inactive',
    },
    {
      label: t('BundleList.filter.status.options.draft'),
      value: 'draft',
    },
  ]

  const tabs = itemStrings.map((item, index) => ({
    content: item.label,
    index,
    onAction: () => {},
    id: `${item}-${index}`,
    isLocked: index === 0,
    actions: [],
    value: item.value,
  }))
  const [seletedTab, setSelectedTab] = useState(0)
  const { mode, setMode } = useSetIndexFiltersMode(IndexFiltersMode.Default)

  const { bundles: bundlesResponse, isLoading } = useListBundles()
  const bundleStatsQuery = useQuery({
    queryKey: [
      'bundleStats',
      timeFilter,
      bundles.length > 0
        ? bundles.map((b) => b.bundleProductId).join(',')
        : 'empty',
    ],
    queryFn: async () => {
      if (bundles.length === 0) return { stats: { bundles: {} } }
      const { dateGte, dateLte } = getDateRangeFromTimeFilter(timeFilter)
      const response = await getBundleStats({
        dateGte,
        dateLte,
        bundles: bundles.map((bundle) => bundle.bundleProductId),
      })
      if (response.data?.ok) {
        const {
          data: { stats },
        } = response
        // Process bundle-specific stats
        const bundleStats = {}
        // Check if stats is an array (for the mock data case) or an object
        if (stats && Array.isArray(stats)) {
          // Handle the mock data format
          stats.forEach((bundleData) => {
            bundleStats[bundleData.bundleProductId] = {
              revenue: bundleData.bundleRevenue || 0,
              totalConversions: bundleData.totalBundleConversions || 0,
              conversionRate: bundleData.conversionRate || 0,
              totalViews: bundleData.totalBundleViews || 0,
              aovIncrease: bundleData.aovIncrease || 0,
            }
          })
        } else if (stats.bundles) {
          // Handle the regular format
          Object.entries(stats.bundles).forEach(([bundleId, bundleData]) => {
            bundleStats[bundleId] = {
              revenue: bundleData.bundleRevenue || 0,
              totalConversions: bundleData.totalBundleConversions || 0,
              conversionRate: bundleData.conversionRate || 0,
              totalViews: bundleData.totalBundleViews || 0,
              aovIncrease: bundleData.aovIncrease || 0,
            }
          })
        }
        return bundleStats
      }
      return {
        stats: {
          totalRevenue: 0,
          totalOrders: 0,
          totalSessions: 0,
          aov: 0,
          conversionRate: 0,
        },
        bundles: {},
        daily: [],
      }
    },
    enabled: bundles.length > 0,
  })

  const productQuery = useQuery({
    queryKey: ['bundles', 'products'],
    queryFn: async () => {
      const unfilteredPIds = bundlesResponse
        .map((bundle) => bundle.productIds)
        .reduce((prev, curr) => [...prev, ...curr], [])
      const productIds = [...new Set(unfilteredPIds)]
      const products = await Promise.all(
        productIds.map(async (id) => {
          const { data } = await fetchProduct(id)
          return data
        }),
      )
      return products
    },
    enabled: Boolean(bundlesResponse.length > 0),
  })

  const productIdMap = useMemo(() => {
    const products = productQuery.data ?? []
    return products.reduce((prev, curr) => {
      if (!curr || !curr.id) return prev
      return {
        ...prev,
        [curr.id]: curr,
      }
    }, {})
  }, [productQuery.data])

  const filteredBundles = bundles
    .filter((bundle) => {
      if (tabs[seletedTab].value === 'all') return true
      return bundle.status === tabs[seletedTab].value
    })
    .filter((bundle) => {
      if (!queryValue) return true
      return bundle.displayName.toLowerCase().includes(queryValue.toLowerCase())
    })

  const paginatedBundles = filteredBundles
    .slice(MAX_BUNDLES_PER_PAGE * (page - 1), MAX_BUNDLES_PER_PAGE * page)
    .map((bundle) => {
      // Get the bundle stats from the API response
      const bundleData = bundleStatsQuery.data?.[bundle.bundleProductId] || {}
      // Create a new object to avoid modifying the original bundle
      return {
        ...bundle,
        statsLoading: bundleStatsQuery.isLoading,
        stats: {
          attributedRevenue: bundleData?.revenue || 0,
          orderPlaced: bundleData?.totalConversions || 0,
          conversion: bundleData?.conversionRate || 0,
        },
      }
    })
  const rowMarkup = paginatedBundles.map((bundle, index) => (
    <BundleEntry
      key={bundle.id}
      bundle={{
        ...bundle,
        products: bundle.productIds.map((id) => productIdMap[id]),
      }}
      index={index}
      redirectionCallback={() => {
        const url = `https://admin.shopify.com/store/${window.shopify.data.shop.myshopifyDomain.replace('.myshopify.com', '')}/products/${bundle.bundleProductId}`
        redirectToLink({
          url: url,
          external: true,
        })
      }}
      refresh={refresh}
      statsLoading={bundleStatsQuery.isLoading}
    />
  ))

  useEffect(() => {
    if (bundlesResponse) {
      setBundles(bundlesResponse)
    }
  }, [bundlesResponse])

  useEffect(() => {
    setPage(1)
  }, [statusFilter, queryValue])

  if (productQuery.isLoading || isLoading) {
    return <Skeleton />
  }

  return (
    <Card padding={'0'}>
      <IndexFilters
        queryValue={queryValue}
        queryPlaceholder={t('BundleList.search.placeholder')}
        onQueryChange={setQueryValue}
        onQueryClear={() => {
          setQueryValue('')
        }}
        tabs={tabs}
        selected={seletedTab}
        onSelect={(idx) => {
          setPage(1)
          setSelectedTab(idx)
        }}
        canCreateNewView={false}
        filters={[]}
        appliedFilters={[]}
        onClearAll={() => {
          setQueryValue('')
        }}
        loading={isLoading}
        mode={mode}
        setMode={setMode}
        cancelAction={{
          onAction: () => {},
          disabled: false,
          loading: false,
        }}
      />
      {bundlesResponse.length > 0 ? (
        <IndexTable
          pagination={{
            hasNext: filteredBundles.length > page * MAX_BUNDLES_PER_PAGE,
            hasPrevious: page > 1,
            onPrevious: () => {
              setPage(page - 1)
            },
            onNext: () => {
              setPage(page + 1)
            },
          }}
          condensed={breakpoints.smDown}
          resourceName={{
            singular: 'Bundle',
            plural: 'Bundles',
          }}
          itemCount={filteredBundles.length}
          headings={[
            { title: t('BundleList.table.bundle') },
            { title: t('BundleList.table.type') },
            { title: t('BundleList.table.status') },
            { title: t('BundleList.table.products') },
            { title: t('BundleList.table.revenue'), alignment: 'start' },
            { title: t('BundleList.table.purchased') },
            { title: t('BundleList.table.conversion') },
          ]}
          selectable={false}
        >
          {rowMarkup}
        </IndexTable>
      ) : (
        <EmptyState
          action={{
            content: t('BundleList.create'),
            url: bundleFeatureEnabled && '/bundles/create/custom',
            icon: !bundleFeatureEnabled ? LockIcon : PlusIcon,
            onAction: () => {
              if (!bundleFeatureEnabled) {
                updateHandler()
              }
            },
          }}
          heading={t('BundleList.empty')}
          image={emptyPicture}
        />
      )}
    </Card>
  )
}

function BundleEntry({
  bundle,
  redirectionCallback,
  refresh,
  index,
  statsLoading,
}) {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const { id, displayName, status: state, products, bundleProductId } = bundle

  // Get the stats for this specific bundle from the bundleStatsQuery data
  const bundleStats = bundle.stats || {}
  return (
    <IndexTable.Row
      id={id}
      key={id}
      position={index}
      onClick={redirectionCallback}
    >
      <IndexTable.Cell>
        <BlockStack gap={'100'}>
          <Text variant="bodyMd" fontWeight="bold" as="span">
            {displayName}
          </Text>
          <Text variant="bodyXs">ID: {bundleProductId}</Text>
        </BlockStack>
      </IndexTable.Cell>
      <IndexTable.Cell>{t('BundleList.type.' + bundle.type)}</IndexTable.Cell>
      <IndexTable.Cell>
        <Badge
          tone={
            state == 'active'
              ? 'success'
              : state === 'created'
                ? 'info'
                : undefined
          }
        >
          {t('BundleList.status.' + state)}
        </Badge>
      </IndexTable.Cell>
      <IndexTable.Cell>
        <div
          style={{
            display: 'flex',
            gap: 'var(--p-space-200)',
            alignItems: 'center',
            justifyContent: 'start',
          }}
        >
          {products.map(
            ({ images, title }) =>
              images?.[0] && (
                <Tooltip key={title} content={title}>
                  <img
                    src={images[0]}
                    style={{
                      width: 50,
                      height: 'auto',
                    }}
                  />
                </Tooltip>
              ),
          )}
        </div>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {statsLoading ? (
          <SkeletonBodyText lines={1} />
        ) : (
          currencyFormatter(
            window.shopify.data.shop.currency,
            bundleStats.attributedRevenue || 0,
          )
        )}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {statsLoading ? (
          <SkeletonBodyText lines={1} />
        ) : (
          numberFormater(bundleStats.orderPlaced || 0)
        )}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {statsLoading ? (
          <SkeletonBodyText lines={1} />
        ) : (
          percentageFormatter(bundleStats.conversion || 0)
        )}
      </IndexTable.Cell>
    </IndexTable.Row>
  )
}

function Skeleton() {
  const { t } = useTranslation()
  return (
    <SkeletonPage title={t('BundleList.title')}>
      <Card>
        <BlockStack gap={'200'}>
          <SkeletonDisplayText />
          <SkeletonBodyText lines={2} />
        </BlockStack>
      </Card>
    </SkeletonPage>
  )
}

// Helper function to get date range from time filter
const getDateRangeFromTimeFilter = (timeFilter) => {
  const start = new Date(timeFilter.start)
  const end = new Date(timeFilter.end)

  return {
    dateGte: new Date(start.setHours(0, 0, 0, 0)).toISOString().split('T')[0],
    dateLte: new Date(end.setHours(23, 59, 59, 999))
      .toISOString()
      .split('T')[0],
  }
}